import { FC, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Popconfirm, Radio, RadioChangeEvent } from 'antd';
import {
  CaretRightOutlined,
  DeleteOutlined,
  EyeOutlined,
  LeftOutlined,
  PauseCircleOutlined,
  PauseOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';

import { api, ILocalization } from '@chess-class/sandbox';
import { localizationsToObjectArray } from '@chess-class/sandbox/utils';

import BaseSkeleton from '~/components/atoms/BaseSkeleton';
import { CountryImageText } from '~/components/atoms/CountryImageText';
import { PencilIcon } from '~/components/atoms/icons';
import { NotFoundResult } from '~/components/atoms/NotFoundResult';
import { PageMeta } from '~/context/PageMetaContext';
import { checkEditPermission } from '~/utils/checkEditPermission';

import { ChapterEditFormik } from '~mMaterials/chapters/components/molecules/ChapterEditFormik';
import { ChapterDetailsLayout } from '~mMaterials/chapters/components/organisms/ChapterDetailsLayout';

export const ChapterDetailsPage: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [localization, setLocalization] = useState<ILocalization>('ru');

  const chapterDelete = api.content.chapters.useChapterDelete();
  const chapterPause = api.content.chapters.useChapterStatusUpdate();
  const chapterQuery = api.content.chapters.useChapter({ id: id || '' });
  const chapter = useMemo(() => chapterQuery.data?.data, [chapterQuery]);

  const [editMode, setEditMode] = useState(false);

  const canEditMaterials = checkEditPermission('materials');

  const handleModeChange = (e: RadioChangeEvent) => {
    setLocalization(e.target.value);
  };

  return (
    <>
      <PageMeta
        openMenuKeys={['materials']}
        selectedMenuKeys={['materials-chapters']}
        title="Темы"
      />

      <Link to="/materials/chapters">
        <Button className="mb-4 text-sm" icon={<LeftOutlined size={10} />} size="large" />
      </Link>

      {chapterQuery.isLoading ? (
        <BaseSkeleton />
      ) : !chapter ? (
        <NotFoundResult title="Тема не найдена" />
      ) : (
        <div className="lg:grid lg:grid-cols-4 gap-4 space-y-8 lg:space-y-0">
          {editMode ? (
            <div className="lg:col-span-3">
              <div className="border bg-white rounded-lg p-4">
                <ChapterEditFormik chapter={chapter} onCancel={() => setEditMode(false)} />
              </div>
            </div>
          ) : (
            <ChapterDetailsLayout chapter={chapter} localization={localization} />
          )}

          <div className="">
            <div className="border bg-white rounded-lg p-4 lg:p-6 lg:grid gap-2">
              <div>
                <p className="mb-2">Выберите язык:</p>
                <Radio.Group
                  className="mb-2"
                  onChange={handleModeChange}
                  optionType="button"
                  options={localizationsToObjectArray((locale) => ({
                    label: <CountryImageText src={locale} width={20} />,
                    value: locale,
                  }))}
                  value={localization}
                />
              </div>
              {canEditMaterials && (
                <>
                  <Popconfirm
                    cancelText="Отменить"
                    icon={
                      chapter.status == 'ACTIVE' ? (
                        <PauseCircleOutlined className="text-ant-blue-5 text-base" />
                      ) : (
                        <PlayCircleOutlined className="text-ant-blue-5 text-base" />
                      )
                    }
                    okText={chapter.status == 'ACTIVE' ? 'Приостановить' : 'Активировать'}
                    okType="primary"
                    onConfirm={() =>
                      chapterPause.mutateAsync({
                        id: chapter.id as string,
                        status: chapter.status == 'ACTIVE' ? 'PAUSED' : 'ACTIVE',
                      })
                    }
                    placement="topLeft"
                    title={
                      <p className="text-sm mb-0">
                        Вы уверены, что хотите
                        <span className="font-semibold">
                          {chapter.status == 'ACTIVE' ? ' приостановить ' : ' активировать '}{' '}
                          текущую тему?
                        </span>
                      </p>
                    }
                  >
                    <Button
                      className="mx-0"
                      ghost
                      icon={chapter.status == 'ACTIVE' ? <PauseOutlined /> : <CaretRightOutlined />}
                      type="primary"
                    >
                      {chapter.status == 'ACTIVE' ? 'Приостановить' : 'Активировать'}
                    </Button>
                  </Popconfirm>
                  <Button
                    className="!mx-0"
                    ghost
                    icon={editMode ? <EyeOutlined /> : <PencilIcon />}
                    onClick={() => setEditMode(!editMode)}
                    type="primary"
                  >
                    {editMode ? 'Просмотреть' : 'Редактировать'}
                  </Button>
                  <Popconfirm
                    cancelText="Отменить"
                    icon={<DeleteOutlined className="text-ant-red-5" />}
                    okText="Удалить"
                    okType="danger"
                    onConfirm={() => {
                      chapterDelete
                        .mutateAsync({ id: chapter.id as string })
                        .then(() => navigate('/materials/chapters'));
                    }}
                    placement="topLeft"
                    title={
                      <span className="text-sm">
                        Вы уверены, что хотите
                        <span className="font-bold"> удалить текущую тему</span>?
                      </span>
                    }
                  >
                    <Button className="!mx-0" danger icon={<DeleteOutlined />}>
                      Удалить
                    </Button>
                  </Popconfirm>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
