import { useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';

import { useEducationFormat } from '~/context/EducationFormContext';

enum IEnumGroupsTabsType {
  groups = 'Классы',
  students = 'Ученики',
}

type IEnumGroupsTabsTypeKeys = keyof typeof IEnumGroupsTabsType;

export default function GroupsTabs({ active }: { active: IEnumGroupsTabsTypeKeys }) {
  const navigate = useNavigate();
  const { defaultEducationFormat } = useEducationFormat();
  return (
    <Tabs
      activeKey={active}
      centered
      items={Object.entries(IEnumGroupsTabsType).map(([key, label]) => ({
        key,
        label: key === 'groups' && defaultEducationFormat == 'PRIVATE' ? 'Группы' : label,
      }))}
      onChange={(tab: IEnumGroupsTabsTypeKeys | string) =>
        navigate(tab == 'groups' ? '/classes/' : `/classes/students`)
      }
    />
  );
}
