import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from '~/components/molecules/routes/ProtectedRoute';
import NotFoundPage from '~/components/pages/errors/NotFoundPage';

import AppSettingsPage from '~mSettings/components/pages/AppSettingsPage';

export const SettingsRouter = () => {
  return (
    <ProtectedRoute access="materials">
      <Routes>
        <Route element={<AppSettingsPage />} index />
        <Route element={<NotFoundPage />} path="*" />
      </Routes>
    </ProtectedRoute>
  );
};
