import { IFilters } from '@chess-class/sandbox';
import { modelKeys, paginationBaseFilters } from '@chess-class/sandbox/consts';

import { InputFilter } from '~/components/molecules/filters/static/InputFilter';

export const exerciseGroupsFilters: IFilters = {
  ...paginationBaseFilters,
  [modelKeys.content.exerciseGroup.name]: {
    input: <InputFilter placeholder="Введите название" />,
  },
  sort: { queries: [{ default: '-id' }] },
  [modelKeys.content.exerciseGroup.id]: {
    sort: true,
  },
  [modelKeys.content.exerciseGroup.createdDate]: {
    sort: true,
  },
};
