import { FC, useMemo, useState } from 'react';

import { api, FilterRender } from '@chess-class/sandbox';

import { SelectMultipleFilter } from '~/components/molecules/filters/static/SelectMultipleFilter';

interface SchoolsSelectMultipleFilterInterface {
  filterRender?: FilterRender;
}

export const SchoolsSelectMultipleFilter: FC<SchoolsSelectMultipleFilterInterface> = ({
  filterRender,
}) => {
  const [schoolName, setSchoolName] = useState<string>();
  const schools = api.schools.schools.useSchoolsAutocomplete({ term: schoolName });
  const schoolsData = schools.data?.data ?? [];

  const options = useMemo(() => {
    return schoolsData?.map((school) => ({
      label: school.name,
      value: school.id as string,
    }));
  }, [schoolsData, schoolName]);

  return (
    <SelectMultipleFilter
      filterOption={false}
      filterRender={filterRender}
      loading={schools.isLoading}
      onSearch={(searchValue) => setSchoolName(searchValue)}
      options={options}
      placeholder="Выберите школу"
    />
  );
};
