import { LoadingOutlined } from '@ant-design/icons';

import { api, IGuideline } from '@chess-class/sandbox';

import ChapterDraggableList from '~mMaterials/guidelines/components/molecules/ChapterDraggableList';

export const GuidelineChapterList = ({ guide }: { guide: IGuideline }) => {
  const chaptersQuery = api.guideline.guidelineChapters.useGuidelineChapters({
    guidelineId: guide.id,
    sort: 'priority',
  });
  const chapters = chaptersQuery.data?.data.content;

  return chaptersQuery.isFetching || chaptersQuery.isLoading ? (
    // <Skeleton active round />
    <div className="flex justify-center">
      <LoadingOutlined className="text-blue-500 text-2xl" />
    </div>
  ) : !chapters?.length ? (
    <div>Методичка не содержит тем</div>
  ) : (
    <ChapterDraggableList chapters={chapters || []} deletable />
  );
};
