import { Button, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  CaretRightOutlined,
  DeleteOutlined,
  PauseCircleOutlined,
  PauseOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';

import { IVideo, IVideoStatusUpdateRequest } from '@chess-class/sandbox';
import { modelKeys } from '@chess-class/sandbox/consts';

import PencilIcon from '~/components/atoms/icons/PencilIcon';

import { CountryImageText } from '~/components/atoms/CountryImageText';
import { TbVideoIcon, VscPlayIcon } from '~/components/atoms/icons';
import { StatusTag } from '~/components/atoms/StatusTag';

import { userPlayLevels } from '~mMaterials/exercises/exercises/types/UserPlayLevel';

export const videosColumns = ({
  canEdit,
  onClickDelete,
  onClickEdit,
  onClickPause,
  onClickView,
}: {
  canEdit: boolean;
  onClickDelete: ({ id }: { id: string }) => void;
  onClickEdit: ({ id }: { id: string }) => void;
  onClickPause: (payload: IVideoStatusUpdateRequest) => void;
  onClickView: ({ id }: { id: string }) => void;
}): ColumnsType<IVideo> => {
  const columns: ColumnsType<IVideo> = [
    {
      dataIndex: modelKeys.content.video.translations,
      render: (translations: IVideo['translations'], { id }) => (
        <Button onClick={() => onClickView({ id })} type="link">
          {translations.ru?.title}
        </Button>
      ),
      title: 'Название видео',
    },
    {
      dataIndex: modelKeys.content.video.type,
      render: (type: IVideo['type']) =>
        type === 'CARTOON' ? (
          <span className="flex flex-col items-center gap-1 text-ant-magenta-6">
            <VscPlayIcon className="h-4 w-4" />
            Мультфильм
          </span>
        ) : (
          <span className="flex flex-col items-center gap-1 text-ant-purple-6">
            <TbVideoIcon className="h-4 w-4" />
            Видеоурок
          </span>
        ),
      title: 'Тип',
    },
    {
      dataIndex: modelKeys.content.video.level,
      render: (level?: IVideo['level']) => <p className="mb-0">{level && userPlayLevels[level]}</p>,
      title: 'Уровень',
    },
    {
      key: 'author',
      render: (_, { translations, type }) =>
        type != 'LESSON' ? (
          '-'
        ) : (
          <div className="space-y-2">
            {Object.entries(translations).map(
              ([locale, localization]) =>
                localization?.videoFile?.author?.name && (
                  <div className="whitespace-nowrap mb-0 flex items-center gap-1" key={locale}>
                    <CountryImageText src={locale} width={15} />
                    {localization.videoFile?.author.name}
                  </div>
                ),
            )}
          </div>
        ),
      title: 'Автор',
    },
    {
      dataIndex: modelKeys.content.video.status,
      render: (status: IVideo['status']) => <StatusTag status={status} />,
      title: 'Статус',
    },
  ];

  if (!canEdit) {
    return columns;
  }

  return [
    ...columns,
    {
      key: 'actions',
      render: (video: IVideo) => {
        const {
          id,
          status,
          translations: { ru },
        } = video;
        return (
          <div className="flex gap-x-1.5">
            <Popconfirm
              cancelText="Отменить"
              icon={
                status == 'ACTIVE' ? (
                  <PauseCircleOutlined className="text-ant-blue-5 text-base" />
                ) : (
                  <PlayCircleOutlined className="text-ant-blue-5 text-base" />
                )
              }
              okText={status == 'ACTIVE' ? 'Приостановить' : 'Запустить'}
              okType="primary"
              onConfirm={() =>
                onClickPause({
                  id: id,
                  status: status == 'ACTIVE' ? 'PAUSED' : 'ACTIVE',
                })
              }
              placement="topLeft"
              title={
                <p className="text-sm mb-0">
                  Вы уверены, что хотите
                  <span className="font-semibold">
                    {status == 'ACTIVE' ? ' приостановить ' : ' запустить '}
                    видео #{id}?
                  </span>
                </p>
              }
            >
              <Button
                ghost
                icon={status == 'ACTIVE' ? <PauseOutlined /> : <CaretRightOutlined />}
                type="primary"
              />
            </Popconfirm>

            <Button
              ghost
              icon={<PencilIcon />}
              onClick={() => onClickEdit({ id })}
              type="primary"
            />

            <Popconfirm
              cancelText="Отменить"
              icon={<DeleteOutlined className="text-ant-red-5" />}
              okText="Удалить"
              okType="danger"
              onConfirm={() => onClickDelete({ id })}
              placement="topLeft"
              title={
                <span className="text-sm">
                  Вы уверены, что хотите удалить видео
                  <span className="font-bold"> &quot;{ru?.title}&quot;</span>?
                </span>
              }
            >
              <Button danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </div>
        );
      },
      title: 'Действия',
    },
  ];
};
