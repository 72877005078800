import { LoadingOutlined } from '@ant-design/icons';

import { api } from '@chess-class/sandbox';

export default function SchoolCoachName({ id }: { id?: string | null }) {
  const coachQuery = api.user.coach.useAdminCoach({ id: id || '' });
  const coach = coachQuery.data?.data;

  return coachQuery.isLoading ? (
    <LoadingOutlined />
  ) : coach ? (
    `${coach.firstName || ''} ${coach.lastName || ''} ${coach.middleName || ''}`
  ) : (
    '-'
  );
}
