import { Children, FC } from 'react';

import { TableFilter, TableSortEnum } from '@chess-class/sandbox';
import { checkTextForCharacterIndex } from '@chess-class/sandbox/utils';

import { FilterDropdown } from '~/components/molecules/filters/FilterDropdown';
import { TableHeaderSortIcon } from '~/components/organisms/adminTable/organismComponents/TableHeaderSortIcon';

interface HeaderInterface {
  props: {
    children: { props: { column: { dataIndex: string; key: string; title: string | object } } }[];
  };
  tableFilterProps: TableFilter;
}

export const TableHeader: FC<HeaderInterface> = ({ props, tableFilterProps }) => {
  const { filtered, filters, onFilter } = tableFilterProps;

  const filteredSortValue = filtered['sort'];

  const orderedBy = checkTextForCharacterIndex(filteredSortValue ?? '')
    ? TableSortEnum.DESC
    : TableSortEnum.ASC;
  const sortedBy = checkTextForCharacterIndex(filteredSortValue ?? '')
    ? filteredSortValue?.slice(1, filteredSortValue?.length)
    : filteredSortValue;

  return (
    <tr>
      {Children.map(props.children, (child, ind) => {
        const { dataIndex, key, title } = child.props.column;
        const filter = filters[dataIndex || key];
        if (filter && filter.input) {
          return (
            <FilterDropdown
              dataIndex={dataIndex || key}
              filter={filter}
              filtered={filtered}
              onFilter={onFilter}
              placement={
                ind == 0 ? 'bottomLeft' : ind + 1 < props.children.length ? 'bottom' : 'bottomRight'
              }
              title={title}
            />
          );
        } else if (filter && filter.sort) {
          return (
            <th>
              <div className="flex justify-between ">
                <span>{filter.title || title?.toString()}</span>
                <TableHeaderSortIcon
                  dataIndex={dataIndex}
                  onFilter={onFilter}
                  orderedBy={orderedBy}
                  sortOrderReversed={filter.sortOrderReversed}
                  sortedBy={sortedBy}
                />
              </div>
            </th>
          );
        }

        return <th>{title?.toString()}</th>;
      })}
    </tr>
  );
};
