import { FC, useEffect, useState } from 'react';
import { Button, Modal, Tabs } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { api, IEnumVideoStatus, ILocalization } from '@chess-class/sandbox';
import { localizationsToObjectArray } from '@chess-class/sandbox/utils';

import BaseSkeleton from '~/components/atoms/BaseSkeleton';
import { CountryImageText } from '~/components/atoms/CountryImageText';
import { TbVideoIcon, VscPlayIcon } from '~/components/atoms/icons';
import { NotFoundResult } from '~/components/atoms/NotFoundResult';
import { TitleAndText } from '~/components/atoms/TitleAndText';
import { checkEditPermission } from '~/utils/checkEditPermission';

import { userPlayLevels } from '~mMaterials/exercises/exercises/types/UserPlayLevel';
import { VideoViewLocalization } from '~mMaterials/videos/videos/components/molecules/VideoViewLocalization';

export const VideoViewModal: FC<{
  id?: string;
  isOpen: boolean;
  onCancel: () => void;
  openEdit?: () => void;
}> = ({ id = '', isOpen, onCancel, openEdit }) => {
  const videoQuery = api.content.videos.useVideo({ id });
  const video = videoQuery.data?.data;

  const [activeTab, setActiveTab] = useState<ILocalization>('ru');
  const canEditVideo = checkEditPermission('materials');

  useEffect(() => {
    // setActiveTab('en');
    setActiveTab('ru');
  }, [isOpen]);

  return (
    <Modal
      className="px-4 md:px-0 md:w-4/5 lg:w-3/4 top-10"
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      open={isOpen}
    >
      {videoQuery.isFetching ? (
        <BaseSkeleton />
      ) : !video ? (
        <NotFoundResult title="Видео не найдено" />
      ) : (
        <div className="gap-6">
          <div className="flex flex-col sm:flex-row gap-4 p-4">
            <h2 className="text-2xl">Видео #{id}</h2>

            {canEditVideo && openEdit && (
              <>
                <Button
                  className="flex items-center justify-center"
                  icon={<EditOutlined />}
                  onClick={openEdit}
                >
                  <span className="hidden md:block">Редактировать</span>
                </Button>
              </>
            )}
          </div>

          <div className="divide-y">
            <TitleAndText
              text={
                video.type === 'CARTOON' ? (
                  <span className="flex items-center gap-1 text-ant-magenta-6">
                    <VscPlayIcon className="text-base" />
                    Мультфильм
                  </span>
                ) : (
                  <span className="flex items-center gap-1 text-ant-purple-6">
                    <TbVideoIcon className="text-base" />
                    Видеоурок
                  </span>
                )
              }
              title="Тип видео"
            />
            {video.type !== 'CARTOON' && (
              <TitleAndText
                text={<p className="mb-0.5">{video.level ? userPlayLevels[video.level] : ''}</p>}
                title="Уровень"
              />
            )}

            <TitleAndText text={IEnumVideoStatus[video.status]} title="Статус" />
          </div>

          {video.type === 'CARTOON' ? (
            <h2 className="flex items-center gap-2 text-ant-magenta-6 p-4 text-xl">
              <VscPlayIcon className="text-base" />
              Мультфильм
            </h2>
          ) : (
            <span className="flex items-center gap-2 text-ant-purple-6 p-4 text-xl">
              <TbVideoIcon /> Видеоурок
            </span>
          )}

          <Tabs
            activeKey={activeTab}
            destroyInactiveTabPane
            items={localizationsToObjectArray((locale) => ({
              children: <VideoViewLocalization localization={locale} video={video} />,
              className: 'pl-0 pb-4',
              key: locale,
              label: <CountryImageText src={locale} width={20} />,
            }))}
            onChange={(active) => setActiveTab(active as ILocalization)}
            tabPosition="left"
            type="line"
          />
        </div>
      )}
    </Modal>
  );
};
