import { FC } from 'react';
import { FormItem, Select } from 'formik-antd';

import { IEnumPermission } from '@chess-class/sandbox';

import TitleInfoTooltip from '~mStaff/components/atoms/TitleInfoTooltip';

const accessOptions = Object.entries(IEnumPermission).map(([value, label]) => ({ label, value }));
export const StaffAuthoritySelects: FC = () => {
  return (
    <div className="sm:grid sm:grid-cols-2 gap-x-5">
      <FormItem
        label={
          <TitleInfoTooltip
            title="Материалы"
            tooltip="Темы, авторы, видео, методичка, задачи, домашнее задание"
          />
        }
        name="materials"
      >
        <Select name="materials" options={accessOptions} />
      </FormItem>

      <FormItem
        label={<TitleInfoTooltip title="Школы" tooltip="Школы, тренеры, заявки" />}
        name="schools"
      >
        <Select name="schools" options={accessOptions} />
      </FormItem>

      <FormItem
        label={<TitleInfoTooltip title="Классы" tooltip="Классы, ученики" />}
        name="classes"
      >
        <Select name="classes" options={accessOptions} />
      </FormItem>

      <FormItem label="Персонал" name="staff">
        <Select name="staff" options={accessOptions} />
      </FormItem>

      <FormItem label="Настройки" name="settings">
        <Select name="settings" options={accessOptions} />
      </FormItem>
    </div>
  );
};
