import { FC, useState } from 'react';
import { Formik } from 'formik';

import { api, IChapter, IChapterUpdateRequest } from '@chess-class/sandbox';

import { useEducationFormat } from '~/context/EducationFormContext';

import ChapterForm from '~mMaterials/chapters/components/molecules/ChapterForm';

export const ChapterEditFormik: FC<{
  chapter: IChapter;
  onCancel: () => void;
}> = ({ chapter, onCancel }) => {
  const chapterUpdate = api.content.chapters.useChapterUpdate();
  const { defaultEducationFormat } = useEducationFormat();
  const [chapterNumExistsAlready, setChapterNumExistsAlready] = useState<{
    numId: number;
    title: string;
  }>();

  return (
    <Formik<IChapterUpdateRequest>
      enableReinitialize
      initialValues={{
        additionalMaterialImages: chapter.additionalMaterialImages,
        cartoonIds: chapter?.cartoons?.map((cartoon) => cartoon.id) || [],
        educationFormat: defaultEducationFormat,
        forceCreate: false,
        id: chapter.id as string,
        lessonIds: chapter?.lessons?.map((lesson) => lesson.id) || [],
        level: chapter.level,
        numId: chapter.numId,
        status: chapter.status,
        translations: chapter.translations,
      }}
      onSubmit={(values) =>
        chapterUpdate
          .mutateAsync(values)
          .then((data) => {
            if (data.status == 201 || data.status == 200) {
              onCancel();
              setChapterNumExistsAlready({
                numId: 0,
                title: '',
              });
            }
          })
          .catch((error) => {
            if (error.response.status == 409) {
              setChapterNumExistsAlready(undefined);
            }
          })
      }
      validationSchema={api.content.chapters.helpers.updateChapterValidation}
    >
      <ChapterForm
        chapterNumExistsAlready={chapterNumExistsAlready}
        isEdit
        isLoading={chapterUpdate.isLoading}
        onCancel={onCancel}
      />
    </Formik>
  );
};
