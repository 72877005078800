import { FC, useState } from 'react';
import { Button, CheckboxOptionType, Radio, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { FilterRender } from '@chess-class/sandbox';

interface RadioFilterInterface {
  filterRender?: FilterRender;
  options: CheckboxOptionType[];
}

export const RadioFilter: FC<RadioFilterInterface> = ({ filterRender, options }) => {
  if (!filterRender) {
    return null;
  }

  const { dataIndex, filtered, onFilter, queries } = filterRender;

  const key = queries?.[0]?.key || dataIndex;
  const [newValue, setNewValue] = useState({ [key]: filtered[key] });

  return (
    <div className="p-2 space-y-3 bg-white shadow-filter max-w-tiny">
      <Radio.Group
        className="w-full"
        defaultValue={filtered[key]}
        onChange={(e) => {
          setNewValue({ [key]: e.target.value });
        }}
      >
        <Space className="text-center w-full" direction="vertical">
          {options.map((option) => (
            <Radio className="w-full" key={`radio-${option.value}`} value={option.value}>
              {option.label}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
      <div className="grid gap-1">
        <Button
          className="w-full"
          icon={<SearchOutlined />}
          onClick={() => onFilter(newValue)}
          size="small"
          type="primary"
        >
          Поиск
        </Button>
        <Button
          className="w-full"
          onClick={() => {
            setNewValue({ [key]: undefined });
            onFilter({ [key]: undefined });
          }}
          size="small"
        >
          Очистить
        </Button>
      </div>
    </div>
  );
};
