import { IEnumChapterStatus, IFilters } from '@chess-class/sandbox';
import { modelKeys, paginationBaseFilters } from '@chess-class/sandbox/consts';

import { ChaptersNumSelectMultipleFilter } from '~/components/molecules/filters/dynamic/ChaptersNumSelectMultipleFilter';
import { ChaptersSelectMultipleFilter } from '~/components/molecules/filters/dynamic/ChaptersSelectMultipleFilter';
import { CheckboxFilter } from '~/components/molecules/filters/static/CheckboxFilter';
import { InputFilter } from '~/components/molecules/filters/static/InputFilter';

import { userPlayLevels } from '~mMaterials/exercises/exercises/types/UserPlayLevel';

export const chapterFilters: IFilters = {
  ...paginationBaseFilters,
  id: {
    input: <InputFilter placeholder="Введите ID" />,
    queries: [{ key: 'ids' }],
    sort: true,
  },
  [modelKeys.content.chapter.translations]: {
    input: <ChaptersSelectMultipleFilter />,
    queries: [{ key: 'ids' }],
    sort: true,
  },
  [modelKeys.content.chapter.numId]: {
    input: <ChaptersNumSelectMultipleFilter />,
    queries: [{ key: 'numId' }],
    sort: true,
  },
  [modelKeys.content.chapter.status]: {
    input: (
      <CheckboxFilter
        options={Object.entries(IEnumChapterStatus).map(([value, label]) => ({
          label,
          value,
        }))}
      />
    ),
    sort: true,
  },
  [modelKeys.content.chapter.level]: {
    input: (
      <CheckboxFilter
        options={Object.entries(userPlayLevels).map(([value, label]) => ({
          label,
          value,
        }))}
      />
    ),
    queries: [{ key: 'level' }],
    sort: true,
    sortOrderReversed: true,
  },
};
