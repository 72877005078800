import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';

export const ExerciseTabs: FC<{ active: 'exercises' | 'groups' }> = ({ active }) => {
  const navigate = useNavigate();

  return (
    <Tabs
      activeKey={active}
      centered
      items={[
        { key: 'exercises', label: 'Задачи' },
        { key: 'groups', label: 'Коллекция задач' },
      ]}
      onChange={(tab) =>
        navigate(tab == 'exercises' ? '/materials/exercises' : `/materials/exercise-groups`)
      }
    />
  );
};
