import { Navigate } from 'react-router-dom';

import { api } from '@chess-class/sandbox';

import { storageKeys } from '~/consts/storageKeys';

const AuthRoute = ({ auth, children }: { auth: boolean; children: JSX.Element }) => {
  const me = api.user.adminAuth.useAdminMe();

  if (
    auth &&
    (!localStorage.getItem(storageKeys.tokenAccess) || me.error?.response?.status == 401)
  ) {
    return <Navigate to="/auth/signin" />;
  }

  if (!auth && localStorage.getItem(storageKeys.tokenAccess)) {
    return <Navigate to="/" />;
  }

  return children;
};

export default AuthRoute;
