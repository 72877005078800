import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgTextIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg height="24px" viewBox="0 0 384 384" width="24px" {...props}>
    <path
      d="M77.85,0c1.82,1.24,3.94,2.2,5.41,3.77,3.18,3.38,3.85,7.52,2.02,11.8-1.96,4.6-5.67,6.85-10.62,6.87-15.46,.05-30.93,.02-46.39,.02-1.49,0-2.98,0-5.07,0,0,1.43,0,2.74,0,4.04,0,15.71,.06,31.43-.02,47.14-.05,9.05-8.63,14.55-16.33,10.55-3.86-2.01-6.04-5.32-6.05-9.63-.09-21.2-.08-42.4-.02-63.6C.8,5.38,4.5,2.35,8.98,0,31.94,0,54.89,0,77.85,0Z"
      fill="currentColor"
    />
    <path
      d="M384,77.1c-1.23,1.82-2.19,3.94-3.76,5.41-3.38,3.18-7.51,3.85-11.79,2.03-4.59-1.95-6.87-5.64-6.88-10.59-.05-15.71-.02-31.41-.02-47.12,0-1.34,0-2.68,0-4.37h-12.59c-11.97,0-23.93,0-35.9,0-8.18,0-12.54-2.56-14.14-8.29-1.58-5.65,1.05-10.3,7.99-14.17h68.87c3.7,1.79,6.45,4.53,8.23,8.23V77.1Z"
      fill="currentColor"
    />
    <path
      d="M384,375.02c-2.98,6.49-8.12,8.45-15.08,8.34-19.45-.31-38.91-.08-58.37-.12-6.57-.01-11.39-4.12-11.94-10-.66-7,4.35-12.39,11.74-12.42,15.59-.06,31.18-.02,46.77-.02,1.34,0,2.68,0,4.41,0v-10.32c0-12.72,0-25.44,0-38.16,0-8.33,2.74-12.84,8.64-14.26,5.58-1.34,9.99,1.25,13.81,8.1v68.87Z"
      fill="currentColor"
    />
    <path
      d="M192.53,65.93c37.67,0,75.35,0,113.02,0,7.47,0,9.39,1.88,9.39,9.22,0,21.83,0,43.66,0,65.49,0,6.97-2.01,9-8.83,9.01-9.86,0-19.71,.01-29.57,0-6.1-.01-8.28-2.19-8.31-8.38-.04-6.99,.03-13.97-.03-20.96-.05-5.49-2.23-7.72-7.76-7.75-12.35-.07-24.7-.07-37.05,0-5.43,.03-7.74,2.4-7.74,7.83-.02,57.51-.02,115.02,0,172.53,0,5.35,2.37,7.64,7.86,7.73,4.99,.08,9.98-.04,14.97,.04,5.06,.08,7.27,2.24,7.31,7.36,.08,10.6,.08,21.21,0,31.81-.04,5.03-2.39,7.38-7.36,7.39-30.69,.04-61.38,.04-92.06,0-5.02,0-7.38-2.33-7.42-7.32-.08-10.73-.09-21.46,0-32.18,.04-4.84,2.32-6.97,7.23-7.05,5.11-.08,10.23,.06,15.34-.05,5.2-.11,7.56-2.47,7.56-7.65,.02-57.63,.02-115.27,0-172.9,0-4.99-2.34-7.47-7.29-7.51-12.72-.1-25.45-.11-38.17,0-4.81,.04-7.01,2.43-7.07,7.28-.08,7.23,.02,14.47-.04,21.71-.05,5.88-2.31,8.06-8.25,8.08-10.1,.02-20.21,.02-30.31,0-5.88-.01-8.13-2.24-8.13-8.19-.03-22.33-.02-44.66,0-66.99,0-6.39,2.17-8.53,8.57-8.53,38.05,0,76.1,0,114.14,0Z"
      fill="currentColor"
    />
    <path
      d="M23.2,360.8c10.81,0,21.14,0,31.47,0,6.73,0,13.47-.08,20.2,.03,6.48,.11,11.23,4.84,11.3,11.04,.07,6.38-4.7,11.33-11.35,11.36-20.95,.08-41.9,.09-62.84,0-6.36-.03-11.16-4.82-11.19-11.16-.09-21.07-.1-42.14,0-63.22,.03-6.37,5.09-11.06,11.31-11,6.21,.06,11.02,4.78,11.06,11.26,.1,15.71,.03,31.42,.04,47.13,0,1.36,0,2.71,0,4.58Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgTextIcon);
export default Memo;
