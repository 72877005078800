import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import clsx from 'clsx';
import { DeleteOutlined, LoadingOutlined, MenuOutlined } from '@ant-design/icons';

import { api, IGuidelineChapterTiny } from '@chess-class/sandbox';

export default function ChapterDraggableList({
  chapters,
  deletable,
  setChapters,
}: {
  chapters: IGuidelineChapterTiny[];
  deletable?: boolean;
  setChapters?: (chapters: IGuidelineChapterTiny[]) => void;
}) {
  const chapterPriorityMutate = api.guideline.guidelineChapters.useGuidelineChapterPriority();
  const chapterRemoveMutate = api.guideline.guidelineChapters.useGuidelineChapterRemove();

  const [isLoading, setIsLoading] = useState(false);

  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination || droppedItem.source.index == droppedItem.destination.index)
      return;

    if (!setChapters) {
      const chapter = chapters[droppedItem.source.index];

      if (chapter) {
        setIsLoading(true);
        chapterPriorityMutate
          .mutateAsync({
            chapterId: chapter.id,
            guideId: chapter.guidelineId,
            priority: droppedItem.destination.index + 1,
          })
          .finally(() => setIsLoading(false));
      }
      return;
    }

    const updatedList = [...chapters];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);

    setChapters?.(updatedList);
  };

  return isLoading ? (
    <div className="flex justify-center">
      <LoadingOutlined className="text-blue-400" />
    </div>
  ) : (
    <div className="App">
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="list-container">
          {(provided) => (
            <div
              className="list-container flex flex-col"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {chapters.map((item, index) => {
                return (
                  <Draggable draggableId={item.id} index={index} key={item.id}>
                    {(draggableProvided, snapshot) => {
                      return (
                        <div
                          className={clsx(
                            'item-container p-1 px-4 my-1 rounded-lg flex justify-between',
                            snapshot.isDragging
                              ? 'border-blue-600 border-2'
                              : 'border-blue-400 border',
                          )}
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.dragHandleProps}
                          {...draggableProvided.draggableProps}
                        >
                          <div className="flex items-center gap-3">
                            <MenuOutlined className="text-blue-600" />
                            <span>{item.translations?.ru?.title || item.id}</span>
                          </div>
                          {deletable &&
                          chapterRemoveMutate.isLoading &&
                          chapterRemoveMutate.variables?.chapterId == item.id ? (
                            <LoadingOutlined className="text-red-500" />
                          ) : (
                            <DeleteOutlined
                              className="hover:text-red-500 text-gray-500"
                              onClick={() => {
                                if (setChapters) {
                                  setChapters(chapters.filter((chapter) => chapter.id != item.id));
                                } else {
                                  chapterRemoveMutate.mutateAsync({
                                    chapterId: item.id,
                                    guideId: item.guidelineId,
                                  });
                                }
                              }}
                            />
                          )}
                        </div>
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
