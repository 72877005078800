import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'antd';
import moment from 'moment';
import { EditOutlined } from '@ant-design/icons';

import { api, IExercisesRequest } from '@chess-class/sandbox';

import BaseSkeleton from '~/components/atoms/BaseSkeleton';
import { NotFoundResult } from '~/components/atoms/NotFoundResult';
import { TitleAndText } from '~/components/atoms/TitleAndText';
import { AdminTable } from '~/components/organisms/adminTable/AdminTable';

import { exerciseColumns } from '~mMaterials/exercises/exercises/consts/columns/exerciseColumns';
import { exerciseFilters } from '~mMaterials/exercises/exercises/consts/filters/exerciseFilters';

export const ExerciseGroupViewModal: FC<{
  canEditExercises: boolean;
  id?: string;
  isOpen: boolean;
  onCancel: () => void;
  onOpenEdit: () => void;
}> = ({ canEditExercises, id = '', isOpen, onCancel, onOpenEdit }) => {
  const [filtered, setFiltered] = useState<IExercisesRequest>({
    page: 1,
    size: 10,
    sort: 'id',
  });

  const navigate = useNavigate();

  const exerciseGroupQuery = api.content.exercisesGroups.useExerciseGroup({ id });
  const exerciseGroup = exerciseGroupQuery.data?.data;

  const exercises = api.content.exercises.useExercises({ ...filtered, groupId: id });

  const exerciseDelete = api.content.exercises.useExerciseDelete();

  return (
    <Modal
      className="px-4 md:px-0 md:w-5/6 lg:w-4/5 pt-0 mt-0 top-8"
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={
        <div className="flex flex-row gap-4">
          <h2 className="text-2xl">Коллекция задач упражнений - #{id}</h2>

          <Button className="hidden md:block" icon={<EditOutlined />} onClick={onOpenEdit}>
            Редактировать
          </Button>

          <Button className="md:hidden" icon={<EditOutlined />} onClick={onOpenEdit} />
        </div>
      }
    >
      {exerciseGroupQuery.isLoading ? (
        <BaseSkeleton />
      ) : !exerciseGroup ? (
        <NotFoundResult title="Коллекция задач не найдена" />
      ) : (
        <div className="space-y-8">
          <TitleAndText light text={exerciseGroup.id} title="ID коллекции" />
          <TitleAndText light text={exerciseGroup.name} title="Название коллекции" />
          <TitleAndText
            light
            text={moment(exerciseGroup.createdDate).format('LLL')}
            title="Дата создания"
          />
          <TitleAndText light text={exerciseGroup.exercisesCount} title="Количество упражнений" />

          <AdminTable
            columns={exerciseColumns({
              canEdit: canEditExercises,
              onClickDelete: exerciseDelete.mutateAsync,
              onClickEdit: ({ id }) => navigate(`/materials/exercises?action=edit&actionId=${id}`),
              onClickView: ({ id }) => navigate(`/materials/exercises?action=view&actionId=${id}`),
            })}
            emptyDescription={<p>Промокоды не найдены</p>}
            position={['bottomLeft']}
            query={exercises}
            tableFilter={{ filtered, filters: exerciseFilters, onFilter: setFiltered }}
          />
        </div>
      )}
    </Modal>
  );
};
