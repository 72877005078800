import { FC } from 'react';
import { Button, Modal, Result } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Select } from 'formik-antd';
import { object, string } from 'yup';
import { SearchOutlined } from '@ant-design/icons';

import { api, ISuperCoachUpdateRequest } from '@chess-class/sandbox';
import { notify } from '@chess-class/sandbox/utils';

import BaseSkeleton from '~/components/atoms/BaseSkeleton';

export const ReassignReassignSuperCoachModal: FC<{
  id?: string;
  isOpen: boolean;
  onCancel: () => void;
}> = ({ id = '', isOpen, onCancel }) => {
  const coachQuery = api.user.coachToSchool.useCoachToSchool({ id });
  const coach = coachQuery.data?.data;
  const superCoachEdit = api.schools.schools.useSchoolUpdate();
  const coachDeactivate = api.user.coachToSchool.useCoachDeactivate();

  const coaches = api.user.coachToSchool.useCoachesToSchool({
    schoolIds: coach?.schoolId ? [coach?.schoolId] : [],
    statuses: ['ACTIVE'],
  });

  return (
    <Modal
      className="px-4 md:px-0 md:w-3/4 lg:w-2/3"
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={
        <h1 className="text-xl m-0 space-x-1">
          <span>Переназначение тренера</span>
        </h1>
      }
    >
      {coachQuery.isLoading ? (
        <BaseSkeleton />
      ) : !coach ? (
        <Result icon={<SearchOutlined />} title="Тренер не найден" />
      ) : (
        <Formik<ISuperCoachUpdateRequest>
          enableReinitialize
          initialValues={{
            id: coach.schoolId ?? '',
            superCoachId: coach.coachUserId,
          }}
          onSubmit={(values) => {
            superCoachEdit
              .mutateAsync({
                id: values.id,
                superCoachId: values.superCoachId,
              })
              .then(() => {
                coachDeactivate.mutateAsync({
                  id,
                });
              })
              .then(() => {
                notify('success', 'Тренер успешно переназначен');
              });
            onCancel();
          }}
          validationSchema={object({
            id: string().required('Обязательно для заполнения'),
            superCoachId: string().required('Поле обязательно лдля заполнения'),
          })}
        >
          {() => (
            <Form className="space-y-6" layout="vertical">
              <FormItem label="Тренера" name="superCoachId">
                <Select
                  allowClear
                  className="gap-y-2"
                  name="superCoachId"
                  optionFilterProp="label"
                  options={
                    coaches.data?.data.content
                      ? coaches.data?.data.content.map((coach) => ({
                          label: `${coach.firstName} ${coach.lastName}`,
                          value: coach.coachUserId,
                        }))
                      : []
                  }
                  placeholder="Выберите нового супер-тренера"
                  showSearch
                />
              </FormItem>
              <div className="grid md:grid-cols-2 gap-5">
                <Button block onClick={onCancel}>
                  Отменить
                </Button>

                <Button block htmlType="submit" type="primary">
                  <span className="max-w-full overflow-ellipsis overflow-hidden">Сохранить</span>
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};
