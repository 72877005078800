import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgFaChessKnightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="currentColor"
    height="24px"
    stroke="currentColor"
    strokeWidth={0}
    viewBox="0 0 384 512"
    width="24px"
    {...props}
  >
    <path
      d="M19 272.47l40.63 18.06a32 32 0 0 0 24.88.47l12.78-5.12a32 32 0 0 0 18.76-20.5l9.22-30.65a24 24 0 0 1 12.55-15.65L159.94 208v50.33a48 48 0 0 1-26.53 42.94l-57.22 28.65A80 80 0 0 0 32 401.48V416h319.86V224c0-106-85.92-192-191.92-192H12A12 12 0 0 0 0 44a16.9 16.9 0 0 0 1.79 7.58L16 80l-9 9a24 24 0 0 0-7 17v137.21a32 32 0 0 0 19 29.26zM52 128a20 20 0 1 1-20 20 20 20 0 0 1 20-20zm316 320H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h352a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z"
      fill="currentColor"
      stroke="none"
    />
  </svg>
);
const Memo = memo(SvgFaChessKnightIcon);
export default Memo;
