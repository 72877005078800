import { IPageActions } from '@chess-class/sandbox';

enum ExercisesPageActionEnum {
  'createTest',
  'createProblem',
  'edit',
  'view',
  'viewVideo',
  'createCustomGame',
}

export type ExercisesPageActions = {
  action: keyof typeof ExercisesPageActionEnum;
  actionId: string;
};

export const exercisesPageActions: IPageActions<ExercisesPageActions> = {
  action: {
    allowed: (value) => Object.keys(ExercisesPageActionEnum).includes(value),
    default: '',
  },
  actionId: { default: 0 },
};
