import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgVscPlayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="currentColor"
    height="24px"
    stroke="currentColor"
    strokeWidth={0}
    viewBox="0 0 16 16"
    width="24px"
    {...props}
  >
    <path
      d="M3.78 2L3 2.41v12l.78.42 9-6V8l-9-6zM4 13.48V3.35l7.6 5.07L4 13.48z"
      fill="currentColor"
      stroke="none"
    />
  </svg>
);
const Memo = memo(SvgVscPlayIcon);
export default Memo;
