import { FC, useEffect, useState } from 'react';
import { Button, Switch as AntSwitch, Tabs } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, Radio, Select, Switch } from 'formik-antd';

import {
  api,
  IEnumCustomProblemType,
  IEnumExerciseTarget,
  IExercise,
  IExerciseCustomProblem,
} from '@chess-class/sandbox';
import { ExerciseCustomProblem } from '@chess-class/sandbox/components';
import { notify } from '@chess-class/sandbox/utils';

import { useEducationFormat } from '~/context/EducationFormContext';

const { TabPane } = Tabs;

export const CustomGameEditFormik: FC<{
  exercise: IExercise & { customProblem: IExerciseCustomProblem };
  onCancel: () => void;
}> = ({ exercise, onCancel }) => {
  const customGameEdit = api.content.exercises.useExerciseCustomGameUpdate();
  const [indicateMoveCount, setIndicateMoveCount] = useState(false);
  const { defaultEducationFormat } = useEducationFormat();
  const chapters = api.content.chapters.useChapters({
    educationFormat: defaultEducationFormat,
    size: 9999,
  });

  useEffect(() => setIndicateMoveCount(!!exercise.customProblem.moveCount), [exercise]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...api.content.exercises.helpers.exerciseCustomGameCreateInitial,
        ...exercise.customProblem,
        chapterId: exercise.chapter?.id,
        educationFormat: defaultEducationFormat,
        id: exercise.id,
        level: exercise.level,
        target: exercise.target,
      }}
      onSubmit={(values, { resetForm }) => {
        customGameEdit.mutateAsync(values).then((data) => {
          if (data.status == 201 || data.status == 200) {
            notify('success', 'Кастомное упражнение изменено');
            onCancel();
            resetForm();
          }
        });
      }}
    >
      {({ setFieldValue, values }) => {
        return (
          <Tabs defaultActiveKey="form">
            <TabPane key="form" tab="Форма">
              <Form className="space-y-6" layout="vertical" validateTrigger="submit">
                <FormItem label="FEN" name="boardState">
                  <Input maxLength={100} name="boardState" placeholder="Введите FEN" suffix />
                </FormItem>
                <FormItem label="Звезды/Фигуры" name="type">
                  <Radio.Group
                    name="type"
                    options={Object.entries(IEnumCustomProblemType).map(([key, value]) => ({
                      label: value,
                      value: key,
                    }))}
                  />
                </FormItem>
                {values.type == 'FIGURE' && (
                  <FormItem
                    label={<span className="text-center">Может ли фигура соперника есть?</span>}
                    name="opponentCanTake"
                  >
                    <Switch name="opponentCanTake" />
                  </FormItem>
                )}
                <Form.Item
                  label={<span className="text-center">Указать кол-во ходов</span>}
                  name="status"
                >
                  <Input hidden name="status" suffix />
                  <AntSwitch
                    checked={indicateMoveCount}
                    onChange={(checked) => setIndicateMoveCount(checked)}
                  />
                </Form.Item>
                {indicateMoveCount && (
                  <FormItem
                    label={<span className="text-center">Указать кол-во</span>}
                    name="moveCount"
                  >
                    <Input name="moveCount" />
                  </FormItem>
                )}

                <FormItem label="Тема" name="chapterId">
                  <Select
                    className="gap-y-2"
                    name="chapterId"
                    optionFilterProp="label"
                    options={
                      chapters.data?.data.content.map((chapter) => ({
                        label: chapter.translations.ru?.title ?? '-',
                        value: chapter.id,
                      })) || []
                    }
                  />
                </FormItem>
                <FormItem label="Место для применения" name="target">
                  <Select
                    className="gap-y-2"
                    name="target"
                    optionFilterProp="label"
                    options={
                      Object.keys(IEnumExerciseTarget).map((key) => ({
                        label: IEnumExerciseTarget[key],
                        value: key,
                      })) || []
                    }
                  />
                </FormItem>
                <Form.Item
                  label={<span className="text-center">Запустить немедленно</span>}
                  name="status"
                >
                  <Input hidden name="status" suffix />
                  <AntSwitch
                    checked={values.status == 'ACTIVE'}
                    onChange={(checked) => setFieldValue('status', checked ? 'ACTIVE' : 'DRAFT')}
                  />
                </Form.Item>
                <div className="grid md:grid-cols-2 gap-5">
                  <Button block onClick={onCancel}>
                    Отменить
                  </Button>
                  <Button block htmlType="submit" type="primary">
                    <span className="max-w-full overflow-ellipsis overflow-hidden">
                      {values['status'] == 'ACTIVE'
                        ? 'Создать и запустить'
                        : 'Сохранить как черновик'}
                    </span>
                  </Button>
                </div>
              </Form>
            </TabPane>
            <TabPane key="board" tab="Доска">
              <ExerciseCustomProblem
                customProblem={exercise.customProblem}
                sidebar={{ show: true, tryAgain: true }}
              />
            </TabPane>
          </Tabs>
        );
      }}
    </Formik>
  );
};
