import { FC } from 'react';

import { api, IEnumEducationFormatKeys, IPageAbleRequest } from '@chess-class/sandbox';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';

import { AdminTable } from '~/components/organisms/adminTable/AdminTable';
import { PageMeta } from '~/context/PageMetaContext';

import { reviewsColumns } from '../consts/columns/reviewsColumns';
import { reviewFilters } from '../consts/filters/reviewFilters';
import { reviewPageActions } from '../consts/pageActions/ReviewsPageActions';

export type IReviewsRequest = IPageAbleRequest & {
  contentId?: string;
  contentType?: string;
  educationFormat?: IEnumEducationFormatKeys;
  schoolName?: string;
  userFullName?: string;
  userId?: string;
};

export const ReviewsPage: FC = () => {
  const { filtered, onFilter } = useUrlFilteredActioned<IReviewsRequest, undefined>(
    reviewFilters,
    reviewPageActions,
  );

  const reviews = api.reviews.reviews.useReviews({ ...filtered });

  return (
    <div className="flex flex-col gap-4">
      <PageMeta openMenuKeys={['reviews']} selectedMenuKeys={['reviews']} title="Отзывы" />

      <AdminTable
        columns={reviewsColumns()}
        emptyDescription={
          <div>
            <p>Отзывы не найдены.</p>
          </div>
        }
        query={reviews}
        tableFilter={{ filtered, filters: reviewFilters, onFilter }}
      />
    </div>
  );
};
