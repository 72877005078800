import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgMenuCollapsedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg height="24px" viewBox="0 0 384 384" width="24px" {...props}>
    <path
      d="M192.37,111.64c-42.03,0-84.06,0-126.09,0-9.13,0-15.1-4.22-17.15-12-2.5-9.49,4.31-19.05,14.08-19.74,.99-.07,1.99-.08,2.99-.08,84.06,0,168.13,0,252.19,0,9.14,0,15.09,4.2,17.15,11.99,2.5,9.49-4.3,19.05-14.08,19.74-1.61,.11-3.24,.09-4.86,.09-41.41,0-82.82,0-124.22,0Z"
      fill="currentColor"
    />
    <path
      d="M144.44,207.49c-26.31,0-52.62,.04-78.93-.02-9.78-.02-16.8-6.65-16.9-15.69-.09-8.55,6.46-15.98,14.99-16,53.87-.13,107.74-.13,161.6,0,8.54,.02,15.12,7.43,15.04,15.96-.08,8.64-6.83,15.63-15.74,15.68-25.19,.14-50.38,.06-75.56,.07-1.5,0-2.99,0-4.49,0Z"
      fill="currentColor"
    />
    <path
      d="M104.55,303.29c-13.59,0-27.18,.14-40.77-.06-7.85-.11-14.2-6.27-15.1-13.98-.9-7.69,3.97-15,11.48-17.14,1.18-.34,2.45-.54,3.68-.54,27.06-.03,54.12-.11,81.17,.02,7.82,.04,14.24,6.01,15.16,13.43,1.02,8.18-3.47,15.39-11.19,17.61-1.99,.57-4.18,.67-6.27,.68-12.72,.06-25.44,.03-38.16,.03,0-.02,0-.03,0-.05Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgMenuCollapsedIcon);
export default Memo;
