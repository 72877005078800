import { IPageActions } from '@chess-class/sandbox';

enum VideoFilesPageActionEnum {
  'create',
  'view',
  'edit',
}

export type IVideoFilesPageActions = {
  action: keyof typeof VideoFilesPageActionEnum;
  actionId: string;
};

export const videoFilesPageActions: IPageActions<IVideoFilesPageActions> = {
  action: {
    allowed: (value) => Object.keys(VideoFilesPageActionEnum).includes(value),
    default: '',
  },
  actionId: { default: '' },
};
