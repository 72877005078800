import { FC } from 'react';
import { Button } from 'antd';

import { api, ICoachesToSchoolRequest } from '@chess-class/sandbox';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';

import { AdminTable } from '~/components/organisms/adminTable/AdminTable';
import { useEducationFormat } from '~/context/EducationFormContext';
import { PageMeta } from '~/context/PageMetaContext';
import { checkEditPermission } from '~/utils/checkEditPermission';

import { coachColumns } from '../../consts/columns/coachColumns';
import { coachFilters } from '../../consts/filters/coachFilters';
import {
  CoachesPageActions,
  coachesPageActions,
} from '../../consts/pageActions/CoachesPageActions';
import { CoachCreateModal } from '../organisms/CoachCreateModal';
import { CoachEditModal } from '../organisms/CoachEditModal';
import { ReassignReassignSuperCoachModal } from '../organisms/ReassignSuperCoachModal';

import SchoolsTabs from '~mSchools/SchoolsTabs';

export const CoachesPage: FC = () => {
  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    ICoachesToSchoolRequest,
    CoachesPageActions
  >(coachFilters, coachesPageActions);
  const { defaultEducationFormat } = useEducationFormat();
  const coaches = api.user.coachToSchool.useCoachesToSchool({
    ...filtered,
    educationFormat: defaultEducationFormat,
  });
  const coachActivate = api.user.coachToSchool.useCoachActivate();
  const coachDeactivate = api.user.coachToSchool.useCoachDeactivate();
  const coachDelete = api.user.coachToSchool.useCoachDelete();
  const coachInviteResend = api.user.coachAuth.useCoachInviteResend();
  const canEditCoach = checkEditPermission('schools');

  return (
    <div className="flex flex-col gap-4">
      <SchoolsTabs active="coaches" />
      <PageMeta selectedMenuKeys={['schools']} title="Список тренеров" />

      {canEditCoach && (
        <div className="flex justify-end gap-3">
          <Button
            className="sm:px-12"
            onClick={() => setActioned({ action: 'invite' })}
            size="large"
            type="primary"
          >
            Добавить тренера
          </Button>
        </div>
      )}

      <AdminTable
        columns={coachColumns({
          canEdit: canEditCoach,
          onClickActivate: coachActivate.mutateAsync,
          onClickDeactivate: coachDeactivate.mutateAsync,
          onClickDelete: coachDelete.mutateAsync,
          onClickEdit: ({ id }) => setActioned({ action: 'edit', actionId: id }),
          onClickInvitationResend: coachInviteResend.mutateAsync,
          onClickSuperCoach: ({ id }) => setActioned({ action: 'super-coach', actionId: id }),
        })}
        emptyDescription={
          <div>
            <p>Тренера не найдены.</p>
            <p>Вы можете добавить тренера, нажав на кнопку “Добавить тренера”</p>
          </div>
        }
        query={coaches}
        tableFilter={{ filtered, filters: coachFilters, onFilter }}
      />

      <ReassignReassignSuperCoachModal
        id={actioned.actionId}
        isOpen={actioned.action == 'super-coach'}
        onCancel={clearParams}
      />
      <CoachCreateModal isOpen={actioned.action == 'invite'} onCancel={clearParams} />
      <CoachEditModal
        id={actioned.actionId}
        isOpen={actioned.action == 'edit'}
        onCancel={clearParams}
      />
    </div>
  );
};
