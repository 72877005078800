import { FC, useState } from 'react';
import { Button, Modal, Switch as AntSwitch } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, Radio, Select, Switch } from 'formik-antd';

import { api, IEnumCustomProblemType, IEnumExerciseTarget } from '@chess-class/sandbox';
import { notify } from '@chess-class/sandbox/utils';

import { useEducationFormat } from '~/context/EducationFormContext';

export const ExerciseCustomProblemCreateModal: FC<{
  isOpen: boolean;
  onCancel: () => void;
}> = ({ isOpen, onCancel }) => {
  const customGameCreate = api.content.exercises.useExerciseCustomGameCreate();
  const [indicateMoveCount, setIndicateMoveCount] = useState(false);
  const { defaultEducationFormat } = useEducationFormat();
  const chapters = api.content.chapters.useChapters({
    educationFormat: defaultEducationFormat,
    size: 9999,
  });

  return (
    <Modal
      className="px-4 w-[600px]"
      footer={null}
      onCancel={() => {
        onCancel();
      }}
      open={isOpen}
      title={
        <h1 className="text-xl m-0 space-x-1">
          <span>Создать кастомное упраженение</span>
        </h1>
      }
    >
      <Formik
        enableReinitialize
        initialValues={api.content.exercises.helpers.exerciseCustomGameCreateInitial}
        onSubmit={(values, { resetForm }) => {
          customGameCreate
            .mutateAsync({ ...values, educationFormat: defaultEducationFormat })
            .then((data) => {
              if (data.status == 201 || data.status == 200) {
                notify('success', 'Кастомное упраженение создано');
                onCancel();
                resetForm();
              }
            });
        }}
        // validationSchema={api.content.exercises.helpers.exerciseCustomGameCreateValidation}
      >
        {({ setFieldValue, values }) => {
          return (
            <Form className="space-y-6" layout="vertical" validateTrigger="submit">
              <FormItem label="FEN" name="boardState">
                <Input maxLength={100} name="boardState" placeholder="Введите FEN" suffix />
              </FormItem>
              <FormItem label="Звезды/Фигуры" name="type">
                <Radio.Group
                  name="type"
                  options={Object.entries(IEnumCustomProblemType).map(([key, value]) => ({
                    label: value,
                    value: key,
                  }))}
                />
              </FormItem>
              {values.type == 'FIGURE' && (
                <FormItem
                  label={<span className="text-center">Может ли фигура соперника есть?</span>}
                  name="opponentCanTake"
                >
                  <Switch name="isCapitalization" />
                </FormItem>
              )}
              <Form.Item
                label={<span className="text-center">Указать кол-во ходов</span>}
                name="status"
              >
                <Input hidden name="status" suffix />
                <AntSwitch
                  checked={indicateMoveCount}
                  onChange={(checked) => setIndicateMoveCount(checked)}
                />
              </Form.Item>
              {indicateMoveCount && (
                <FormItem
                  label={<span className="text-center">Указать кол-во</span>}
                  name="moveCount"
                >
                  <Input name="moveCount" />
                </FormItem>
              )}

              <FormItem label="Тема" name="chapterId">
                <Select
                  className="gap-y-2"
                  name="chapterId"
                  optionFilterProp="label"
                  options={
                    chapters.data?.data.content.map((chapter) => ({
                      label: chapter.translations.ru?.title ?? '-',
                      value: chapter.id,
                    })) || []
                  }
                />
              </FormItem>

              <FormItem label="Место для применения" name="target">
                <Select
                  className="gap-y-2"
                  name="target"
                  optionFilterProp="label"
                  options={
                    Object.keys(IEnumExerciseTarget).map((key) => ({
                      label: IEnumExerciseTarget[key],
                      value: key,
                    })) || []
                  }
                />
              </FormItem>
              <Form.Item
                label={<span className="text-center">Запустить немедленно</span>}
                name="status"
              >
                <Input hidden name="status" suffix />
                <AntSwitch
                  checked={values.status == 'ACTIVE'}
                  onChange={(checked) => setFieldValue('status', checked ? 'ACTIVE' : 'DRAFT')}
                />
              </Form.Item>

              <div className="flex flex-row gap-4">
                <Button block onClick={onCancel}>
                  Отмена
                </Button>
                <Button block htmlType="submit" type="primary">
                  <span className="max-w-full overflow-ellipsis overflow-hidden">
                    {values['status'] == 'ACTIVE'
                      ? 'Создать и запустить'
                      : 'Сохранить как черновик'}
                  </span>
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
