import { FC, useMemo } from 'react';
import { Space, Tag } from 'antd';
import tw from 'twin.macro';
import styled from '@emotion/styled';

import { StatusEnum, StatusEnumKeys } from '@chess-class/sandbox';

type StatusProps = {
  readonly status?: StatusEnumKeys | null;
};

const StatusWrapper = styled(Space)`
  ${tw`flex items-center justify-start`}
`;

export const StatusTag: FC<StatusProps> = ({ status }) => {
  const statusColor = useMemo(() => {
    switch (status) {
      case 'ACTIVE':
        return 'success';
      case 'DRAFT':
      case 'INVITED':
        return 'warning';
      case 'DELETED':
      case 'DISABLED':
      case 'INACTIVE':
      case 'PAUSED':
        return 'error';
    }
  }, [status]);

  return (
    <StatusWrapper>
      {!!status && (
        <Tag className="text-center px-2" color={statusColor}>
          {StatusEnum[status]}
        </Tag>
      )}
    </StatusWrapper>
  );
};
