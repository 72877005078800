import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgPencilIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="currentColor" height="20px" viewBox="-3.5 -4 20.5 20.5" width="20px" {...props}>
    <path
      d="M12.1172 2.78906L12.9062 2.01562C13.3047 1.61719 13.3203 1.1875 12.9688 0.828125L12.7031 0.5625C12.3516 0.210938 11.9219 0.25 11.5234 0.632812L10.7344 1.41406L12.1172 2.78906ZM2.90625 11.9766L11.4297 3.46094L10.0625 2.08594L1.53125 10.6094L0.789062 12.3438C0.71875 12.5312 0.914062 12.75 1.10156 12.6719L2.90625 11.9766Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgPencilIcon);
export default Memo;
