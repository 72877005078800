import { ILocation } from '@chess-class/sandbox';

export const extractLocation = (
  location: ILocation | undefined,
  key: 'isDistrict' | 'isLocality' | 'isRegion',
): ILocation | undefined => {
  for (const prop in location) {
    if (prop === key && location[key]) {
      return location; // Key found, return the object
    }

    if (typeof location[prop] === 'object') {
      const result = extractLocation(location[prop], key);

      if (result !== undefined) {
        return result;
      }
    }
  }

  return undefined;
};
