import { FC } from 'react';
import { Button } from 'antd';

import { api, IAuthorsRequest } from '@chess-class/sandbox';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';

import { AdminTable } from '~/components/organisms/adminTable/AdminTable';
import { PageMeta } from '~/context/PageMetaContext';
import { checkEditPermission } from '~/utils/checkEditPermission';

import { AuthorCreateModal } from '~mMaterials/videos/videos-authors/components/organisms/AuthorCreateModal';
import { AuthorEditModal } from '~mMaterials/videos/videos-authors/components/organisms/AuthorEditModal';
import { AuthorViewModal } from '~mMaterials/videos/videos-authors/components/organisms/AuthorViewModal';
import {
  AuthorsPageActions,
  authorsPageActions,
} from '~mMaterials/videos/videos-authors/consts/AuthorsPageActions';
import { authorsColumns } from '~mMaterials/videos/videos-authors/consts/columns/authorsColumns';
import { authorsFilters } from '~mMaterials/videos/videos-authors/consts/filters/authorFilters';
import VideoTabs from '~mMaterials/videos/VideoTabs';

const AuthorsPage: FC = () => {
  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    IAuthorsRequest,
    AuthorsPageActions
  >(authorsFilters, authorsPageActions);
  const authors = api.content.authors.useAuthors(filtered);
  const authorDelete = api.content.authors.useAuthorDelete();

  const canEditAuthors = checkEditPermission('materials');

  return (
    <div className="flex flex-col gap-4">
      <VideoTabs active="authors" />

      <PageMeta
        openMenuKeys={['materials']}
        selectedMenuKeys={['materials-videos']}
        title="Авторы"
      />

      <div className="flex justify-end gap-4">
        <Button
          className="px-12"
          disabled={!canEditAuthors}
          onClick={() => setActioned({ action: 'create' })}
          size="large"
          type="primary"
        >
          Новый автор
        </Button>
      </div>
      <AdminTable
        columns={authorsColumns({
          canEdit: canEditAuthors,
          onClickDelete: authorDelete.mutateAsync,
          onClickEdit: ({ id }) => setActioned({ action: 'edit', actionId: id }),
          onClickView: ({ id }) => setActioned({ action: 'view', actionId: id }),
        })}
        emptyDescription={
          <div>
            <p>Авторов не найдено.</p>
            <p>Вы можете создать автора, нажав на кнопку “Создать”</p>
          </div>
        }
        query={authors}
        tableFilter={{ filtered, filters: authorsFilters, onFilter }}
      />

      <AuthorCreateModal isOpen={actioned.action == 'create'} onCancel={clearParams} />

      <AuthorEditModal
        id={actioned.actionId}
        isOpen={actioned.action == 'edit'}
        onCancel={clearParams}
      />

      <AuthorViewModal
        id={actioned.actionId}
        isOpen={actioned.action == 'view'}
        onCancel={clearParams}
      />
    </div>
  );
};

export default AuthorsPage;
