import { FC } from 'react';
import { Button } from 'antd';

import { api, IVideoFilesRequest } from '@chess-class/sandbox';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';

import { AdminTable } from '~/components/organisms/adminTable/AdminTable';
import { PageMeta } from '~/context/PageMetaContext';
import { checkEditPermission } from '~/utils/checkEditPermission';

import { VideoFileUpdateModal } from '~mMaterials/videos/videos-files/components/organisms/VideoFileUpdateModal';
import { VideoFileUploadModal } from '~mMaterials/videos/videos-files/components/organisms/VideoFileUploadModal';
import { VideoFileViewModal } from '~mMaterials/videos/videos-files/components/organisms/VideoFileViewModal';
import { videoFilesColumns } from '~mMaterials/videos/videos-files/consts/columns/videoFilesColumns';
import { videoFilesFilters } from '~mMaterials/videos/videos-files/consts/filters/videoFilesFilters';
import {
  IVideoFilesPageActions,
  videoFilesPageActions,
} from '~mMaterials/videos/videos-files/consts/pageActions/IVideoFilesPageActions';
import VideoTabs from '~mMaterials/videos/VideoTabs';

export const VideoFilesPage: FC = () => {
  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    IVideoFilesRequest,
    IVideoFilesPageActions
  >(videoFilesFilters, videoFilesPageActions);

  const videoFiles = api.content.videosFiles.useVideoFiles(filtered);

  const videoFileDelete = api.content.videosFiles.useVideoFileDelete();
  const canEditVideo = checkEditPermission('materials');

  return (
    <div className="flex flex-col gap-4">
      <VideoTabs active="files" />

      <PageMeta
        openMenuKeys={['materials']}
        selectedMenuKeys={['materials-videos']}
        subTitle={
          actioned.action == 'view' && actioned.actionId ? `Видеофайл #${actioned.actionId}` : ''
        }
        title="Видеофайлы"
      />

      {canEditVideo && (
        <div className="flex justify-end gap-4">
          <Button
            className="sm:px-12"
            onClick={() => setActioned({ action: 'create' })}
            size="large"
            type="default"
          >
            Новый видеофайл
          </Button>
        </div>
      )}

      <AdminTable
        columns={videoFilesColumns({
          canEdit: canEditVideo,
          onClickDelete: videoFileDelete.mutateAsync,
          onClickEdit: ({ uuid }) => setActioned({ action: 'edit', actionId: uuid }),
          onClickView: ({ uuid }) => setActioned({ action: 'view', actionId: uuid }),
        })}
        emptyDescription={
          <div>
            <p>Видеофайлы не найдены.</p>
            <p>Вы можете создать видеофайл, нажав на кнопку “Новый видеофайл”</p>
          </div>
        }
        query={videoFiles}
        rowKey="uuid"
        tableFilter={{ filtered, filters: videoFilesFilters, onFilter }}
      />

      <VideoFileViewModal
        isOpen={actioned.action == 'view'}
        onCancel={clearParams}
        uuid={actioned.actionId}
      />

      <VideoFileUploadModal isOpen={actioned.action == 'create'} onCancel={clearParams} />

      <VideoFileUpdateModal
        fileUuid={actioned.actionId}
        isOpen={actioned.action == 'edit'}
        onCancel={clearParams}
      />
    </div>
  );
};
