import { FC } from 'react';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

import { TableSortEnum } from '@chess-class/sandbox';

type SortType = {
  dataIndex: string;
  onFilter: (key: object) => void;
  orderedBy?: string;
  sortOrderReversed?: boolean;
  sortedBy?: string;
};

export const TableHeaderSortIcon: FC<SortType> = ({
  dataIndex,
  onFilter,
  orderedBy,
  sortOrderReversed,
  sortedBy,
}) => {
  const isActive = sortedBy == dataIndex;
  const orders = sortOrderReversed
    ? [TableSortEnum.DESC, TableSortEnum.ASC]
    : [TableSortEnum.ASC, TableSortEnum.DESC];
  const nextOrderBy =
    isActive && orderedBy == orders[0]
      ? orders[1]
      : isActive && orderedBy == orders[1]
        ? undefined
        : orders[0];

  return (
    <span
      className=" ant-table-filter-trigger inline-flex flex-col justify-center text-xs"
      onClick={(e) => {
        e.stopPropagation();
        onFilter({
          ['sort']: nextOrderBy
            ? `${nextOrderBy === TableSortEnum.DESC ? '-' : ''}${dataIndex}`
            : undefined,
        });
      }}
    >
      <CaretUpOutlined
        className={`h-2.5 ${orderedBy == TableSortEnum.ASC && isActive && 'text-ant-blue-6'}`}
      />
      <CaretDownOutlined
        className={`h-2.5 ${orderedBy == TableSortEnum.DESC && isActive && 'text-ant-blue-6'}`}
      />
    </span>
  );
};
