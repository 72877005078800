import { IPageActions } from '@chess-class/sandbox';

enum SchoolPageActionsEnum {
  'view',
  'create',
  'edit',
}

export type SchoolPageActions = {
  action: keyof typeof SchoolPageActionsEnum;
  actionId: string;
};

export const schoolPageActions: IPageActions<SchoolPageActions> = {
  action: {
    allowed: (value) => Object.keys(SchoolPageActionsEnum).includes(value),
    default: '',
  },
  actionId: { default: '' },
};
