import { Button, Tag } from 'antd';
import moment from 'moment';
import { CalendarOutlined, EditOutlined } from '@ant-design/icons';

import { IEnumGuidelineStatus, IGuideline } from '@chess-class/sandbox';

import { useEducationFormat } from '~/context/EducationFormContext';
import { userPlayLevels } from '~/modules/materials/exercises/exercises/types/UserPlayLevel';

export const GuidelineCollapseLabel = ({
  editable,
  guideline: { createdAt, grade, name, status, version },
  onEdit,
}: {
  editable?: boolean;
  guideline: IGuideline;
  onEdit: () => void;
}) => {
  const { defaultEducationFormat } = useEducationFormat();
  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-4 items-center">
        <span>Методичка - {name} </span>
        <Tag color={status == 'PUBLISHED' ? 'success' : 'default'}>
          {IEnumGuidelineStatus[status]}
        </Tag>
        {editable && status == 'EDITABLE' && (
          <Button
            icon={<EditOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              onEdit();
            }}
            size="small"
          />
        )}
      </div>
      {!!createdAt && status == 'PUBLISHED' && (
        <div className="flex gap-1 items-center">
          <CalendarOutlined /> Дата:
          {moment(createdAt).utc().format(' DD.MM.YYYY H:mm')}
        </div>
      )}
      <div className="flex gap-2 items-center">
        <Tag color="blue">
          {defaultEducationFormat == 'GENERAL'
            ? `Класс: ${grade}`
            : `Уровень ${userPlayLevels[grade]}`}
        </Tag>
        <span className="text-sm text-gray-700">Версия: {version}</span>
      </div>
    </div>
  );
};
