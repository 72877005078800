import { FC, useMemo, useState } from 'react';
import { Button, Modal, Select as SelectAntd } from 'antd';
import { AxiosError } from 'axios';
import { Formik } from 'formik';
import { Form, FormItem, Input, Select } from 'formik-antd';

import { api, IGuidelineChapterTiny, IGuidelineCreateRequest } from '@chess-class/sandbox';
import { modelKeys } from '@chess-class/sandbox/consts';
import { notify } from '@chess-class/sandbox/utils';

import { useEducationFormat } from '~/context/EducationFormContext';
import { userPlayLevels } from '~/modules/materials/exercises/exercises/types/UserPlayLevel';

import ChapterDraggableList from '~mMaterials/guidelines/components/molecules/ChapterDraggableList';

export const GuidelineCreateModal: FC<{
  isOpen: boolean;
  onCancel: () => void;
}> = ({ isOpen, onCancel }) => {
  const [selectedChapters, setSelectedChapters] = useState<IGuidelineChapterTiny[]>([]);
  const { defaultEducationFormat } = useEducationFormat();

  const chaptersQuery = api.guideline.guidelineChapters.useGuidelineChapters({
    educationFormat: defaultEducationFormat,
    size: 200,
  });

  const chapters = chaptersQuery.data?.data.content;

  const chaptersFiltered = useMemo(() => {
    const selectedChapterIds = selectedChapters.map((chapter) => chapter.id);
    return chapters?.filter((chapter) => !selectedChapterIds.includes(chapter.id));
  }, [chapters, selectedChapters]);
  const guidelineCreate = api.guideline.guideline.useGuidelineCreate();
  const guideChapterAdd = api.guideline.guidelineChapters.useGuidelineChapterAdd();

  return (
    <Modal
      className="px-4 md:px-0 md:w-3/4 lg:w-2/3"
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title="Новая методичка"
    >
      <Formik<IGuidelineCreateRequest>
        enableReinitialize
        initialValues={api.guideline.guideline.helpers.guidelineCreateInitial}
        onSubmit={(values, { resetForm, setErrors }) => {
          guidelineCreate
            .mutateAsync({ ...values, educationFormat: defaultEducationFormat })
            .then((data) => {
              if (data.status == 201 || data.status == 200) {
                selectedChapters.forEach(
                  async (chap) =>
                    await guideChapterAdd.mutateAsync({
                      chapterId: chap.id,
                      guideId: data.data.id,
                    }),
                );
                notify('success', 'Методичка создана');
                onCancel();
                resetForm();
              }
            })
            .catch((axiosError: AxiosError) => {
              if (axiosError.response?.status == 422) {
                setErrors({ grade: 'Редактируемая методичка с данным классом уже существует' });
              }
            });
        }}
        validationSchema={api.guideline.guideline.helpers.guidelineCreateValidation}
      >
        {() => (
          <Form className="flex flex-col gap-2 mt-6" layout="vertical">
            <FormItem label="Название" name={modelKeys.guideline.guideline.name}>
              <Input name={modelKeys.guideline.guideline.name} suffix />
            </FormItem>
            <FormItem
              label={defaultEducationFormat === 'GENERAL' ? 'Класс' : 'Уровень'}
              name={modelKeys.guideline.guideline.grade}
            >
              <Select
                name={modelKeys.guideline.guideline.grade}
                options={
                  defaultEducationFormat == 'GENERAL'
                    ? [1, 2, 3, 4].map((classNumber) => ({
                        label: `${classNumber}-й класс`,
                        value: classNumber,
                      }))
                    : Object.keys(userPlayLevels).map((level) => ({
                        label: userPlayLevels[level],
                        value: Number(level),
                      }))
                }
              />
            </FormItem>
            <FormItem className="mb-0" label="Темы" name="chapters">
              <SelectAntd
                onChange={(value, option) =>
                  setSelectedChapters((prev) =>
                    Array.isArray(option) ? prev : [...prev, option.chapter],
                  )
                }
                optionFilterProp="label"
                options={chaptersFiltered?.map((chapter) => ({
                  chapter: chapter,
                  label:
                    chapter.translations?.ru?.title ||
                    // chapter.translations?.en?.title ||
                    chapter.id,
                  value: chapter.id,
                }))}
                placeholder="Выберите темы"
                showSearch
                value="Выберите темы"
              />
            </FormItem>

            {!!selectedChapters.length && (
              <div className="mt-6">
                <h5>Список выбранных тем</h5>
                <ChapterDraggableList
                  chapters={selectedChapters}
                  deletable
                  setChapters={setSelectedChapters}
                />
              </div>
            )}

            <div className="grid md:grid-cols-2 gap-5 mt-6">
              <Button block onClick={onCancel}>
                Отменить
              </Button>

              <Button
                block
                htmlType="submit"
                loading={guidelineCreate.isLoading || guideChapterAdd.isLoading}
                type="primary"
              >
                <span className="max-w-full overflow-ellipsis overflow-hidden">Создать</span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
