import { FC } from 'react';
import { Button, Tooltip } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Select } from 'formik-antd';
import { FileOutlined } from '@ant-design/icons';

import { api, IVideo, IVideoStepTwoRequest } from '@chess-class/sandbox';
import { notify } from '@chess-class/sandbox/utils';

import { userPlayLevels } from '~mMaterials/exercises/exercises/types/UserPlayLevel';
import { AllowedSteps } from '~mMaterials/videos/videos/utils/checkAllowedSteps';

type StepTwoFormType = {
  changeStep: (step: 0 | 1 | 2) => void;
  onCancel: () => void;
  steps: AllowedSteps;
  video: IVideo;
};

export const StepTwo: FC<StepTwoFormType> = ({ changeStep, onCancel, steps, video }) => {
  const videoTwoUpdate = api.content.videos.useVideoStepTwo();

  const submitDraft = (values: IVideoStepTwoRequest) => {
    videoTwoUpdate.mutateAsync({ ...values, status: 'DRAFT' }).then((data) => {
      if (data.status == 201 || data.status == 200) {
        notify('success', 'Видео сохранено как черновик');
        onCancel();
      }
    });
  };

  return (
    <Formik<IVideoStepTwoRequest>
      enableReinitialize
      initialValues={{
        id: video.id,
        level: video.level,
        status: video.status,
      }}
      onReset={() => {
        changeStep(0);
      }}
      onSubmit={(values) => {
        videoTwoUpdate
          .mutateAsync(values)
          .then((data) => {
            if (data.status == 201 || data.status == 200) {
              notify('success', 'Шаг 2 сохранен');
            }
          })
          .finally(() => {
            changeStep(2);
          });
      }}
      validationSchema={api.content.videos.helpers.videoStepTwoValidation}
    >
      {({ dirty, values }) => (
        <Form className="space-y-4 md:space-y-8" layout="vertical">
          <h1 className="text-2xl">Дополнительные параметры видео</h1>
          <div className="sm:grid sm:grid-cols-2 gap-4">
            <FormItem label="Уровень" name="level">
              <Select
                name="level"
                optionFilterProp="label"
                options={Object.keys(userPlayLevels)
                  // .map(Number)
                  .map((key) => ({
                    label: userPlayLevels[key],
                    value: key,
                  }))}
                placeholder="Уровень"
              />
            </FormItem>
          </div>
          <div className="grid sm:grid-cols-2 gap-5">
            <Button block htmlType="reset">
              Назад
            </Button>
            <div className="flex flex-row gap-3">
              <Button
                block
                htmlType="submit"
                loading={videoTwoUpdate.isLoading}
                onClick={() => !steps['2'].disabled && !dirty && changeStep(2)}
                type="primary"
              >
                <span className="max-w-full overflow-ellipsis overflow-hidden">Следующий шаг</span>
              </Button>
              <Tooltip title="Сохранить как черновик">
                <Button
                  className="px-2 border-dashed border-ant-gray-6 hover:border-ant-blue-5 shadow-none"
                  ghost
                  icon={<FileOutlined />}
                  onClick={() => submitDraft(values)}
                  type="primary"
                />
              </Tooltip>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
