import { Route, Routes } from 'react-router-dom';

import NotFoundPage from '~/components/pages/errors/NotFoundPage';

import { ReviewsPage } from './pages/ReviewsPage';

export const ReviewsRouter = () => {
  return (
    <Routes>
      <Route element={<ReviewsPage />} index />
      <Route element={<NotFoundPage />} path="*" />
    </Routes>
  );
};
