import { FC, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Button, Layout, Menu } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { storageKeys } from '@chess-class/sandbox/consts';

import { GlobalEducationFormatSwitcher } from '~/components/atoms/GlobalEducationFormatSwitcher';
import { MenuCollapsedIcon, MenuIcon } from '~/components/atoms/icons';
import AuthRoute from '~/components/molecules/routes/AuthRoute';
import { LogoutDropdown } from '~/components/organisms/LogoutDropdown';
import { SideNavbar } from '~/components/organisms/SideNavbar';
import { useEducationFormat } from '~/context/EducationFormContext';

const AdminLayout: FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { defaultEducationFormat, setDefaultEducationFormat } = useEducationFormat();

  const handleDefaultEducationFormatChange = (educationFormat: string) => {
    localStorage.setItem(storageKeys.educationFormat, educationFormat);
    setDefaultEducationFormat(educationFormat as 'GENERAL' | 'PRIVATE');
  };

  return (
    <AuthRoute auth>
      <Layout className="h-full w-full" hasSider>
        <SideNavbar collapsed={collapsed} />
        <Layout className="flex flex-col">
          <Layout.Header className="flex items-center justify-between bg-white pl-0 pr-5 h-20 border-b">
            <Button
              className="trigger mx-6 text-black"
              icon={collapsed ? <MenuCollapsedIcon /> : <MenuIcon />}
              onClick={() => setCollapsed(!collapsed)}
              type="link"
            />
            <div className="flex flex-row items-center">
              <GlobalEducationFormatSwitcher
                onChange={(v) => handleDefaultEducationFormatChange(v)}
                value={defaultEducationFormat}
              />
              <Menu
                className="header-menu font-semibold max-w-full justify-center text-center"
                expandIcon={<SearchOutlined />}
                items={[
                  {
                    className: 'flex items-center',
                    key: 'logout',
                    label: <LogoutDropdown />,
                  },
                ]}
                mode="horizontal"
                overflowedIndicatorPopupClassName="header-menu-overflow"
                rootClassName="border-0 py-1"
                selectedKeys={[]}
              />
            </div>
          </Layout.Header>
          <Layout.Content className="max-w-full overflow-auto p-6">
            <Outlet />
          </Layout.Content>
        </Layout>
      </Layout>
    </AuthRoute>
  );
};

export default AdminLayout;
