import { IFilters } from '@chess-class/sandbox';
import { modelKeys, paginationBaseFilters } from '@chess-class/sandbox/consts';

import { InputFilter } from '~/components/molecules/filters/static/InputFilter';

export const videoFilesFilters: IFilters = {
  ...paginationBaseFilters,
  [modelKeys.content.videoFile.title]: {
    input: <InputFilter placeholder="Напишите название" />,
    sort: true,
  },
  [modelKeys.content.videoFile.createdDate]: {
    sort: true,
  },
  sort: { queries: [{ default: '-createdDate' }] },
};
