import { IFilters } from '@chess-class/sandbox';
import { modelKeys, paginationBaseFilters } from '@chess-class/sandbox/consts';

import { DateRangeFilter } from '~/components/molecules/filters/static/DateRangeFilter';
import { InputFilter } from '~/components/molecules/filters/static/InputFilter';

export const staffsFilters: IFilters = {
  ...paginationBaseFilters,

  [modelKeys.user.admin.email]: {
    input: <InputFilter placeholder="Введите почту" />,
    sort: true,
  },
  [modelKeys.user.admin.firstName]: {
    input: <InputFilter placeholder="Введите имя" />,
    sort: true,
  },
  [modelKeys.user.admin.lastActivityAt]: {
    input: <DateRangeFilter placeholderMax="до" placeholderMin="от" />,
    queries: [{ key: 'lastActivityFrom' }, { key: 'lastActivityTo' }],
  },
  [modelKeys.user.admin.status]: {
    sort: true,
  },
  sort: { queries: [{ default: '-createdDate' }] },
};
