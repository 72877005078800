import { FC } from 'react';

import { ILocalization, IVideo } from '@chess-class/sandbox';
import { PlyrPlayer } from '@chess-class/sandbox/components';

import { BiImageIcon, MdVideoSettingsIcon } from '~/components/atoms/icons';
import { TitleAndText } from '~/components/atoms/TitleAndText';
import { FileDownload } from '~/components/molecules/files/FileDownload';

type VideoViewLocalizationType = { localization: ILocalization; video: IVideo };

export const VideoViewLocalization: FC<VideoViewLocalizationType> = ({ localization, video }) => {
  return (
    <div>
      <div className="divide-y">
        <TitleAndText
          text={
            <span className="font-bold text-xl">{video.translations[localization]?.title}</span>
          }
          title="Название"
        />
        <TitleAndText
          text={
            video.type == 'LESSON' && !!video.translations[localization]?.videoFile?.author ? (
              <>
                {video.translations[localization]?.videoFile?.author?.rank && (
                  <span className="font-bold text-ant-red-7 mr-1">
                    {video.translations[localization]?.videoFile?.author?.rank}
                  </span>
                )}
                {video.translations[localization]?.videoFile?.author?.name}
              </>
            ) : (
              '-'
            )
          }
          title="Автор"
        />
      </div>

      <div className="flex justify-center px-2">
        <div className="w-full sm:px-12 md:px-0 md:w-4/5 lg:w-3/5 my-4 md:my-6 overflow-scroll rounded">
          <PlyrPlayer
            className="w-full aspect-video rounded-t-lg overflow-hidden"
            localization={localization as ILocalization}
            video={video}
          />
        </div>
      </div>

      <div className="grid md:flex md:flex-row justify-center px-4 gap-3">
        <FileDownload
          fileName="Видео"
          icon={<MdVideoSettingsIcon />}
          link={video.translations[localization]?.videoFile?.hlsUrl}
        />
        <FileDownload
          fileName="Обложка"
          icon={<BiImageIcon />}
          link={video.translations[localization]?.previewUrl}
        />
      </div>

      {video.type !== 'CARTOON' && (
        <div className="divide-y mt-5">
          <h2 className="text-2xl px-4">Тест</h2>
          <TitleAndText
            className="col-span-full"
            text={video.test?.translations[localization]?.question}
            title="Вопрос"
          />
          <TitleAndText
            className="py-2"
            text={video.test?.translations[localization]?.answerOptions?.[0]}
            title="Ответ 1"
          />

          <TitleAndText
            className="py-2"
            text={video.test?.translations[localization]?.answerOptions?.[1]}
            title="Ответ 2"
          />
          <TitleAndText
            className="py-2"
            text={video.test?.translations[localization]?.answerOptions?.[2]}
            title="Ответ 3"
          />

          <TitleAndText
            className="py-2"
            text={video.test?.translations[localization]?.answerOptions?.[3]}
            title="Ответ 4"
          />
        </div>
      )}
    </div>
  );
};
