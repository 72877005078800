import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgGraduationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg height="24px" viewBox="0 0 384 384" width="24px" {...props}>
    <path
      d="M329.82,185.14c-6.29,3.66-12.35,7.05-18.21,10.75-.88,.56-1.17,2.57-1.18,3.92-.08,17.6-.22,35.2,0,52.79,.21,17.49-7.03,30.29-22.4,38.87-18.83,10.51-37.33,21.6-56.08,32.25-28.79,16.35-57.64,16.18-86.37-.25-18.82-10.77-37.54-21.73-56.4-32.43-14.6-8.28-21.73-20.66-21.64-37.4,.09-17.85-.04-35.69,.08-53.54,.02-2.72-.8-4.22-3.19-5.58-13.21-7.52-26.34-15.2-39.46-22.9-15.01-8.8-15.03-26.35,0-35.1,47.72-27.78,95.46-55.53,143.22-83.23,13.66-7.92,27.57-8.08,41.23-.17,48.01,27.78,95.96,55.66,143.87,83.6,14.31,8.35,14.51,25.38,.7,34.46-1.15,.76-2.2,2.67-2.21,4.05-.12,31.32-.12,62.65,0,93.97,0,1.47,.91,3.3,1.99,4.35,8.05,7.81,8.39,19.97,.61,27.81-7.44,7.5-19.84,7.43-27.24-.17-7.63-7.83-7.15-20.16,.97-27.81,.95-.9,1.87-2.41,1.88-3.64,.1-27.58,.08-55.16,.07-82.74,0-.46-.12-.91-.26-1.85ZM37.75,154.01c2.04,1.24,3.49,2.16,4.97,3.02,44.94,26.11,89.93,52.15,134.79,78.4,7.84,4.58,15.01,4.65,22.82,.09,45.52-26.6,91.14-53.05,136.71-79.56,.93-.54,1.78-1.2,2.91-1.96-.93-.61-1.51-1.04-2.14-1.4-46.23-26.87-92.47-53.73-138.69-80.62-6.9-4.01-13.59-3.92-20.5,.12-25.07,14.66-50.2,29.21-75.31,43.8-21.66,12.59-43.31,25.17-65.56,38.1Zm51.37,55.16c0,15.38,.12,29.98-.05,44.57-.09,8.47,3.41,14.39,10.8,18.57,18.99,10.73,37.85,21.69,56.73,32.6,21.39,12.36,42.86,12.51,64.29,.3,19.38-11.04,38.62-22.35,57.99-33.42,6.73-3.85,9.95-9.49,9.94-17.15-.02-14.1,0-28.2-.02-42.31,0-.91-.2-1.81-.35-3.12-1.7,.97-2.99,1.69-4.26,2.43-24.47,14.21-48.96,28.37-73.38,42.66-14.71,8.6-29.2,8.5-43.89-.13-24.61-14.46-49.33-28.72-74.02-43.06-1.03-.6-2.13-1.1-3.8-1.95Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgGraduationIcon);
export default Memo;
