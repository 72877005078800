import { FC, useState } from 'react';

import { api, FilterRender } from '@chess-class/sandbox';

import { SelectMultipleFilter } from '~/components/molecules/filters/static/SelectMultipleFilter';

interface ChaptersSelectMultipleInterface {
  filterRender?: FilterRender;
}

export const ChaptersNumSelectMultipleFilter: FC<ChaptersSelectMultipleInterface> = ({
  filterRender,
}) => {
  const [chapterNumId, setChapterNumId] = useState('');
  const chapters = api.content.chapters.useChapters({
    numId: Number.parseInt(chapterNumId) || undefined,
  });
  const chaptersData = chapters.data?.data;

  const options =
    chaptersData?.content.map((chapter) => ({
      label: chapter.numId,
      value: chapter?.numId?.toString() || 0,
    })) || [];

  return (
    <SelectMultipleFilter
      filterOption={false}
      filterRender={filterRender}
      loading={chapters.isLoading}
      onSearch={(searchValue) => setChapterNumId(searchValue)}
      options={options}
      placeholder="Выберите номер темы"
    />
  );
};
