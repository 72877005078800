import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import clsx from 'clsx';

import { api } from '@chess-class/sandbox';

import { sidebars } from '~/consts/sidebars';
import { useEducationFormat } from '~/context/EducationFormContext';
import { PageMetaContext } from '~/context/PageMetaContext';
import { checkPermission } from '~/utils/checkPermission';

type SideNavbarType = {
  collapsed: boolean;
};

export const SideNavbar: FC<SideNavbarType> = ({ collapsed }) => {
  const { openMenuKeys, selectedMenuKeys } = useContext(PageMetaContext);

  const [broken, setBroken] = useState<boolean>(false);
  const [openKeys, setOpenKeys] = useState<string[]>();
  const { defaultEducationFormat } = useEducationFormat();
  const me = api.user.adminAuth.useAdminMe();

  const items = useMemo(
    () =>
      sidebars({ educationFormat: defaultEducationFormat })
        .filter((route) => !route.access || checkPermission(route.access, me.data?.accesses))
        .map((route) => {
          const Icon = route.icon;

          return {
            children: route.children?.map((childRoute) => {
              const ChildrenIcon = childRoute.icon;
              return {
                icon: ChildrenIcon ? (
                  <ChildrenIcon className={childRoute.iconClassName} />
                ) : undefined,
                key: `${route.key}-${childRoute.key}`,
                label: (
                  <Link to={`${route.link ? `/${route.link}` : ''}/${childRoute.link}`}>
                    {childRoute.title}
                  </Link>
                ),
              };
            }),
            icon: Icon ? <Icon className={route.iconClassName} /> : undefined,
            key: route.key,
            label: route.children ? route.title : <Link to={`/${route.link}`}>{route.title}</Link>,
          };
        }),
    [sidebars, me.data?.roles],
  );

  useEffect(() => {
    setOpenKeys(openMenuKeys);
  }, [openMenuKeys]);

  return (
    <Layout.Sider
      breakpoint="sm"
      className="min-h-full"
      collapsed={broken || collapsed}
      collapsedWidth={broken && collapsed ? 0 : broken ? 50 : 70}
      collapsible
      onBreakpoint={setBroken}
      trigger={null}
      width={220}
    >
      <div className="flex items-center justify-center gap-4 p-4 mx-auto h-20 mb-2 overflow-hidden border-b border-b-gray-600">
        <div className="p-1 sm:p-2 bg-[#333] rounded-lg">
          <img
            alt="Chess Class logo"
            className="w-7 sm:w-8 h-7 sm:h-8 object-contain"
            src="/logo-normal.png"
          />
        </div>

        <span
          className={clsx(
            'text-white font-bold text-lg whitespace-nowrap truncate tracking-wide',
            broken || collapsed ? 'hidden' : '',
          )}
        >
          Chess <span className="text-[rgb(93,112,255)]">Class</span>
        </span>
      </div>
      <Menu
        className="font-medium"
        items={items}
        mode="inline"
        onOpenChange={setOpenKeys}
        openKeys={collapsed || broken ? [] : !openKeys?.length ? undefined : openKeys}
        selectedKeys={selectedMenuKeys}
        theme="dark"
      />
    </Layout.Sider>
  );
};
