import { FC } from 'react';
import { Button, Tooltip } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Radio } from 'formik-antd';
import { FileOutlined } from '@ant-design/icons';

import {
  api,
  filteredLocalizations,
  IEnumLocalizationGenitive,
  ILocalization,
  IVideo,
  IVideoStepThreeRequest,
} from '@chess-class/sandbox';
import { notify } from '@chess-class/sandbox/utils';

import { useEducationFormat } from '~/context/EducationFormContext';

import QuestionAnswersForm from '~mMaterials/exercises/exercises/components/molecules/QuestionAnswersForm';

type StepThreeType = {
  changeStep: (value) => void;
  onCancel: () => void;
  video: IVideo;
};

export const StepThree: FC<StepThreeType> = ({ changeStep, onCancel, video }) => {
  const videoThreeUpdate = api.content.videos.useVideoStepThree();
  const { defaultEducationFormat } = useEducationFormat();

  const submitDraft = (values: IVideoStepThreeRequest) => {
    videoThreeUpdate.mutateAsync({ ...values, status: 'DRAFT' }).then((data) => {
      if (data.status == 201 || data.status == 200) {
        notify('success', 'Видео сохранено как черновик');
        onCancel();
      }
    });
  };

  return (
    <Formik<IVideoStepThreeRequest>
      enableReinitialize
      initialValues={{
        id: video.id,
        status: video.status,
        test: video.test || api.content.videos.helpers.videoStepThreeInitial.test,
      }}
      onReset={() => {
        changeStep(1);
      }}
      onSubmit={(values) => {
        videoThreeUpdate.mutateAsync({ ...values, status: 'ACTIVE' }).then((data) => {
          if (data.status == 201 || data.status == 200) {
            notify('success', 'Шаг 3 сохранен');
            onCancel();
          }
        });
      }}
      // validationSchema={api.content.videos.helpers.videoStepThreeValidation}
    >
      {({ values }) => (
        <Form className="space-y-4 md:space-y-8" layout="vertical">
          <h1 className="text-2xl">Тест</h1>
          <div className="grid grid-cols-2 gap-4">
            {filteredLocalizations({
              defaultEducationFormat: defaultEducationFormat ?? 'GENERAL',
              isDev:
                process.env.REACT_APP_API_URL.includes('api.dev') ||
                process.env.NODE_ENV == 'development',
            }).map((locale) => (
              <QuestionAnswersForm
                isVideo
                key={`question-form-${locale}`}
                localization={locale as ILocalization}
                nameStart={`test.translations.${locale}`}
                // test={values.test?.translations?.[locale]}
                text={`Вопрос на ${IEnumLocalizationGenitive[locale]} языке`}
              />
            ))}
          </div>

          <FormItem label="Правильный вариант" name="test.correctOption">
            <Radio.Group
              name="test.correctOption"
              options={[1, 2, 3, 4].map((correct) => ({
                label: String.fromCharCode(64 + correct),
                value: correct,
              }))}
            />
          </FormItem>

          <div className="grid sm:grid-cols-2 gap-5">
            <Button block htmlType="reset">
              Назад
            </Button>
            <div className="flex flex-row gap-3">
              <Button block htmlType="submit" loading={videoThreeUpdate.isLoading} type="primary">
                <span className="max-w-full overflow-ellipsis overflow-hidden">Запустить</span>
              </Button>
              <Tooltip title="Сохранить как черновик">
                <Button
                  className="px-2 border-dashed border-ant-gray-6 hover:border-ant-blue-5 shadow-none"
                  ghost
                  icon={<FileOutlined />}
                  onClick={() => submitDraft(values)}
                  type="primary"
                />
              </Tooltip>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
