import { Button, Collapse, Switch as AntSwitch } from 'antd';
import { useFormikContext } from 'formik';
import { Form, FormItem, Input, Select, Switch } from 'formik-antd';
import { DeleteOutlined, MenuOutlined } from '@ant-design/icons';

import {
  api,
  filteredLocalizations,
  IChapterCreateRequest,
  IChapterUpdateRequest,
  IEnumLocalizationGenitive,
  ILocalization,
} from '@chess-class/sandbox';
import { modelKeys } from '@chess-class/sandbox/consts';

import { CountryImageText } from '~/components/atoms/CountryImageText';
import { FileUpload } from '~/components/molecules/files/FileUpload';
import { useEducationFormat } from '~/context/EducationFormContext';

import { ChapterFormTranslationCard } from '~mMaterials/chapters/components/molecules/ChapterFormTranslationCard';
import { userPlayLevels } from '~mMaterials/exercises/exercises/types/UserPlayLevel';

export default function ChapterForm({
  chapterNumExistsAlready,
  isEdit,
  isLoading,
  onCancel,
}: {
  chapterNumExistsAlready?: { numId?: number; title?: string };
  isEdit?: boolean;
  isLoading?: boolean;
  onCancel: () => void;
}) {
  const videos = api.content.videos.useVideos({ size: 9999, type: 'LESSON' });
  const cartoons = api.content.videos.useVideos({ size: 9999, type: 'CARTOON' });
  const { defaultEducationFormat } = useEducationFormat();
  const { setFieldValue, values } = useFormikContext<
    IChapterCreateRequest | IChapterUpdateRequest
  >();

  return (
    <Form className="flex flex-col gap-6" layout="vertical">
      <div className="grid grid-cols-2 gap-4">
        {filteredLocalizations({
          defaultEducationFormat: defaultEducationFormat ?? 'GENERAL',
          isDev:
            process.env.REACT_APP_API_URL.includes('api.dev') ||
            process.env.NODE_ENV == 'development',
        })?.map((locale) => (
          <ChapterFormTranslationCard
            key={`chapter-form-${locale}`}
            nameStart={`translations.${locale}`}
            text={`Тема на ${IEnumLocalizationGenitive[locale]} языке`}
            translation={locale as ILocalization}
          />
        ))}
      </div>

      <div className="grid grid-cols-2 gap-4 gap-x-6">
        <FormItem label="Уровень" name="level">
          <Select
            name="level"
            optionFilterProp="label"
            options={Object.keys(userPlayLevels)
              // .map(Number)
              .map((level) => ({ label: userPlayLevels[level], value: level }))}
            showSearch={false}
          />
        </FormItem>

        <FormItem label="Видеоуроки" name="lessonIds">
          <Select
            allowClear
            className="gap-y-2"
            name="lessonIds"
            onChange={(value) => setFieldValue('lessonIds', [value])}
            optionFilterProp="label"
            options={
              videos.data?.data.content
                ? videos.data?.data.content.map((video) => ({
                    label: video.translations ? video.translations.ru?.title : video.id,
                    value: video.id,
                  }))
                : []
            }
            showSearch
          />
        </FormItem>

        <FormItem label="Мультфильмы" name="cartoonIds">
          <Select
            allowClear
            className="gap-y-2"
            mode="multiple"
            name="cartoonIds"
            optionFilterProp="label"
            options={
              cartoons.data?.data.content
                ? cartoons.data?.data.content.map((cartoon) => ({
                    label: cartoon.translations ? cartoon.translations.ru?.title : cartoon.id,
                    value: cartoon.id,
                  }))
                : []
            }
            showSearch
          />
        </FormItem>

        <FormItem label="Номер темы" name="numId">
          <Input maxLength={20} name="numId" placeholder="Номер темы" suffix type="number" />
        </FormItem>
      </div>

      <div className="flex flex-col gap-6 pb-4">
        <span className="text-lg">
          Дополнительный материал <span className="text-gray-400">(необязательно)</span>
        </span>

        {!!values.additionalMaterialImages?.length && (
          <Collapse
            className="border-0"
            expandIconPosition="end"
            ghost
            items={values.additionalMaterialImages?.map((value, index) => {
              const imageKey = `${modelKeys.content.chapter.additionalMaterialImages}.${index}`;

              return {
                children: (
                  <div className="p-3" key={`material-image-${index}`}>
                    <FormItem
                      className="upload-form-item text-center custom-picture"
                      name={imageKey}
                    >
                      <FileUpload
                        accept=".jpeg,.png,.jpg"
                        defaultFileList={
                          value
                            ? [{ name: value, status: 'done', uid: value, url: value }]
                            : undefined
                        }
                        folderName="additionalMaterials"
                        key={value}
                        label="Дополнительный материал"
                        maxCount={1}
                        onChange={(info, linkInfo) => {
                          if (!info.file.status || info.file.status == 'removed')
                            setFieldValue(imageKey, '');
                          if (info.file.status == 'done')
                            setFieldValue(imageKey, linkInfo.downloadUrl);
                        }}
                      />
                    </FormItem>

                    <div className="grid grid-cols-2 gap-8">
                      {filteredLocalizations({
                        defaultEducationFormat: defaultEducationFormat ?? 'GENERAL',
                        isDev:
                          process.env.REACT_APP_API_URL.includes('api.dev') ||
                          process.env.NODE_ENV == 'development',
                      })?.map((locale) => (
                        <div className="flex flex-col gap-4" key={`additional-locale-${locale}`}>
                          <FormItem
                            className="col-span-3"
                            label={
                              <CountryImageText
                                src={locale}
                                text={`Название на ${IEnumLocalizationGenitive[locale]}`}
                                width={14}
                              />
                            }
                            name={`translations.${locale}.additionalMaterialTitles.${index}`}
                          >
                            <Input
                              name={`translations.${locale}.additionalMaterialTitles.${index}`}
                              suffix
                            />
                          </FormItem>

                          <FormItem
                            className="col-span-3"
                            label={
                              <CountryImageText
                                src={locale}
                                text={`Описание на ${IEnumLocalizationGenitive[locale]}`}
                                width={14}
                              />
                            }
                            name={`translations.${locale}.additionalMaterialDescriptions.${index}`}
                          >
                            <Input.TextArea
                              maxLength={1000}
                              name={`translations.${locale}.additionalMaterialDescriptions.${index}`}
                            />
                          </FormItem>
                        </div>
                      ))}
                    </div>
                  </div>
                ),
                headerClass: 'flex items-center gap-1',
                key: `material-image-${index}`,
                label: (
                  <div className="flex items-center gap-2">
                    <MenuOutlined className="text-ant-blue-4" />
                    {index + 1}
                    <Button
                      className="ml-auto"
                      danger
                      ghost
                      icon={<DeleteOutlined />}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        const images = [...(values.additionalMaterialImages || [])];
                        images.splice(index, 1);
                        setFieldValue(modelKeys.content.chapter.additionalMaterialImages, images);

                        filteredLocalizations({
                          defaultEducationFormat: defaultEducationFormat ?? 'GENERAL',
                          isDev:
                            process.env.REACT_APP_API_URL.includes('api.dev') ||
                            process.env.NODE_ENV == 'development',
                        })?.forEach((locale) => {
                          const titles = [
                            ...(values.translations?.[locale]?.additionalMaterialTitles || []),
                          ];
                          titles.splice(index, 1);
                          setFieldValue(`translations.${locale}.additionalMaterialTitles`, titles);

                          const descriptions = [
                            ...(values.translations?.[locale]?.additionalMaterialDescriptions ||
                              []),
                          ];
                          titles.splice(index, 1);
                          setFieldValue(
                            `translations.${locale}.additionalMaterialDescriptions`,
                            descriptions,
                          );
                        });
                      }}
                    />
                  </div>
                ),
              };
            })}
          />
        )}

        <div>
          <Button
            ghost
            onClick={() => {
              setFieldValue(modelKeys.content.chapter.additionalMaterialImages, [
                ...(values.additionalMaterialImages || []),
                '',
              ]);
              filteredLocalizations({
                defaultEducationFormat: defaultEducationFormat ?? 'GENERAL',
                isDev:
                  process.env.REACT_APP_API_URL.includes('api.dev') ||
                  process.env.NODE_ENV == 'development',
              })?.forEach((locale) => {
                setFieldValue(`translations.${locale}.additionalMaterialTitles`, [
                  ...(values.translations?.[locale]?.additionalMaterialTitles || []),
                  '',
                ]);
                setFieldValue(`translations.${locale}.additionalMaterialDescriptions`, [
                  ...(values.translations?.[locale]?.additionalMaterialDescriptions || []),
                  '',
                ]);
              });
            }}
            type="primary"
          >
            + Добавить доп. материал
          </Button>
        </div>
      </div>

      {!!chapterNumExistsAlready && !values.forceCreate && (
        <p>
          Тема под номером {chapterNumExistsAlready.numId} уже существует, пожалуйста подтвердите
          сдвиг номера темы {chapterNumExistsAlready.title} и всех номеров последующих тем вперед на
          +1
        </p>
      )}

      <div className="grid md:grid-cols-2 place-items-center text-center">
        <FormItem
          label={<span className="text-center">Подтвердить сдвиг темы </span>}
          name="forceCreate"
        >
          <Switch name="forceCreate" />
        </FormItem>
        <Form.Item
          label={<span className="text-center">Активировать немедленно</span>}
          name="status"
        >
          <Input hidden name="status" suffix />

          <AntSwitch
            checked={values.status == 'ACTIVE'}
            onChange={(checked) => setFieldValue('status', checked ? 'ACTIVE' : 'DRAFT')}
          />
        </Form.Item>
      </div>

      <div className="grid md:grid-cols-2 gap-5">
        <Button block onClick={onCancel}>
          Отменить
        </Button>

        <Button block htmlType="submit" loading={isLoading} type="primary">
          <span className="max-w-full overflow-ellipsis overflow-hidden">
            {values['status'] == 'ACTIVE'
              ? `${isEdit ? 'Изменить' : 'Создать'} и активировать`
              : 'Сохранить как черновик'}
          </span>
        </Button>
      </div>
    </Form>
  );
}
