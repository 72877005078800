import { FC } from 'react';

import { ChessAnalyseEditor } from '@chess-class/sandbox/components';

export const StudioEditorForm: FC = () => {
  return (
    <form className="flex flex-wrap gap-8 justify-center">
      <ChessAnalyseEditor
        isOpen={true}
        onClose={() => {
          //
        }}
      />
    </form>
  );
};
