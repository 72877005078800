import { FC } from 'react';
import { FormItem, Input, Select } from 'formik-antd';

import { api, ILocalization, IVideo } from '@chess-class/sandbox';

import { CountryImageText } from '~/components/atoms/CountryImageText';
import { FileUpload } from '~/components/molecules/files/FileUpload';

type VideoLocaleFormCardType = {
  localization: ILocalization;
  previewKey?: string;
  previewUrl?: string;
  setFieldValue: (
    field: string,
    value: string | number | undefined,
    shouldValidate?: boolean | undefined,
  ) => void;
  type?: IVideo['type'];
};

export const VideoLocaleFormCard: FC<VideoLocaleFormCardType> = ({
  localization,
  previewKey,
  previewUrl,
  setFieldValue,
  type,
}) => {
  const videoFiles = api.content.videosFiles.useVideoFiles({ isUploading: false, size: 9999 });

  return (
    <div className="p-5 rounded-2xl border space-y-6">
      <h1 className="text-lg">
        <CountryImageText
          src={localization}
          text={type == 'CARTOON' ? 'Мультфильм' : 'Видеоурок'}
          width={20}
        />
      </h1>

      <FormItem label="Название" name={`translations.${localization}.title`}>
        <Input
          maxLength={256}
          name={`translations.${localization}.title`}
          placeholder={`Название ${type == 'CARTOON' ? 'мультфильма' : 'видеоурока'}`}
          showCount
          suffix
        />
      </FormItem>

      <div className="sm:grid gap-1 text-center">
        <FormItem
          className="mb-1"
          label="Видеофайл"
          name={`translations.${localization}.videoFileId`}
        >
          <Select
            name={`translations.${localization}.videoFileId`}
            optionFilterProp="label"
            options={videoFiles.data?.data.content?.map((file) => ({
              label: file.title,
              value: file.uuid,
            }))}
            placeholder="Видеофайл"
            showSearch
          />
        </FormItem>
      </div>

      <FormItem
        className="upload-form-item text-center custom-picture"
        name={`translations.${localization}.previewUrl`}
      >
        <FileUpload
          accept=".png,.jpeg,.jpg"
          defaultFileList={
            previewUrl
              ? [
                  {
                    name: '',
                    status: 'done',
                    uid: `Обложка ${localization}`,
                    url: previewUrl,
                  },
                ]
              : undefined
          }
          folderName="previews"
          key={previewKey}
          label="Обложка видео"
          listType="picture-card"
          maxCount={1}
          onChange={(info, linkInfo) => {
            if (!info.file.status || info.file.status == 'removed')
              setFieldValue(`translations.${localization}.previewUrl`, '');
            if (info.file.status == 'done')
              setFieldValue(`translations.${localization}.previewUrl`, linkInfo.downloadUrl);
          }}
        />
      </FormItem>
    </div>
  );
};
