import { Button } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input } from 'formik-antd';

export const StudioPgnForm = () => {
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{}}
        onSubmit={() => {
          // studioCreate.mutateAsync(values).then(() => navigate('/studios'));
        }}
        // validationSchema={studioCreateValidation}
      >
        <Form className="space-y-8" layout="vertical">
          <FormItem label="Название" name="name">
            <Input name="name" placeholder="Студия ABC" suffix type="text" />
          </FormItem>

          <FormItem label="PGN" name="pgn">
            <Input.TextArea
              name="pgn"
              placeholder="1. a4 a5 2. e4 e5 3. Ke2 Ke7"
              prefix=""
              rows={6}
            />
          </FormItem>

          <div className="flex justify-end gap-2">
            <Button>Отменить</Button>

            <Button htmlType="submit" type="primary">
              Добавить
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
