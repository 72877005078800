import { IPageActions } from '@chess-class/sandbox';

enum IEnumGroupsPageActions {
  'view',
  'create',
}

export type IGroupPageActions = {
  action: keyof typeof IEnumGroupsPageActions;
  actionId: string;
};

export const groupPageActions: IPageActions<IGroupPageActions> = {
  action: {
    allowed: (value) => Object.keys(IEnumGroupsPageActions).includes(value),
    default: '',
  },
  actionId: { default: '' },
};
