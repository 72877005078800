import { IEnumSettingName, IEnumSettingNameKeys, IPageActions } from '@chess-class/sandbox';

enum AppSettingsActionEnum {
  'edit',
}

export type AppSettingsPageActions = {
  action: keyof typeof AppSettingsActionEnum;
  actionName?: IEnumSettingNameKeys;
};

export const appSettingsPageActions: IPageActions<AppSettingsPageActions> = {
  action: {
    allowed: (value) => Object.keys(AppSettingsActionEnum).includes(value),
    default: '',
  },
  actionName: { allowed: (value) => Object.keys(IEnumSettingName).includes(value) },
};
