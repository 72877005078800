import { IEnumCoachStatus, IFilters } from '@chess-class/sandbox';
import { modelKeys, paginationBaseFilters } from '@chess-class/sandbox/consts';

import { SchoolsSelectMultipleFilter } from '~/components/molecules/filters/dynamic/SchoolsSelectMultipleFilter';
import { CheckboxFilter } from '~/components/molecules/filters/static/CheckboxFilter';
import { InputFilter } from '~/components/molecules/filters/static/InputFilter';
import { RadioFilter } from '~/components/molecules/filters/static/RadioFilter';

export const coachFilters: IFilters = {
  ...paginationBaseFilters,
  [modelKeys.user.coachToSchool.schoolId]: {
    input: <SchoolsSelectMultipleFilter />,
    queries: [{ key: 'schoolIds' }],
    sort: true,
  },
  [modelKeys.user.coachToSchool.firstName]: {
    input: <InputFilter placeholder="Введите имя" />,
    queries: [{ key: 'fullName' }],
  },
  // [modelKeys.user.coachToSchool.email]: {
  //   input: <InputFilter placeholder="Введите почту" />,
  // },
  [modelKeys.user.coachToSchool.isSuperCoach]: {
    input: (
      <RadioFilter
        options={[
          { label: 'Да', value: true },
          { label: 'Нет', value: false },
        ]}
      />
    ),
    queries: [{ key: 'superCoach' }],
  },
  [modelKeys.user.coachToSchool.status]: {
    input: (
      <CheckboxFilter
        options={Object.entries(IEnumCoachStatus).map(([value, label]) => ({
          label,
          value,
        }))}
      />
    ),
    sort: true,
  },
  sort: { queries: [{ default: '-createdDate' }] },
};
