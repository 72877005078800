import { FC } from 'react';
import { Button, Modal } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, Select } from 'formik-antd';
import { SearchOutlined } from '@ant-design/icons';

import { api, IExerciseGroupEditRequest } from '@chess-class/sandbox';
import { notify } from '@chess-class/sandbox/utils';

import BaseSkeleton from '~/components/atoms/BaseSkeleton';
import { FaChessKnightIcon } from '~/components/atoms/icons';
import { NotFoundResult } from '~/components/atoms/NotFoundResult';

export const ExerciseGroupEditModal: FC<{
  id?: string;
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}> = ({ id = '', isOpen, onCancel, onSubmit }) => {
  const videos = api.content.videos.useVideos({ isCartoon: false });

  const exerciseGroupQuery = api.content.exercisesGroups.useExerciseGroup({ id });
  const exerciseGroup = exerciseGroupQuery.data?.data;

  const exerciseGroupEdit = api.content.exercisesGroups.useExerciseGroupEdit();

  return (
    <Modal
      className="px-4 md:px-0 md:w-3/4 lg:w-2/3"
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={
        <div className="flex flex-row gap-4 truncate">
          <h1 className="text-xl m-0 flex items-center gap-1">
            <FaChessKnightIcon /> Изменить коллекцию задач #{id}
          </h1>
          <Button icon={<SearchOutlined />} onClick={onSubmit} shape="circle" />
        </div>
      }
    >
      {exerciseGroupQuery.isLoading ? (
        <BaseSkeleton />
      ) : !exerciseGroup ? (
        <NotFoundResult title="Коллекция задач не найдена" />
      ) : (
        <Formik<IExerciseGroupEditRequest>
          initialValues={{ groupName: exerciseGroup.name, id }}
          onSubmit={(values, { resetForm }) => {
            exerciseGroupEdit.mutateAsync(values).then((data) => {
              if (data.status == 201 || data.status == 200) {
                notify('success', 'Коллекция задач изменена');
                onSubmit();
                resetForm();
              }
            });
          }}
          validationSchema={api.content.exercisesGroups.helpers.exerciseGroupEditValidation}
        >
          <Form className="space-y-8" layout="vertical">
            <div>
              <p className="text-ant-gray-7">
                Все поля опциональны.
                <span className="ml-1 font-semibold">
                  Выбранные для изменения поля будут переписаны у всех упражнений в данной коллекции
                </span>
              </p>
            </div>

            <FormItem label="Название коллекции задач" name="name">
              <Input maxLength={150} name="name" placeholder="Название" suffix />
            </FormItem>

            {/* <FormItem label="Уровень" name={modelKeys.content.exercise.playLevels}>
                <Select
                  mode="multiple"
                  name={modelKeys.content.exercise.playLevels}
                  options={Object.keys(userPlayLevels)
                    .map(Number)
                    .map((playLevel) => ({
                      disabled:
                        !!values.playLevels?.length &&
                        !values.playLevels?.includes(playLevel as IUserPlayLevelNumber),
                      label: userPlayLevels[playLevel],
                      value: playLevel,
                    }))}
                  placeholder="Уровень"
                  showSearch={false}
                />
              </FormItem> */}

            <FormItem label="Видеоурок" name="videoId">
              <Select
                allowClear
                className="gap-y-2"
                name="videoId"
                optionFilterProp="label"
                options={
                  videos.data?.data.content.map((video) => ({
                    label: video.translations.ru?.title,
                    value: video.id,
                  })) || []
                }
                placeholder="Видео"
                showSearch
              />
            </FormItem>
            {/*
              <FormItem label="Стадия игры" name={modelKeys.content.exercise.gamePhase}>
                <Select
                  allowClear
                  name={modelKeys.content.exercise.gamePhase}
                  options={Object.entries(ExerciseGamePhaseEnum).map(([value, label]) => ({
                    label,
                    value,
                  }))}
                  placeholder="Стадия игры"
                />
              </FormItem>

              <FormItem
                label={<span className="text-center">Капитализация преимущества</span>}
                name={modelKeys.content.exercise.isCapitalization}
              >
                <Select
                  allowClear
                  name={modelKeys.content.exercise.isCapitalization}
                  options={[
                    { label: 'Да', value: 'true' },
                    { label: 'Нет', value: 'false' },
                  ]}
                  placeholder="Капитализация преимущества"
                />
              </FormItem>

              <FormItem
                label={<span className="text-center">Тактика</span>}
                name={modelKeys.content.exercise.isTactic}
              >
                <Select
                  allowClear
                  name={modelKeys.content.exercise.isTactic}
                  options={[
                    { label: 'Да', value: 'true' },
                    { label: 'Нет', value: 'false' },
                  ]}
                  placeholder="Тактика"
                />
              </FormItem>

              <FormItem
                label={<span className="text-center">Пешечная структура</span>}
                name={modelKeys.content.exercise.isPawnStructure}
              >
                <Select
                  allowClear
                  name={modelKeys.content.exercise.isPawnStructure}
                  options={[
                    { label: 'Да', value: 'true' },
                    { label: 'Нет', value: 'false' },
                  ]}
                  placeholder="Пешечная структура"
                />
              </FormItem> */}
            <FormItem label={<span className="text-center">Проверено</span>} name="checked">
              <Select
                allowClear
                name="checked"
                options={[
                  { label: 'Да', value: 'true' },
                  { label: 'Нет', value: 'false' },
                ]}
                placeholder="Проверено"
              />
            </FormItem>

            <div className="grid md:grid-cols-2 gap-5">
              <Button block onClick={onCancel}>
                Отменить
              </Button>

              <Button block htmlType="submit" loading={exerciseGroupEdit.isLoading} type="primary">
                <span className="max-w-full overflow-ellipsis overflow-hidden">Изменить</span>
              </Button>
            </div>
          </Form>
        </Formik>
      )}
    </Modal>
  );
};
