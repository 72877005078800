import { FC } from 'react';
import { Modal, Tag } from 'antd';

import { api } from '@chess-class/sandbox';

import BaseSkeleton from '~/components/atoms/BaseSkeleton';
import { NotFoundResult } from '~/components/atoms/NotFoundResult';
import { useEducationFormat } from '~/context/EducationFormContext';

import { ChapterEditFormik } from '~mMaterials/chapters/components/molecules/ChapterEditFormik';

export const ChapterEditModal: FC<{
  id: string;
  isOpen: boolean;
  onCancel: () => void;
}> = ({ id, isOpen, onCancel }) => {
  const chapterQuery = api.content.chapters.useChapter({ id });
  const chapter = chapterQuery.data?.data;
  const { defaultEducationFormat } = useEducationFormat();

  return (
    <Modal
      className="px-4 md:px-0 md:w-4/5 lg:w-3/4 top-4"
      destroyOnClose
      footer={null}
      onCancel={() => {
        onCancel();
      }}
      open={isOpen}
      title={
        <h1 className="text-xl space-x-1 mb-8">
          <span>Редактировать тему</span>
          <Tag color="processing">
            {defaultEducationFormat == 'GENERAL' ? 'Гос. школа' : 'Частная школа'}
          </Tag>
        </h1>
      }
    >
      {chapterQuery.isFetching ? (
        <BaseSkeleton />
      ) : !chapter ? (
        <NotFoundResult title="Тема не найдена" />
      ) : (
        <ChapterEditFormik chapter={chapter} onCancel={onCancel} />
      )}
    </Modal>
  );
};
