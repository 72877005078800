import { FC } from 'react';
import { Image, Result } from 'antd';

import { PageMeta } from '~/context/PageMetaContext';

import GroupsTabs from '~mGroups/GroupsTabs';

export const StudentsPage: FC = () => {
  return (
    <div className="flex flex-col gap-4">
      <GroupsTabs active="students" />
      <PageMeta selectedMenuKeys={['classes']} title="Студенты" />

      <Result
        icon={
          <Image
            className="object-cover h-32 sm:h-48 md:h-64 lg:h-80"
            preview={false}
            src={'/images/not-found.png'}
          />
        }
        subTitle="Страница в находится в разработке"
        title="404"
      />
    </div>
  );
};
