import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input } from 'formik-antd';

export const StudioFenForm = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={
          {
            //
          }
        }
        onSubmit={() => {
          // studioCreate.mutateAsync(values).then(() => navigate('/studios'));
        }}
        // validationSchema={studioCreateValidation}
      >
        <Form className="space-y-8" layout="vertical">
          <FormItem label="Название" name="name">
            <Input name="name" placeholder="Студия ABC" suffix type="text" />
          </FormItem>

          <FormItem label="FEN" name="fen">
            <Input
              name="fen"
              placeholder="rnbqkbnr/pppppppp/8/8/8/8/1PPPPPPP/RNBQKBNR b KQkq - 0 1"
              suffix
              type="text"
            />
          </FormItem>

          <div className="flex justify-end gap-2">
            <Button onClick={() => navigate('/materials/studios')}>Отменить</Button>

            <Button htmlType="submit" type="primary">
              Добавить
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
