import { Route, Routes } from 'react-router-dom';

import IndexRoute from '~/components/molecules/routes/IndexRoute';
import NotFoundPage from '~/components/pages/errors/NotFoundPage';
import AdminLayout from '~/layouts/AdminLayout';
import AuthLayout from '~/layouts/AuthLayout';

import { ReviewsRouter } from './modules/reviews/ReviewsRouter';

import AuthRouter from '~mAuth/AuthRouter';
import GroupsRouter from '~mGroups/GroupsRouter';
import { MaterialRouter } from '~mMaterials/MaterialRouter';
import { SchoolsRouter } from '~mSchools/SchoolsRouter';
import { SettingsRouter } from '~mSettings/SettingsRouter';
import { StaffRouter } from '~mStaff/StaffRouter';

const App = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route element={<AuthRouter />} path="auth/*" />
      </Route>

      <Route element={<AdminLayout />} path="*">
        <Route element={<IndexRoute />} index />

        <Route element={<MaterialRouter />} path="materials/*" />
        <Route element={<SchoolsRouter />} path="schools/*" />
        <Route element={<GroupsRouter />} path="classes/*" />
        <Route element={<StaffRouter />} path="staff/*" />
        <Route element={<SettingsRouter />} path="settings/*" />
        <Route element={<ReviewsRouter />} path="reviews/*" />

        <Route element={<NotFoundPage />} path="*" />
      </Route>
    </Routes>
  );
};

export default App;
