import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';

const AuthLayout: FC = () => {
  return (
    <Layout className="min-h-full flex items-center justify-center py-6 md:py-12 px-4 sm:px-6 lg:px-8">
      <Content className="bg-transparent max-w-lg w-full items-center flex">
        <div className="container">
          <div className="py-6 sm:py-9 px-7 sm:px-14 bg-white rounded-2xl">
            <Outlet />
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default AuthLayout;
