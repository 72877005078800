import { FC, useState } from 'react';
import { Button, DatePicker } from 'antd';
import localeRu from 'antd/lib/date-picker/locale/ru_RU';
import dayjs, { Dayjs } from 'dayjs';
import { SearchOutlined } from '@ant-design/icons';

import { FilterRender } from '@chess-class/sandbox';

import 'moment/locale/ru';

interface DateRangeFilterInterface {
  filterRender?: FilterRender;
  placeholderMax?: string;
  placeholderMin?: string;
}

export const DateRangeFilter: FC<DateRangeFilterInterface> = ({
  filterRender,
  placeholderMax,
  placeholderMin,
}) => {
  if (!filterRender) {
    return null;
  }

  const { dataIndex, filtered, onFilter, queries } = filterRender;

  const keyMin = queries?.[0]?.key || dataIndex;
  const keyMax = queries?.[1]?.key || dataIndex;

  const [min, setMin] = useState<Dayjs | null>(
    filtered[keyMin] ? dayjs(filtered[keyMin], 'YYYY-MM-DD HH:mm:ss') : null,
  );
  const [max, setMax] = useState<Dayjs | null>(
    filtered[keyMax] ? dayjs(filtered[keyMax], 'YYYY-MM-DD HH:mm:ss') : null,
  );

  const onDateRangeSubmit = (clear = false) =>
    onFilter({
      [keyMax]: clear ? undefined : max?.endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      [keyMin]: clear ? undefined : min?.startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    });

  return (
    <div className="p-2 space-y-3 bg-white shadow-filter max-w-tiny">
      <DatePicker.RangePicker
        format="YYYY-MM-DD"
        locale={localeRu}
        onCalendarChange={(val) => {
          if (val) {
            setMin(val[0]);
            setMax(val[1]);
          }
        }}
        placeholder={[placeholderMin || '', placeholderMax || '']}
        value={[min, max]}
      />

      <div className="grid gap-1 grid-cols-2 rounded-none">
        <Button
          className="w-full"
          disabled={(!min && !max) || (filtered[keyMin] == min && filtered[keyMax] == max)}
          icon={<SearchOutlined />}
          onClick={() => onDateRangeSubmit()}
          size="small"
          type="primary"
        >
          Поиск
        </Button>
        <Button className="w-full" onClick={() => onDateRangeSubmit(true)} size="small">
          Очистить
        </Button>
      </div>
    </div>
  );
};
