import { type FC, useMemo } from 'react';
import { Modal } from 'antd';
import { Formik } from 'formik';

import { api } from '@chess-class/sandbox';
import { notify, phoneMaskSanitizer } from '@chess-class/sandbox/utils';

import {
  ISchoolWithCoachesCreateRequest,
  schoolWithCoachesCreateValidation,
  shortCoachInitial,
} from '../../consts/schoolAndCoachesValidation';
import { SchoolForm } from './SchoolForm';

type SchoolEditModalProps = {
  id?: string;
  isOpen: boolean;
  onCancel: () => void;
};

export const SchoolEditModal: FC<SchoolEditModalProps> = ({ id = '', isOpen, onCancel }) => {
  const schoolQuery = api.schools.schools.useSchool({ id });
  const school = schoolQuery.data?.data;

  const coachListQuery = api.user.coachToSchool.useCoachesToSchool({
    enabled: !!id,
    schoolIds: id ? [id] : [],
  });
  const coachList = coachListQuery.data?.data.content;

  const schoolUpdate = api.schools.schools.useSchoolUpdate();
  const coachCreate = api.user.coachAuth.useCoachInvite();
  const coachEdit = api.user.coachToSchool.useCoachProfileEdit();

  const schoolInitialValues = useMemo<ISchoolWithCoachesCreateRequest>(() => {
    const location = school?.location;
    const district = location?.isLocality
      ? location.parent
      : location?.isDistrict
        ? location
        : undefined;

    return {
      ...school,
      additional: {
        cityId: location?.isLocality ? location.id : school?.locationText ? 0 : undefined,
        coaches: coachList?.length ? coachList : [shortCoachInitial],
        districtId: district?.id,
        regionId: district?.parent?.id,
      },
      address: school?.address || '',
      amountOfStudents: school?.amountOfStudents || 0,
      email: school?.email || '',
      guidelineIds: school?.guidelines.map(({ id }) => id),
      locationText: school?.locationText || '',
      name: school?.name || '',
      status: school?.status || 'DRAFT',
    };
  }, [school, coachList]);

  return (
    <Modal
      className="top-4"
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      width={900}
    >
      <Formik<ISchoolWithCoachesCreateRequest>
        enableReinitialize
        initialValues={schoolInitialValues}
        onSubmit={async ({ additional, ...values }, { resetForm }) => {
          const newSuperCoach = additional.coaches?.find(
            (coach) => !coach.coachUserId && coach.isSuperCoach,
          );

          await schoolUpdate
            .mutateAsync({
              ...values,
              id,
              locationId: additional.cityId || additional.districtId || additional.regionId,
              superCoachId: newSuperCoach
                ? null
                : additional.coaches?.find((coach) => coach.isSuperCoach)?.coachUserId ||
                  school?.superCoachId,
            })
            .then(() => notify('success', 'Школа успешно изменена'));

          if (additional.coaches?.length) {
            additional.coaches
              .filter((coach) => !newSuperCoach || coach.coachUserId || !coach.isSuperCoach)
              .forEach((coach) => {
                if (coach.coachUserId) {
                  coachEdit.mutate({ ...coach, id: coach.id || '' });
                } else {
                  coachCreate.mutateAsync({
                    ...coach,
                    email: coach.email || '',
                    isSuperCoach: false,
                    phone: phoneMaskSanitizer(coach.phone || ''),
                    schoolId: id,
                  });
                }
              });

            if (newSuperCoach) {
              setTimeout(() => {
                coachCreate.mutateAsync({
                  ...newSuperCoach,
                  email: newSuperCoach.email || '',
                  isSuperCoach: true,
                  phone: phoneMaskSanitizer(newSuperCoach.phone || ''),
                  schoolId: id,
                });
              }, 1000);
            }
          }

          notify('success', 'Тренера успешно изменены');
          onCancel();
          resetForm();

          resetForm();
          onCancel();
        }}
        validationSchema={schoolWithCoachesCreateValidation}
      >
        <SchoolForm
          isEdit
          isLoading={schoolUpdate.isLoading || coachCreate.isLoading || coachEdit.isLoading}
          onCancel={onCancel}
        />
      </Formik>
    </Modal>
  );
};
