import { FC } from 'react';
import { Button, Tag } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { api, IAdminsRequest } from '@chess-class/sandbox';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';

import { AdminTable } from '~/components/organisms/adminTable/AdminTable';
import { PageMeta } from '~/context/PageMetaContext';
import { checkEditPermission } from '~/utils/checkEditPermission';

import { StaffEditModal } from '~mStaff/components/organisms/StaffEditModal';
import { StaffInviteModal } from '~mStaff/components/organisms/StaffInviteModal';
import { staffsColumns } from '~mStaff/consts/columns/staffsColumns';
import { staffsFilters } from '~mStaff/consts/filters/staffsFilters';
import {
  IStaffsPageActions,
  staffsPageActions,
} from '~mStaff/consts/pageActions/IStaffsPageActions';

const StaffsPage: FC = () => {
  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    IAdminsRequest,
    IStaffsPageActions
  >(staffsFilters, staffsPageActions);
  const meQuery = api.user.adminAuth.useAdminMe();

  const staffs = api.user.admin.useAdmins(filtered);
  const staffDelete = api.user.admin.useAdminDelete();
  const staffInviteResend = api.user.admin.useAdminInviteResend();

  const canEditStaff = checkEditPermission('materials');

  return (
    <div>
      <PageMeta
        selectedMenuKeys={['staff']}
        subTitle={
          actioned.action == 'invite'
            ? 'Новый персонал'
            : actioned.action == 'edit'
              ? `Изменить персонал #${actioned.actionId}`
              : ''
        }
        title="Персонал"
      />

      {canEditStaff && (
        <div className="flex justify-end items-center">
          <Tag
            className="flex items-baseline py-2.5 px-4 rounded-xl"
            color="geekblue"
            icon={<UserOutlined />}
          >
            <h3 className="mb-0">{meQuery.data?.email}</h3>
          </Tag>
          <Button
            className="sm:px-12"
            onClick={() => setActioned({ action: 'invite' })}
            size="large"
            type="primary"
          >
            Создать
          </Button>
        </div>
      )}

      <AdminTable
        columns={staffsColumns({
          canEdit: canEditStaff,
          onClickDelete: staffDelete.mutateAsync,
          onClickEdit: ({ id }) => setActioned({ action: 'edit', actionId: id }),
          onClickInvitationResend: staffInviteResend.mutateAsync,
        })}
        emptyDescription={
          <div>
            <p>Персонал не найден.</p>
            <p>Вы можете создать персонал, нажав на кнопку “Новый персонал”</p>
          </div>
        }
        query={staffs}
        tableFilter={{ filtered, filters: staffsFilters, onFilter }}
      />

      <StaffInviteModal isOpen={actioned.action == 'invite'} onCancel={clearParams} />

      <StaffEditModal
        id={actioned.actionId}
        isOpen={actioned.action == 'edit'}
        onCancel={clearParams}
      />
    </div>
  );
};

export default StaffsPage;
