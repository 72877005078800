import type { FC } from 'react';
import styled from '@emotion/styled';

type Props = {
  id: string;
};

const PgnViewer = styled.div<Omit<Props, 'id'>>`
  .lpv__board {
    max-width: 66vh;
    max-height: 66vh;
  }

  cg-board {
    background-image: url('/images/board.svg');
  }
`;

export const ChessPgnViewer: FC<Props> = (props) => {
  return <PgnViewer {...props} />;
};
