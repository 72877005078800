import type { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, Divider } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { api, IStudentsRequest } from '@chess-class/sandbox';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';

import { AdminTable } from '~/components/organisms/adminTable/AdminTable';
import { PageMeta } from '~/context/PageMetaContext';

import { studentColumns } from '~mGroups/students/consts/columns/studentColumns';
import { studentFilters } from '~mGroups/students/consts/filters/studentFilters';
import {
  StudentPageActions,
  studentPageActions,
} from '~mGroups/students/consts/pageActions/IStudentPageActions';
import { extractLocation } from '~mSchools/utils/extractLocation';

export const GroupPage: FC = () => {
  const { id } = useParams();

  const classQuery = api.schools.groups.useGroup({
    enabled: !!id,
    id: id ?? '',
  });

  const classData = classQuery.data?.data;

  const { filtered, onFilter } = useUrlFilteredActioned<IStudentsRequest, StudentPageActions>(
    studentFilters,
    studentPageActions,
  );

  const students = api.user.students.useStudents({
    ...filtered,
    assigned: true,
    enabled: !!id && !!classData,
    groupIds: id ? [id] : [],
    schoolId: classData?.school?.id,
  });

  return (
    <>
      <PageMeta selectedMenuKeys={['classes']} title={`Класс 11А`} />

      <Link to="/classes">
        <div className="flex items-center space-x-4 text-black font-semibold text-xl">
          <ArrowLeftOutlined />
          <span>К списку всех классов</span>
        </div>
      </Link>

      <div className="mt-8">
        <div className="flex flex-col">
          <span className="text-xl font-semibold block mb-4">{classData?.name} класс</span>
          <div className="flex flex-col space-y-2">
            {/* <span>Статус: Активна</span> */}
            <span className="text-base">
              Количество учеников в классе: {classData?.studentsCount ?? 0}
            </span>
          </div>
        </div>

        <Divider />

        <div>
          <span className="text-xl font-semibold block mb-4">{classData?.school?.name ?? '-'}</span>

          <div className="flex flex-col space-y-3 text-base">
            <span>
              Область:{' '}
              <strong>
                {classData?.school
                  ? extractLocation(classData.school.location, 'isRegion')?.nameRu
                  : '-'}
              </strong>
            </span>
            <span>
              Город:{' '}
              <strong>
                {classData?.school
                  ? extractLocation(classData.school.location, 'isLocality')?.nameRu
                  : '-'}
              </strong>
            </span>
            <span>
              Адрес: <strong>{classData?.school?.address ?? '-'}</strong>
            </span>
            <span>
              Email: <strong>{classData?.school?.email ?? '-'}</strong>
            </span>
          </div>
        </div>

        <Divider />

        <div>
          <span className="text-xl font-semibold mb-4 block">Тренер: </span>
          <div className="grid grid-cols-2">
            <Card className="bg-ant-blue-1 flex">
              <div className="flex flex-col font-semibold">
                <span>{`${classData?.coach?.firstName} ${classData?.coach?.lastName} ${classData?.coach?.middleName}`}</span>
                {/*<span>{classData?.coach?.email ?? '-'}</span>*/}
                {/*<span>{classData?.coach?.phone ?? '-'}</span>*/}
              </div>
            </Card>
          </div>
        </div>

        <Divider />

        <AdminTable
          columns={studentColumns()}
          emptyDescription={
            <div>
              <p>Учеников не найдено.</p>
            </div>
          }
          query={students}
          tableFilter={{ filtered, filters: studentFilters, onFilter }}
        />
      </div>
    </>
  );
};
