import { IEnumSchoolStatus, IFilters } from '@chess-class/sandbox';
import { modelKeys, paginationBaseFilters } from '@chess-class/sandbox/consts';

import { LocationSelectMultipleFilter } from '~/components/molecules/filters/dynamic/LocationSelectMultipleFilter';
import { SchoolCoachesSelectMultiple } from '~/components/molecules/filters/dynamic/SchoolCoachesSelectMultipleFilter';
import { CheckboxFilter } from '~/components/molecules/filters/static/CheckboxFilter';
import { InputFilter } from '~/components/molecules/filters/static/InputFilter';

export const schoolFilters: IFilters = {
  ...paginationBaseFilters,
  cityIds: {
    input: <LocationSelectMultipleFilter placeholder="Выберите город" type="isLocality" />,
    queries: [{ key: 'cityIds' }],
  },
  districtIds: {
    input: <LocationSelectMultipleFilter placeholder="Выберите район" type="isDistrict" />,
    queries: [{ key: 'districtIds' }],
  },
  regionIds: {
    input: <LocationSelectMultipleFilter placeholder="Выберите регион" type="isRegion" />,
    queries: [{ key: 'regionIds' }],
  },
  sort: { queries: [{ default: '-createdDate' }] },
  [modelKeys.school.school.status]: {
    input: (
      <CheckboxFilter
        options={Object.entries(IEnumSchoolStatus).map(([value, label]) => ({
          label,
          value,
        }))}
      />
    ),
    sort: true,
  },
  [modelKeys.school.school.superCoachId]: {
    input: <SchoolCoachesSelectMultiple placeholder="Выберите тренера" />,
    queries: [{ key: 'superCoachIds' }],
  },
  [modelKeys.school.school.name]: {
    input: <InputFilter placeholder="Введите название" />,
    queries: [{ key: 'name' }],
    sort: true,
  },
};
