import { FC } from 'react';
import { Formik } from 'formik';

import { api, IExercise, IExerciseTest, IExerciseTestEditRequest } from '@chess-class/sandbox';
import { notify } from '@chess-class/sandbox/utils';

import TestForm from '~mMaterials/exercises/exercises/components/molecules/TestForm';

export const TestEditFormik: FC<{
  exercise: IExercise & { test: IExerciseTest };
  onCancel: () => void;
}> = ({ exercise, onCancel }) => {
  const testEdit = api.content.exercises.useExerciseTestEdit();

  return (
    <Formik<IExerciseTestEditRequest>
      enableReinitialize
      initialValues={{
        ...exercise.test,
        chapterId: exercise.chapter?.id as string,
        correctOption: exercise.test.correctOption || 1,
        gamePhase: exercise.gamePhase,
        id: exercise.id,
        imageUrl: exercise.test.imageUrl,
        isCapitalization: exercise.isCapitalization,
        isTactic: exercise.isTactic,
        level: exercise.level,
        status: exercise.status,
        target: exercise.target,
        translations: exercise.test.translations,
      }}
      onSubmit={(values, { resetForm }) => {
        testEdit.mutateAsync(values).then((data) => {
          if (data.status == 201 || data.status == 200) {
            notify('success', 'Тест изменен');
            onCancel();
            resetForm();
          }
        });
      }}
      // validationSchema={api.content.exercises.helpers.exerciseTestEditValidation}
    >
      <TestForm isEdit isLoading={testEdit.isLoading} onCancel={onCancel} />
    </Formik>
  );
};
