import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgTitleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg height="24px" viewBox="0 0 384 384" width="24px" {...props}>
    <path
      d="M86.08,0c70.86,0,141.72,0,212.58,0,.44,.21,.85,.52,1.31,.6,26.05,4.92,42.46,24.42,42.47,50.96,.06,102.17,.03,204.34-.01,306.51,0,2.84-.18,5.79-.93,8.51-4.32,15.6-21.63,21.33-35.77,11.92-32.99-21.97-65.96-43.98-98.95-65.95-10.19-6.79-18.64-6.77-28.81,0-23.14,15.42-46.27,30.84-69.4,46.26-10.37,6.91-20.64,13.99-31.16,20.68-12.42,7.9-28.47,2.73-33.42-10.8-1.29-3.52-1.65-7.56-1.66-11.36-.09-101.55-.07-203.09-.06-304.64,0-1.99,.05-3.99,.19-5.98,1.47-20.52,14.54-37.53,33.96-44.08,3.15-1.06,6.43-1.76,9.65-2.62Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgTitleIcon);
export default Memo;
