import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgGraduationFillIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg height="24px" viewBox="0 0 384 384" width="24px" {...props}>
    <path
      d="M188.88,57.03c5.2,1.74,10.52,2.6,14.88,5.12,48.15,27.74,96.09,55.83,144.25,83.56,6.56,3.78,7.25,12.51,.04,16.67-48.12,27.78-96.1,55.83-144.2,83.66-10.36,6-20.8,5.28-31.11-.73-40.84-23.8-81.72-47.52-122.58-71.28-6.77-3.94-13.58-7.82-20.33-11.81-7.3-4.32-7.36-12.04-.07-16.28,48.17-28.01,96.32-56.05,144.6-83.87,4.26-2.46,9.44-3.33,14.51-5.05Z"
      fill="currentColor"
    />
    <path
      d="M78.47,211.07c10.19,5.92,19.65,11.42,29.11,16.92,18.75,10.89,37.5,21.77,56.24,32.68,16.79,9.78,33.58,9.74,50.35,.01,27.16-15.76,54.3-31.55,81.45-47.32,1.06-.61,2.15-1.17,3.61-1.96,.11,1.4,.25,2.44,.25,3.49,.02,13.35-.04,26.7,.02,40.05,.05,11.52-4.9,20.15-14.8,25.89-19.94,11.57-39.79,23.29-59.93,34.5-24.2,13.46-48.65,13-72.69-.54-19.65-11.07-39.05-22.57-58.59-33.83-10.09-5.81-15.11-14.42-15.04-26.1,.08-14.34,.02-28.67,.02-43.78Z"
      fill="currentColor"
    />
    <path
      d="M351.71,180.76c0,2.14,0,3.49,0,4.84,0,27.57,.02,55.14-.04,82.71,0,2.39,.59,4.05,2.43,5.79,7.94,7.47,7.89,19.84,.13,27.41-7.59,7.41-19.94,7.18-27.23-.5-7.34-7.74-7.07-19.97,.85-27.16,1.8-1.64,2.28-3.23,2.27-5.46-.05-23.83,.02-47.65-.1-71.48-.01-2.75,.82-4.2,3.22-5.49,6.01-3.23,11.85-6.8,18.47-10.65Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgGraduationFillIcon);
export default Memo;
