import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from '~/components/molecules/routes/ProtectedRoute';
import NotFoundPage from '~/components/pages/errors/NotFoundPage';

import { GroupPage } from '~mGroups/groups/pages/GroupPage';
import { GroupsPage } from '~mGroups/groups/pages/GroupsPage';
import { StudentsPage } from '~mGroups/students/pages/StudentsPage';

export default function GroupsRouter() {
  return (
    <ProtectedRoute access="classes">
      <Routes>
        <Route element={<GroupsPage />} index />
        <Route element={<GroupPage />} path=":id" />
        <Route element={<StudentsPage />} path="coaches" />
        <Route element={<NotFoundPage />} path="*" />
      </Routes>
    </ProtectedRoute>
  );
}
