import { FC } from 'react';
import { Button, Tag, Tooltip } from 'antd';
import { Formik, FormikProps } from 'formik';
import { Form } from 'formik-antd';
import { FileOutlined } from '@ant-design/icons';

import {
  api,
  filteredLocalizations,
  ILocalization,
  IVideo,
  IVideoStepOneUpdateRequest,
} from '@chess-class/sandbox';
import { localizationsToObject, notify } from '@chess-class/sandbox/utils';

import { useEducationFormat } from '~/context/EducationFormContext';

import { VideoLocaleFormCard } from '~mMaterials/videos/videos/components/molecules/VideoLocaleFormCard';
import { AllowedSteps } from '~mMaterials/videos/videos/utils/checkAllowedSteps';

export const StepOne: FC<{
  changeStep: (step: 0 | 1 | 2) => void;
  onCancel: () => void;
  steps: AllowedSteps;
  video: IVideo;
}> = ({ changeStep, onCancel, steps, video }) => {
  const videoOneUpdate = api.content.videos.useVideoStepOneUpdate();
  const { defaultEducationFormat } = useEducationFormat();

  const submitDraft = ({
    resetForm,
    values,
  }: Pick<
    FormikProps<IVideoStepOneUpdateRequest>,
    'values' | 'setTouched' | 'setErrors' | 'resetForm'
  >) => {
    videoOneUpdate.mutateAsync({ ...values, status: 'DRAFT' }).then((data) => {
      if (data.status == 201 || data.status == 200) {
        notify('success', 'Видео сохранено как черновик');
        onCancel();
        resetForm();
      }
    });
  };

  return (
    <Formik<IVideoStepOneUpdateRequest>
      enableReinitialize
      initialValues={{
        educationFormat: defaultEducationFormat,
        id: video.id,
        status: video.status,
        translations: localizationsToObject((locale) => ({
          educationFormat: defaultEducationFormat,
          previewUrl: video.translations[locale]?.previewUrl || '',
          title: video.translations[locale]?.title || '',
          videoFileId: video.translations[locale]?.videoFile?.uuid ?? '',
        })),
      }}
      onSubmit={(values) => {
        videoOneUpdate.mutateAsync(values).then((data) => {
          if (data.status == 201 || data.status == 200) {
            if (video.type == 'CARTOON') {
              notify('success', 'Мультфильм сохранен');
              onCancel();
            } else {
              notify('success', 'Шаг 1 сохранен');
              changeStep(1);
            }
          }
        });
      }}
      validationSchema={api.content.videos.helpers.videoStepOneUpdateValidation}
    >
      {({ dirty, resetForm, setErrors, setFieldValue, setTouched, values }) => (
        <Form className="space-y-4 md:space-y-6" layout="vertical">
          <h1 className="text-2xl">
            Изменение
            {video.type == 'CARTOON' ? (
              <span className="text-ant-magenta-6 ml-1"> мультфильма #{video.id}</span>
            ) : (
              <span className="text-ant-purple-6 ml-1"> видеоурока #{video.id}</span>
            )}
            <Tag className="ml-2" color="processing">
              {defaultEducationFormat == 'GENERAL' ? 'Гос. школа' : 'Частная школа'}
            </Tag>
          </h1>

          <div className="grid sm:grid-cols-2 gap-3">
            {filteredLocalizations({
              defaultEducationFormat: defaultEducationFormat ?? 'GENERAL',
              isDev:
                process.env.REACT_APP_API_URL.includes('api.dev') ||
                process.env.NODE_ENV == 'development',
            }).map((locale) => (
              <VideoLocaleFormCard
                key={`video-locale-card-${locale}`}
                localization={locale as ILocalization}
                previewUrl={video.translations[locale]?.previewUrl}
                setFieldValue={setFieldValue}
                type={video.type}
              />
            ))}
          </div>

          <div className="grid sm:grid-cols-2 gap-5">
            <Button block onClick={onCancel}>
              Отменить
            </Button>
            <div className="flex flex-row gap-3">
              <Button
                block
                htmlType="submit"
                loading={videoOneUpdate.isLoading}
                onClick={() => !steps['1'].disabled && !dirty && changeStep(1)}
                type="primary"
              >
                <span className="max-w-full overflow-ellipsis overflow-hidden">
                  {video.type == 'CARTOON' ? 'Изменить и запустить' : 'Следующий шаг'}
                </span>
              </Button>
              <Tooltip title="Сохранить как черновик">
                <Button
                  className="px-2 border-dashed border-ant-gray-6 hover:border-ant-blue-5 shadow-none"
                  ghost
                  icon={<FileOutlined />}
                  onClick={() => submitDraft({ resetForm, setErrors, setTouched, values })}
                  type="primary"
                />
              </Tooltip>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
