import { IPageActions } from '@chess-class/sandbox';

enum ChaptersPageActionEnum {
  'createChapter',
  'edit',
  'view',
}

export type ChaptersPageActions = {
  action: keyof typeof ChaptersPageActionEnum;
  actionId: string;
};

export const chaptersPageActions: IPageActions<ChaptersPageActions> = {
  action: {
    allowed: (value) => Object.keys(ChaptersPageActionEnum).includes(value),
    default: '',
  },
  actionId: { default: '' },
};
