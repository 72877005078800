import { Button, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { DeleteOutlined, MailOutlined } from '@ant-design/icons';

import { api, IAdmin, IAdminInviteResendRequest } from '@chess-class/sandbox';
import { modelKeys } from '@chess-class/sandbox/consts';
import { notify } from '@chess-class/sandbox/utils';

import PencilIcon from '~/components/atoms/icons/PencilIcon';

import { StatusTag } from '~/components/atoms/StatusTag';

export const staffsColumns = ({
  canEdit,
  onClickDelete,
  onClickEdit,
  onClickInvitationResend,
}: {
  canEdit: boolean;
  onClickDelete: ({ id }: { id: string }) => void;
  onClickEdit: ({ id }: { id: string }) => void;
  onClickInvitationResend: (payload: IAdminInviteResendRequest) => Promise<AxiosResponse<void>>;
}): ColumnsType<IAdmin> => {
  const me = api.user.adminAuth.useAdminMe();

  const columns: ColumnsType<IAdmin> = [
    {
      dataIndex: modelKeys.user.admin.firstName,
      render: (_, staff) =>
        `${staff.firstName ?? ''} ${staff.lastName ?? ''} ${staff.middleName ?? ''}`,
      title: 'ФИО',
    },
    {
      dataIndex: modelKeys.user.admin.email,
      title: 'Почта',
    },
    {
      dataIndex: modelKeys.user.admin.lastActivityAt,
      render: (lastActivityAt?: IAdmin['lastActivityAt']) =>
        lastActivityAt ? moment(lastActivityAt).format('LLL') : '-',
      title: 'Последняя активность',
    },
    {
      dataIndex: modelKeys.user.admin.status,
      render: (status: IAdmin['status']) => <StatusTag status={status} />,
      title: 'Статус',
    },
  ];

  if (!canEdit) {
    return columns;
  }

  return [
    ...columns,
    {
      key: 'action',
      render: ({ email, firstName, id, lastName, middleName, status }: IAdmin) => (
        <div className="flex gap-1.5">
          {status == 'INVITED' && (
            <Popconfirm
              cancelText="Отменить"
              icon={<MailOutlined className="text-ant-blue-5 text-base" />}
              okText="Отправить"
              okType="primary"
              onConfirm={() =>
                onClickInvitationResend(email).then(() => {
                  notify('success', 'Приглашение тренеру отправлено');
                })
              }
              placement="topLeft"
              title={
                <p className="text-sm mb-0">
                  Вы уверены, что хотите{' '}
                  <span className="font-semibold">
                    отправить приглашение тренеру &quot;
                    {`${firstName} ${lastName} ${middleName}`}&quot;?
                  </span>
                </p>
              }
            >
              <Button ghost icon={<MailOutlined />} type="primary" />
            </Popconfirm>
          )}
          <Button
            disabled={me.data?.id === id}
            ghost
            icon={<PencilIcon />}
            onClick={() => onClickEdit({ id })}
            type="primary"
          />
          <Popconfirm
            cancelText="Отменить"
            disabled={me.data?.id === id}
            icon={<DeleteOutlined className="text-ant-red-5" color="red" />}
            okText="Удалить"
            okType="danger"
            onConfirm={() => onClickDelete({ id })}
            placement="topLeft"
            title={
              <span className="text-sm">
                Вы уверены, что хотите удалить <span className="font-bold">{email}</span>?
              </span>
            }
          >
            <Button danger disabled={me.data?.id === id} icon={<DeleteOutlined />} />
          </Popconfirm>
        </div>
      ),
      title: 'Действия',
    },
  ];
};
