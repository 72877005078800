import { FunctionComponent } from 'react';

import { IEnumAdminPermissionKeys, IEnumEducationFormatKeys } from '@chess-class/sandbox';

import {
  ExerciseIcon,
  GraduationFillIcon,
  GroupsFillIcon,
  GuidelineIcon,
  SettingsIcon,
  StaffIcon,
  StudioIcon,
  TbVideoIcon,
  TextIcon,
  TitleIcon,
} from '~/components/atoms/icons';

export interface SidebarInterface {
  access?: IEnumAdminPermissionKeys;
  children?: SidebarInterface[];
  icon?: FunctionComponent<{ className?: string }>;
  iconClassName?: string;
  key: string;
  link: string;
  title?: string;
}

export const sidebars: ({
  educationFormat,
}: {
  educationFormat?: IEnumEducationFormatKeys;
}) => SidebarInterface[] = ({
  educationFormat,
}: {
  educationFormat?: IEnumEducationFormatKeys;
}) => {
  return [
    {
      access: 'materials',
      children: [
        { icon: ExerciseIcon, key: 'exercises', link: 'exercises', title: 'Задачи' },
        { icon: TbVideoIcon, key: 'videos', link: 'videos', title: 'Видео' },
        {
          icon: TextIcon,
          iconClassName: 'h-5 w-5',
          key: 'chapters',
          link: 'chapters',
          title: 'Темы',
        },
        {
          icon: GuidelineIcon,
          iconClassName: 'h-5 w-5',
          key: 'guidelines',
          link: 'guidelines',
          title: 'Методичка',
        },
        { icon: StudioIcon, key: 'studios', link: 'studios', title: 'Студия' },
      ],
      icon: TitleIcon,
      iconClassName: 'h-5 w-5',
      key: 'materials',
      link: 'materials',
      title: 'Материалы',
    },
    {
      access: 'schools',
      icon: GraduationFillIcon,
      key: 'schools',
      link: 'schools',
      title: 'Школы',
    },
    {
      access: 'classes',
      icon: GroupsFillIcon,
      key: 'classes',
      link: 'classes',
      title: educationFormat == 'GENERAL' ? 'Классы' : 'Группы',
    },
    {
      icon: ExerciseIcon,
      key: 'reviews',
      link: 'reviews',
      title: 'Обратная связь',
    },
    {
      access: 'staff',
      icon: StaffIcon,
      key: 'staff',
      link: 'staff',
      title: 'Персонал',
    },
    {
      access: 'settings',
      icon: SettingsIcon,
      iconClassName: 'h-5 w-5',
      key: 'settings',
      link: 'settings',
      title: 'Настройки',
    },
  ];
};
