import { useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';

enum IEnumVideoTabsType {
  videos = 'Видеобиблиотека',
  // eslint-disable-next-line typescript-sort-keys/string-enum
  authors = 'Авторы',
  files = 'Файлы',
}

type IEnumVideosTabsTypeKeys = keyof typeof IEnumVideoTabsType;

export default function VideoTabs({ active }: { active: IEnumVideosTabsTypeKeys }) {
  const navigate = useNavigate();

  return (
    <Tabs
      activeKey={active}
      centered
      items={Object.entries(IEnumVideoTabsType).map(([key, label]) => ({
        key,
        label,
      }))}
      onChange={(tab: IEnumVideosTabsTypeKeys | string) =>
        navigate(
          tab == 'videos'
            ? '/materials/videos'
            : tab == 'authors'
              ? `/materials/video-authors`
              : '/materials/video-files',
        )
      }
    />
  );
}
