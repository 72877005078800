import { IVideo } from '@chess-class/sandbox';

export type AllowedSteps = Record<
  0 | 1 | 2,
  { disabled: boolean; status: 'error' | 'process' | 'finish' | 'wait' }
>;

export const checkAllowedSteps = (video: IVideo | undefined) => {
  const steps: AllowedSteps = {
    0: {
      disabled: false,
      status: 'process',
    },
    1: {
      disabled: true,
      status: 'wait',
    },
    2: {
      disabled: true,
      status: 'wait',
    },
  };

  // TODO: LOCALIZE
  if (
    video &&
    // video.translations?.en?.title &&
    // video.translations.en.videoFile?.uuid &&
    // video.translations.en.previewUrl &&
    video.translations.ru?.title &&
    video.translations.ru.previewUrl &&
    video.translations.ru.videoFile?.uuid &&
    video.translations.kz?.title &&
    video.translations.kz.previewUrl &&
    video.translations.kz.videoFile?.uuid
  ) {
    if (video.type === 'CARTOON') {
      steps[0].status = 'finish';
      steps[1].status = 'finish';
      steps[2].status = 'finish';
      return steps;
    }
    // if (!video.translations.ru.author?.id || !video.translations.en.author?.id) {
    //   return steps;
    // }
    steps[0].status = 'finish';
    steps[1].status = 'process';
    steps[1].disabled = false;
  } else return steps;

  if (video.level) {
    steps[1].status = 'finish';
    steps[2].status = 'process';
    steps[2].disabled = false;
  } else return steps;

  if (
    video.test?.correctOption &&
    // video.test.translations?.en?.question &&
    // (video.test.translations.en.answerOptions.length || 0) > 3 &&
    video.test.translations?.ru?.question &&
    (video.test.translations.ru.answerOptions.length || 0) > 3
  ) {
    steps[2].status = 'finish';
  }

  return steps;
};
