import { FC, useMemo } from 'react';
import { Button, Modal, Select as SelectAntd } from 'antd';
import { AxiosError } from 'axios';
import { Formik } from 'formik';
import { Form, FormItem, Input, Select } from 'formik-antd';

import { api, IBackendError, IGuideline, IGuidelineCreateRequest } from '@chess-class/sandbox';
import { modelKeys } from '@chess-class/sandbox/consts';
import { notify } from '@chess-class/sandbox/utils';

import { useEducationFormat } from '~/context/EducationFormContext';

import ChapterDraggableList from '~mMaterials/guidelines/components/molecules/ChapterDraggableList';

export const GuidelineEditModal: FC<{
  guide?: IGuideline;
  isOpen: boolean;
  onCancel: () => void;
}> = ({ guide, isOpen, onCancel }) => {
  const guideChaptersQuery = api.guideline.guidelineChapters.useGuidelineChapters({
    guidelineId: guide?.id,
    sort: 'priority',
  });

  const guidelineChapters = guideChaptersQuery.data?.data.content;
  const { defaultEducationFormat } = useEducationFormat();

  const chaptersQuery = api.guideline.guidelineChapters.useGuidelineChapters({
    educationFormat: defaultEducationFormat,
    size: 200,
  });
  const chapters = chaptersQuery.data?.data.content;
  const chaptersFiltered = useMemo(() => {
    const selectedChapterIds = guidelineChapters?.map((chapter) => chapter.id);
    return chapters?.filter((chapter) => !selectedChapterIds?.includes(chapter.id));
  }, [chapters, guidelineChapters]);

  const guideChapterAdd = api.guideline.guidelineChapters.useGuidelineChapterAdd();
  const guidelineUpdate = api.guideline.guideline.useGuidelineUpdate();
  const guidePublish = api.guideline.guideline.useGuidelinePublish();

  if (!guide) {
    return null;
  }

  return (
    <Modal
      className="px-4 md:px-0 md:w-3/4 lg:w-2/3"
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={`Изменить методичку - ${guide.name} - Версия ${guide.version}`}
    >
      <Formik<IGuidelineCreateRequest>
        enableReinitialize
        initialValues={{ grade: guide.grade, name: guide.name }}
        onSubmit={async (values, { resetForm }) => {
          if (!guidelineChapters?.length) {
            notify('error', 'Методичка должна содержать минимум 1 тему');
            return;
          }

          if (values.name != guide.name) {
            await guidelineUpdate.mutateAsync({
              id: guide.id,
              name: values.name,
            });
          }

          guidePublish.mutateAsync({ id: guide.id }).then(() => {
            notify('success', 'Методичка опубликована');
            onCancel();
            resetForm();
          });
        }}
        validationSchema={api.guideline.guideline.helpers.guidelineCreateValidation}
      >
        {() => (
          <Form className="flex flex-col gap-2 mt-6" layout="vertical">
            <FormItem label="Название" name={modelKeys.guideline.guideline.name}>
              <Input name={modelKeys.guideline.guideline.name} suffix />
            </FormItem>
            <FormItem label="Класс" name={modelKeys.guideline.guideline.grade}>
              <Select
                disabled
                name={modelKeys.guideline.guideline.grade}
                options={[1, 2, 3, 4].map((grade) => ({ label: `${grade}`, value: grade }))}
              />
            </FormItem>
            <FormItem className="mb-0" label="Темы" name="chapters">
              <SelectAntd
                onChange={(value, option) => {
                  if (!Array.isArray(option)) {
                    guideChapterAdd
                      .mutateAsync({
                        chapterId: option.chapter.id,
                        guideId: guide.id,
                      })
                      .catch((e: AxiosError<IBackendError>) => {
                        if (e.response?.status == 422) {
                          notify('error', 'Тема уже добавлена в другую методичку');
                        }
                      });
                  }
                }}
                optionFilterProp="label"
                options={chaptersFiltered?.map((chapter) => ({
                  chapter: chapter,
                  label:
                    chapter.translations?.ru?.title ||
                    // chapter.translations?.en?.title ||
                    chapter.id,
                  value: chapter.id,
                }))}
                placeholder="Выберите темы"
                showSearch
                value="Выберите темы"
              />
            </FormItem>

            {!!guidelineChapters?.length && (
              <div className="mt-6">
                <h5>Список выбранных тем</h5>
                <ChapterDraggableList chapters={guidelineChapters} deletable />
              </div>
            )}

            <div className="grid md:grid-cols-2 gap-5 mt-6">
              <Button block onClick={onCancel}>
                Отменить
              </Button>

              <Button
                block
                htmlType="submit"
                loading={
                  guideChapterAdd.isLoading || guidelineUpdate.isLoading || guidePublish.isLoading
                }
                type="primary"
              >
                <span className="max-w-full overflow-ellipsis overflow-hidden">Опубликовать</span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
