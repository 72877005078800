import { FC, useMemo, useState } from 'react';

import { api, FilterRender, IFiltersPropsDefault } from '@chess-class/sandbox';

import { SelectMultipleFilter } from '~/components/molecules/filters/static/SelectMultipleFilter';

interface LocationSelectMultipleFilterInterface {
  filterRender?: FilterRender & {
    filtered: IFiltersPropsDefault & {
      cityIds?: string[];
      districtIds?: string[];
      regionIds?: string[];
    };
  };
  placeholder?: string;
  type: 'isDistrict' | 'isLocality' | 'isRegion';
}

export const LocationSelectMultipleFilter: FC<LocationSelectMultipleFilterInterface> = ({
  filterRender,
  placeholder,
  type,
}) => {
  const [locationName, setLocationName] = useState<string>();

  const filterType = useMemo(() => {
    switch (type) {
      case 'isDistrict':
        return {
          isDistrict: true,
          parentId: filterRender?.filtered.regionIds,
        };
      case 'isLocality':
        return {
          isLocality: true,
          parentId: filterRender?.filtered.districtIds,
        };
      case 'isRegion':
        return {
          isRegion: true,
        };
    }
  }, [type]);

  const afterCallback = () => {
    if (filterRender?.dataIndex === 'regionIds') {
      return filterRender.onFilter({
        cityIds: undefined,
        districtIds: undefined,
      });
    } else if (filterRender?.dataIndex === 'districtIds') {
      return filterRender.onFilter({
        cityIds: undefined,
      });
    }
  };

  const locationQuery = api.schools.locations.useLocations({
    enabled: true,
    ...filterType,
    name: locationName,
  });
  const locationData = locationQuery.data?.data.content;

  const options =
    locationData?.map((location) => ({
      label: location.nameRu,
      value: location.id.toString(),
    })) ?? [];

  return (
    <SelectMultipleFilter
      afterCallback={afterCallback}
      filterOption={false}
      filterRender={filterRender}
      loading={locationQuery.isLoading}
      onSearch={(searchValue) => setLocationName(searchValue)}
      options={options}
      placeholder={placeholder ?? 'Выберите регион'}
    />
  );
};
