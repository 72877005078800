import { type FC } from 'react';
import { Modal } from 'antd';
import { Formik } from 'formik';

import { api } from '@chess-class/sandbox';
import { notify, phoneMaskSanitizer } from '@chess-class/sandbox/utils';

import { useEducationFormat } from '~/context/EducationFormContext';

import { SchoolForm } from './SchoolForm';

import {
  ISchoolWithCoachesCreateRequest,
  schoolWithCoachesCreateValidation,
  shortCoachInitial,
} from '~mSchools/consts/schoolAndCoachesValidation';

type SchoolCreateModalProps = {
  isOpen: boolean;
  onCancel: () => void;
};

export const SchoolCreateModal: FC<SchoolCreateModalProps> = ({ isOpen, onCancel }) => {
  const schoolCreate = api.schools.schools.useSchoolCreate();
  const coachCreate = api.user.coachAuth.useCoachInvite();
  const { defaultEducationFormat } = useEducationFormat();

  return (
    <Modal
      className="px-4 md:px-0 md:w-4/5 lg:w-3/4 top-4"
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      open={isOpen}
    >
      <Formik<ISchoolWithCoachesCreateRequest>
        enableReinitialize
        initialValues={{
          ...api.schools.schools.helpers.schoolCreateInitial,
          additional: {
            cityId: null,
            coaches: [],
            districtId: null,
            regionId: null,
            superCoach: shortCoachInitial,
          },
        }}
        onSubmit={async ({ additional, ...values }, { resetForm }) => {
          const schoolResponse = await schoolCreate.mutateAsync({
            ...values,
            educationFormat: defaultEducationFormat,
            locationId: additional.cityId || additional.districtId || additional.regionId,
          });

          const schoolId = schoolResponse.data.id;

          if (schoolId && additional.superCoach?.firstName) {
            await coachCreate.mutateAsync({
              ...additional.superCoach,
              email: additional.superCoach.email || '',
              isSuperCoach: true,
              phone: phoneMaskSanitizer(additional.superCoach.phone || ''),
              schoolId,
            });
          }

          if (schoolId && additional.coaches?.length) {
            additional.coaches.forEach((coach) => {
              coachCreate.mutateAsync({
                ...coach,
                email: coach.email || '',
                isSuperCoach: false,
                phone: phoneMaskSanitizer(coach.phone || ''),
                schoolId,
              });
            });
          }

          notify('success', 'Школа успешно создана');
          onCancel();
          resetForm();
        }}
        validateOnBlur
        validateOnChange
        validationSchema={schoolWithCoachesCreateValidation}
      >
        <SchoolForm
          isLoading={schoolCreate.isLoading || coachCreate.isLoading}
          onCancel={onCancel}
        />
      </Formik>
    </Modal>
  );
};
