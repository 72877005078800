import { FC } from 'react';
import { Button } from 'antd';

import { api, IChaptersRequest } from '@chess-class/sandbox';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';

import { AdminTable } from '~/components/organisms/adminTable/AdminTable';
import { useEducationFormat } from '~/context/EducationFormContext';
import { PageMeta } from '~/context/PageMetaContext';
import { checkEditPermission } from '~/utils/checkEditPermission';

import { ChapterCreateModal } from '~mMaterials/chapters/components/organisms/ChapterCreateModal';
import { ChapterEditModal } from '~mMaterials/chapters/components/organisms/ChapterEditModal';
import { chaptersColumns } from '~mMaterials/chapters/consts/columns/chaptersColumns';
import { chapterFilters } from '~mMaterials/chapters/consts/filters/chapterFilters';
import {
  ChaptersPageActions,
  chaptersPageActions,
} from '~mMaterials/chapters/consts/pageActions/ChaptersPageActions';

export const ChaptersPage: FC = () => {
  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    IChaptersRequest,
    ChaptersPageActions
  >(chapterFilters, chaptersPageActions);
  const { defaultEducationFormat } = useEducationFormat();
  const chapters = api.content.chapters.useChapters({
    ...filtered,
    educationFormat: defaultEducationFormat,
  });
  const chapterPause = api.content.chapters.useChapterStatusUpdate();
  const chapterDelete = api.content.chapters.useChapterDelete();
  const canEditChapter = checkEditPermission('materials');

  return (
    <div className="flex flex-col gap-4">
      <PageMeta
        openMenuKeys={['materials']}
        selectedMenuKeys={['materials-chapters']}
        title="Список тем"
      />

      {canEditChapter && (
        <div className="flex justify-end gap-3">
          <Button
            className="sm:px-12"
            onClick={() => setActioned({ action: 'createChapter' })}
            size="large"
            type="primary"
          >
            Создать тему
          </Button>
        </div>
      )}

      <AdminTable
        columns={chaptersColumns({
          canEdit: canEditChapter,
          onClickDelete: chapterDelete.mutateAsync,
          onClickEdit: ({ id }) => setActioned({ action: 'edit', actionId: id }),
          onClickPause: chapterPause.mutateAsync,
        })}
        emptyDescription={
          <div>
            <p>Темы не найдены.</p>
            <p>Вы можете создать тему, нажав на кнопку “Создать тему”</p>
          </div>
        }
        query={chapters}
        tableFilter={{ filtered, filters: chapterFilters, onFilter }}
      />

      <ChapterCreateModal isOpen={actioned.action == 'createChapter'} onCancel={clearParams} />
      <ChapterEditModal
        id={actioned.actionId as string}
        isOpen={actioned.action == 'edit'}
        onCancel={clearParams}
      />
    </div>
  );
};
