import { FC } from 'react';
import { Button, Modal } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, Select } from 'formik-antd';

import { api, IVideoFileUpdateRequest } from '@chess-class/sandbox';

import BaseSkeleton from '~/components/atoms/BaseSkeleton';
import { NotFoundResult } from '~/components/atoms/NotFoundResult';

export const VideoFileUpdateModal: FC<{
  fileUuid?: string;
  isOpen: boolean;
  onCancel: () => void;
}> = ({ fileUuid = '', isOpen, onCancel }) => {
  const videoFileQuery = api.content.videosFiles.useVideoFile({ uuid: fileUuid });
  const videoFile = videoFileQuery.data?.data;
  const authorsQuery = api.content.authors.useAuthors({ size: 9999 });

  const videoFileUpdate = api.content.videosFiles.useVideoFileUpdate();

  return (
    <Modal
      // className="px-4 md:px-0 md:w-4/5 lg:w-3/4 top-8"
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title="Изменить видеофайл"
    >
      {videoFileQuery.isFetching ? (
        <BaseSkeleton />
      ) : !videoFile ? (
        <NotFoundResult title="Видеофайл не найден" />
      ) : (
        <Formik<IVideoFileUpdateRequest>
          enableReinitialize
          initialValues={{
            authorId: videoFile.author?.id,
            title: videoFile.title,
            uuid: videoFile.uuid,
            videoFileName: videoFile.videoFileName,
          }}
          onSubmit={(values) => {
            videoFileUpdate.mutateAsync(values).then(onCancel);
          }}
          validationSchema={api.content.videosFiles.helpers.videoFileUpdateValidation}
        >
          <Form className="flex flex-col gap-4 mt-8" layout="vertical">
            <FormItem label="Название видео" name="title">
              <Input name="title" suffix />
            </FormItem>
            <FormItem label="Автор" name={`authorId`}>
              <Select
                allowClear
                name={`authorId`}
                options={
                  authorsQuery.data?.data.content.map((author) => ({
                    label: author.rank ? (
                      <>
                        <span className="font-bold text-ant-red-7 mr-1">{author.rank}</span>
                        {author.translations.ru?.name}
                      </>
                    ) : (
                      author.translations.ru?.name
                    ),
                    value: author.id,
                  })) || []
                }
                placeholder="Автор"
              />
            </FormItem>

            <div className="grid md:grid-cols-2 gap-5">
              <Button block onClick={onCancel}>
                Отменить
              </Button>

              <Button htmlType="submit" loading={videoFileUpdate.isLoading} type="primary">
                Сохранить изменения
              </Button>
            </div>
          </Form>
        </Formik>
      )}
    </Modal>
  );
};
