import { FC, useState } from 'react';
import { Modal, Tag } from 'antd';
import { Formik } from 'formik';

import { api, IChapterCreateRequest } from '@chess-class/sandbox';
import { notify } from '@chess-class/sandbox/utils';

import { useEducationFormat } from '~/context/EducationFormContext';

import ChapterForm from '~mMaterials/chapters/components/molecules/ChapterForm';

export const ChapterCreateModal: FC<{
  isOpen: boolean;
  onCancel: () => void;
}> = ({ isOpen, onCancel }) => {
  const chapterCreate = api.content.chapters.useChapterCreate();
  const { defaultEducationFormat } = useEducationFormat();

  const nextNumId = api.content.chapters.useNextNumId({
    educationFormat: defaultEducationFormat ?? 'GENERAL',
  });

  const [chapterNumExistsAlready, setChapterNumExistsAlready] = useState<{
    numId: number;
    title: string;
  }>();

  return (
    <Modal
      className="px-4 md:px-0 md:w-4/5 lg:w-3/4 top-4"
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={
        <h1 className="text-xl space-x-1 mb-8">
          <span>Создать тему</span>
          <Tag color="processing">
            {defaultEducationFormat == 'GENERAL' ? 'Гос. школа' : 'Частная школа'}
          </Tag>
        </h1>
      }
    >
      <Formik<IChapterCreateRequest>
        enableReinitialize
        initialValues={{
          ...api.content.chapters.helpers.chapterCreateInitial,
          numId: nextNumId.data?.data.nextNumId,
        }}
        onSubmit={(values, { resetForm }) => {
          chapterCreate
            .mutateAsync({ ...values, educationFormat: defaultEducationFormat })
            .then((data) => {
              if (data.status == 201 || data.status == 200) {
                notify('success', 'Тема создана');
                onCancel();
                resetForm();
                setChapterNumExistsAlready({
                  numId: 0,
                  title: '',
                });
              }
            })
            .catch((error) => {
              if (error.response.status == 409) {
                setChapterNumExistsAlready(undefined);
              }
            });
        }}
        validationSchema={api.content.chapters.helpers.chapterCreateValidation}
      >
        <ChapterForm
          chapterNumExistsAlready={chapterNumExistsAlready}
          isLoading={chapterCreate.isLoading}
          onCancel={onCancel}
        />
      </Formik>
    </Modal>
  );
};
