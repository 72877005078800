import { FC, useEffect, useState } from 'react';
import { Button, Modal, Steps, Switch as AntdSwitch, Tag, Tooltip } from 'antd';
import { Formik, FormikProps } from 'formik';
import { Form, FormItem } from 'formik-antd';
import { FileOutlined } from '@ant-design/icons';

import {
  api,
  filteredLocalizations,
  ILocalization,
  IVideo,
  IVideoStepOneCreateRequest,
} from '@chess-class/sandbox';
import { notify } from '@chess-class/sandbox/utils';

import { useEducationFormat } from '~/context/EducationFormContext';

import { VideoLocaleFormCard } from '~mMaterials/videos/videos/components/molecules/VideoLocaleFormCard';

export const VideoCreateModal: FC<{
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: (id: string) => void;
}> = ({ isOpen, onCancel, onSubmit }) => {
  const [fileKeys, setFileKeys] = useState({
    previewEn: 'previewEn' + Math.random(),
    previewKz: 'previewKz' + Math.random(),
    previewRu: 'previewRu' + Math.random(),
  });
  const videoOneCreate = api.content.videos.useVideoStepOneCreate();
  const [type, setType] = useState<IVideo['type']>('LESSON');
  const { defaultEducationFormat } = useEducationFormat();

  const submitDraft = ({
    resetForm,
    values,
  }: Pick<
    FormikProps<IVideoStepOneCreateRequest>,
    'resetForm' | 'setErrors' | 'setTouched' | 'values'
  >) => {
    videoOneCreate.mutateAsync(values).then((data) => {
      if (data.status == 201 || data.status == 200) {
        notify('success', 'Видео сохранено как черновик');
        onCancel();
        setFileKeys({
          previewEn: fileKeys.previewEn + 1,
          previewKz: fileKeys.previewKz + 1,
          previewRu: fileKeys.previewRu + 1,
        });
        resetForm();
      }
    });
  };

  useEffect(() => {
    setType('LESSON');
  }, [isOpen]);

  const getInitialValues = () => {
    const initialValues = api.content.videos.helpers.videoStepOneCreateInitial;

    function addEducationFormat(translations) {
      for (const language in translations) {
        translations[language].educationFormat = defaultEducationFormat;
      }
      return translations;
    }
    return {
      ...initialValues,
      translations: addEducationFormat(initialValues.translations),
    };
  };

  return (
    <Modal
      className="px-4 md:px-0 w-full md:w-4/5 lg:w-3/4 top-8"
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={
        <Steps
          className="site-navigation-steps"
          current={undefined}
          items={[
            {
              disabled: true,
              status: 'process',
              title: 'Шаг 1',
            },
            ...(type == 'LESSON'
              ? [
                  { disabled: true, title: 'Шаг 2' },
                  { disabled: true, title: 'Шаг 3' },
                ]
              : []),
          ]}
          onChange={undefined}
          type="navigation"
        />
      }
    >
      <Formik<IVideoStepOneCreateRequest>
        enableReinitialize
        initialValues={getInitialValues()}
        onSubmit={(values, { resetForm }) => {
          videoOneCreate
            .mutateAsync(
              values.type == 'CARTOON'
                ? {
                    ...values,
                    educationFormat: defaultEducationFormat,
                    status: 'ACTIVE',
                  }
                : {
                    ...values,
                    educationFormat: defaultEducationFormat,
                  },
            )
            .then((data) => {
              if (data.status == 201 || data.status == 200) {
                if (values.type == 'CARTOON') {
                  notify('success', 'Мультфильм сохранен');
                  onCancel();
                } else {
                  notify('success', 'Шаг 1 сохранен');
                  onSubmit(data.data.id);
                }
                setFileKeys({
                  previewEn: fileKeys.previewEn + 1,
                  previewKz: fileKeys.previewKz + 1,
                  previewRu: fileKeys.previewRu + 1,
                });
                resetForm();
              }
            });
        }}
        validationSchema={api.content.videos.helpers.videoStepOneCreateValidation}
      >
        {({ resetForm, setErrors, setFieldValue, setTouched, values }) => (
          <Form className="space-y-4 md:space-y-6" layout="vertical">
            <h1 className="text-2xl flex items-center">
              Создание{' '}
              {values.type == 'CARTOON' ? (
                <span className="text-ant-magenta-6 ml-1"> мультфильма</span>
              ) : (
                <span className="text-ant-purple-6 ml-1"> видеоурока</span>
              )}
              <Tag className="ml-2" color="processing">
                {defaultEducationFormat == 'GENERAL' ? 'Гос. школа' : 'Частная школа'}
              </Tag>
            </h1>

            <div className="grid sm:grid-cols-2 gap-3">
              {filteredLocalizations({
                defaultEducationFormat: defaultEducationFormat ?? 'GENERAL',
                isDev:
                  process.env.REACT_APP_API_URL.includes('api.dev') ||
                  process.env.NODE_ENV == 'development',
              }).map((locale) => (
                <VideoLocaleFormCard
                  key={`video-locale-card-${locale}`}
                  localization={locale as ILocalization}
                  previewKey={fileKeys[`preview${locale[0].toUpperCase()}${locale[1]}`]}
                  setFieldValue={setFieldValue}
                  type={values.type}
                />
              ))}
            </div>

            <div className="flex flex-col sm:flex-row items-center justify-evenly text-center align-middle">
              <FormItem label="Мультфильм" name="isCartoon">
                <AntdSwitch
                  checked={values.type == 'CARTOON'}
                  onChange={(isCartoon) => {
                    setType(isCartoon ? 'CARTOON' : 'LESSON');
                    setFieldValue('type', isCartoon ? 'CARTOON' : 'LESSON');
                  }}
                />
              </FormItem>
            </div>

            <div className="grid sm:grid-cols-2 gap-4 md:gap-6">
              <Button
                block
                onClick={() => {
                  onCancel();
                  resetForm();
                }}
              >
                Отменить
              </Button>
              <div className="flex flex-row gap-3">
                <Button block htmlType="submit" loading={videoOneCreate.isLoading} type="primary">
                  <span className="max-w-full overflow-ellipsis overflow-hidden">
                    {values.type == 'CARTOON' ? 'Создать и запустить' : 'Следующий шаг'}
                  </span>
                </Button>
                <Tooltip title="Сохранить как черновик">
                  <Button
                    className="px-2 border-dashed border-ant-gray-6 hover:border-ant-blue-5 shadow-none"
                    ghost
                    icon={<FileOutlined />}
                    onClick={() => submitDraft({ resetForm, setErrors, setTouched, values })}
                    type="primary"
                  />
                </Tooltip>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
