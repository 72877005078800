import { array, boolean, number, object, ObjectSchema, string } from 'yup';

import {
  api,
  ICoachToSchool,
  IEnumCoachStatusKeys,
  IEnumSchoolStatusKeys,
  ISchoolCreateRequest,
} from '@chess-class/sandbox';

export type ISchoolWithCoachesCreateRequest = ISchoolCreateRequest & {
  additional: {
    cityId?: number | null;
    coaches?: IShortCoach[];
    districtId?: number | null;
    regionId?: number | null;
    superCoach?: Partial<IShortCoach>;
  };
};

export type IShortCoach = Pick<
  ICoachToSchool,
  'email' | 'firstName' | 'lastName' | 'middleName' | 'phone' | 'status'
> & { coachUserId?: string; id?: string; isSuperCoach?: boolean | null };

export const shortCoachInitial: IShortCoach = {
  coachUserId: '',
  email: '',
  firstName: '',
  id: '',
  isSuperCoach: false,
  lastName: '',
  middleName: '',
  phone: '',
  status: 'INVITED',
};

export const coachValidation: ObjectSchema<IShortCoach> = object({
  coachUserId: string().optional(),
  email: string().required('Обязательно для заполнения').email('Недействительная почта'),
  firstName: string().required('Обязательно для заполнения'),
  id: string().optional(),
  isSuperCoach: boolean().nullable().optional(),
  lastName: string().required('Обязательно для заполнения'),
  middleName: string().optional(),
  phone: string().required('Обязательно для заполнения'),
  status: string<IEnumCoachStatusKeys>().required('Обязательно для заполнения'),
});

const superCoachStringValidation = string()
  .optional()
  .when('$', {
    is: (request: ISchoolWithCoachesCreateRequest) => {
      const coach = request.additional.superCoach;
      if (request.status != 'ACTIVE' && coach) {
        return (
          !!coach.email ||
          !!coach.firstName ||
          !!coach.lastName ||
          !!coach.middleName ||
          !!coach.phone ||
          !!coach.coachUserId ||
          !!coach.id
        );
      }
      return !request['id'] && request.status == 'ACTIVE';
    },
    then: (schema) => schema.required('Обязательно для заполнения'),
  });

export const schoolWithCoachesCreateValidation: ObjectSchema<ISchoolWithCoachesCreateRequest> =
  api.schools.schools.helpers.schoolCreateValidation.concat(
    object({
      additional: object({
        cityId: number()
          .nullable()
          .when('$.status', {
            is: (status: IEnumSchoolStatusKeys) => status == 'ACTIVE',
            then: (schema) => schema.required('Обязательно для заполнения'),
          }),
        coaches: array().of(coachValidation.required()).optional(),
        districtId: number()
          .nullable()
          .when('$.status', {
            is: (status: IEnumSchoolStatusKeys) => status == 'ACTIVE',
            then: (schema) => schema.required('Обязательно для заполнения'),
          }),
        regionId: number()
          .nullable()
          .when('$.status', {
            is: (status: IEnumSchoolStatusKeys) => status == 'ACTIVE',
            then: (schema) => schema.required('Обязательно для заполнения'),
          }),
        superCoach: object({
          coachUserId: string().optional(),
          email: superCoachStringValidation.email('Недействительная почта'),
          firstName: superCoachStringValidation,
          id: string().optional(),
          lastName: superCoachStringValidation,
          middleName: string().optional(),
          phone: superCoachStringValidation,
          status: string<IEnumCoachStatusKeys>().optional(),
        }).required(),
      }),
    }),
  );
