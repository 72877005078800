import { FC } from 'react';
import { Button, Modal } from 'antd';
import { AxiosError } from 'axios';
import { Formik } from 'formik';
import { Form, FormItem, Input, Select } from 'formik-antd';

import {
  api,
  filteredLocalizations,
  IAuthorCreateRequest,
  IBackendError,
  IEnumAuthorRank,
  IEnumLocalizationGenitive,
} from '@chess-class/sandbox';
import { localizationsToObject, notify } from '@chess-class/sandbox/utils';

import { CountryImageText } from '~/components/atoms/CountryImageText';
import { useEducationFormat } from '~/context/EducationFormContext';

type AuthorCreateModalType = {
  isOpen: boolean;
  onCancel: () => void;
};

export const AuthorCreateModal: FC<AuthorCreateModalType> = ({ isOpen, onCancel }) => {
  const authorCreate = api.content.authors.useAuthorCreate();
  const { defaultEducationFormat } = useEducationFormat();

  return (
    <Modal
      className="px-4"
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={<h1 className="text-xl m-0">Создание автора</h1>}
    >
      <Formik<IAuthorCreateRequest>
        initialValues={api.content.authors.helpers.authorCreateInitial}
        onSubmit={(values, { resetForm }) => {
          authorCreate
            .mutateAsync(values)
            .then((data) => {
              if (data.status == 201 || data.status == 200) {
                notify('success', 'Автор создан');
                onCancel();
                resetForm();
              }
            })
            .catch((error: AxiosError<IBackendError>) => {
              if (error.response?.status == 422) {
                resetForm({
                  errors: {
                    translations: localizationsToObject(() => ({
                      name: 'Такое название уже существует',
                    })),
                  },
                  values,
                });
              }
            });
        }}
        validationSchema={api.content.authors.helpers.authorCreateValidation}
      >
        {() => (
          <Form className="space-y-8" layout="vertical">
            <FormItem label="Звание автора" name="rank">
              <Select
                name="rank"
                options={Object.keys(IEnumAuthorRank).map((rank) => ({
                  label: rank,
                  value: rank,
                }))}
              />
            </FormItem>

            {filteredLocalizations({
              defaultEducationFormat: defaultEducationFormat ?? 'GENERAL',
              isDev:
                process.env.REACT_APP_API_URL.includes('api.dev') ||
                process.env.NODE_ENV == 'development',
            }).map((locale) => (
              <FormItem
                key={`form-item-${locale}`}
                label={
                  <CountryImageText
                    src={locale}
                    text={`Имя автора на ${IEnumLocalizationGenitive[locale]}`}
                    width={14}
                  />
                }
                name={`translations.${locale}.name`}
              >
                <Input
                  maxLength={60}
                  name={`translations.${locale}.name`}
                  placeholder="Имя автора"
                  showCount
                  suffix
                  type="text"
                />
              </FormItem>
            ))}

            <div className="grid md:grid-cols-2 gap-5">
              <Button block onClick={onCancel}>
                Отменить
              </Button>

              <Button htmlType="submit" loading={authorCreate.isLoading} type="primary">
                Создать автора
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
