import { isInteger } from 'formik';

import { IPageActions } from '@chess-class/sandbox';

enum ExerciseGroupsPageActionEnum {
  'edit',
  'view',
}

export type IExerciseGroupsPageActions = {
  action: keyof typeof ExerciseGroupsPageActionEnum;
  actionId: string;
};

export const exerciseGroupsPageActions: IPageActions<IExerciseGroupsPageActions> = {
  action: {
    allowed: (value) => Object.keys(ExerciseGroupsPageActionEnum).includes(value),
    default: '',
  },
  actionId: { allowed: (value) => isInteger(value), default: '' },
};
