import { IPageActions } from '@chess-class/sandbox';

enum CoachesPageActionEnum {
  'invite',
  'edit',
  'view',
  'super-coach',
}

export type CoachesPageActions = {
  action: keyof typeof CoachesPageActionEnum;
  actionId: string;
};

export const coachesPageActions: IPageActions<CoachesPageActions> = {
  action: {
    allowed: (value) => Object.keys(CoachesPageActionEnum).includes(value),
    default: '',
  },
  actionId: {
    default: '',
  },
};
