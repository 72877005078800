import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input } from 'formik-antd';

import { api, IAdminLoginRequest } from '@chess-class/sandbox';
import { notify } from '@chess-class/sandbox/utils';

import { PageMeta } from '~/context/PageMetaContext';

const LoginPage: FC = () => {
  const login = api.user.adminAuth.useAdminLogin();

  return (
    <>
      <PageMeta title="Авторизация" />

      <Formik<IAdminLoginRequest>
        initialValues={api.user.adminAuth.helpers.adminLoginInitial}
        onSubmit={(values) => {
          login.mutateAsync(values).catch(() => {
            notify('error', 'Данные были введены некорректно');
          });
        }}
        validationSchema={api.user.adminAuth.helpers.adminLoginValidation}
      >
        {({ values }) => (
          <Form className="mt-2 sm:mt-8 space-y-8" layout="vertical">
            <h2 className="text-center text-3xl sm:text-4xl text-gray-900 truncate">Авторизация</h2>

            <FormItem label="Почта" name="email">
              <Input name="email" placeholder="example@example.com" suffix />
            </FormItem>

            <FormItem label="Пароль" name="password">
              <Input.Password
                maxLength={30}
                name="password"
                placeholder="example"
                showCount={values.password.length > 19}
              />
            </FormItem>

            <div>
              <Button block htmlType="submit" loading={login.isLoading} size="large" type="primary">
                Войти
              </Button>

              <Link to="/auth/reset">
                <Button block className="mt-3" size="large">
                  Забыл пароль
                </Button>
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default LoginPage;
