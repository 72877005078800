import { Route, Routes } from 'react-router-dom';

import LoginPage from '~mAuth/pages/LoginPage';
import RegisterPage from '~mAuth/pages/RegisterPage';
import ResetEmailPage from '~mAuth/pages/ResetEmailPage';
import ResetPasswordPage from '~mAuth/pages/ResetPasswordPage';

export default function AuthRouter() {
  return (
    <Routes>
      <Route element={<LoginPage />} path="*" />
      <Route element={<ResetEmailPage />} path="reset" />
      <Route element={<ResetPasswordPage />} path="reset/:link" />
      <Route element={<RegisterPage />} path="register/:link" />
    </Routes>
  );
}
