import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';

import { IEnumStudentGender, IStudent } from '@chess-class/sandbox';
import { modelKeys } from '@chess-class/sandbox/consts';

import { StatusTag } from '~/components/atoms/StatusTag';

export const studentColumns = (): ColumnsType<IStudent> => {
  return [
    {
      dataIndex: 'fullName',
      render: (_, student) =>
        student ? `${student.firstName} ${student.lastName} ${student.middleName}` : '-',
      title: 'ФИО',
    },
    {
      dataIndex: 'email',
      title: 'Почта',
    },
    {
      dataIndex: modelKeys.user.student.birthDate,
      render: (birthDate: IStudent['birthDate']) =>
        birthDate ? moment(birthDate).format('DD.MM.YYYY') : '-',
      title: 'Дата рождения',
    },
    {
      dataIndex: modelKeys.user.student.gender,
      render: (gender: IStudent['gender']) => (gender ? IEnumStudentGender[gender] : '-'),
      title: 'Пол',
    },
    {
      dataIndex: modelKeys.user.student.status,
      render: (status: IStudent['status']) => (status ? <StatusTag status={status} /> : '-'),
      title: 'Статус',
    },
    {
      dataIndex: modelKeys.user.student.iin,
      render: (iin: IStudent['iin']) => iin || '-',
      title: 'ИИН',
    },
  ];
};
