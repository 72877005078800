import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgGroupsFillIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg height="24px" viewBox="0 0 384 384" width="24px" {...props}>
    <path
      d="M384,357.8c-.3,.93-.64,1.84-.89,2.78-3.43,13.22-14.97,22.58-28.65,22.61-40.41,.12-80.81,.14-121.22,0-16.41-.06-29.53-13.17-29.59-29.54-.13-40.41-.14-80.81,0-121.22,.06-16.38,13.18-29.47,29.59-29.53,40.28-.13,80.57-.1,120.85-.02,14.26,.03,25.83,9.49,29.17,23.36,.17,.7,.49,1.37,.74,2.05v129.5Zm-81.83-120.93c-2.61,.18-5.24,.21-7.83,.56-24.85,3.38-44.95,23.75-47.96,48.53-3.08,25.37,11.12,49.57,34.68,59.11,20.43,8.27,43.05,3.92,56.5-11.06,1.68-1.87,3.12-4.31,3.69-6.72,1.17-4.95-1.36-9.68-5.65-11.94-4.3-2.26-9.89-1.44-13.38,2.33-3.47,3.75-7.32,6.84-12.36,7.99-12.29,2.81-23.11-.24-31.84-9.24-9.09-9.36-11.73-20.74-8.02-33.19,6.39-21.43,30.65-30.24,50.28-18.52,6.27,3.74,12.98,2.55,16.42-2.92,3.56-5.67,1.64-12.27-4.74-16.26-9.1-5.7-19.03-8.56-29.78-8.66Z"
      fill="currentColor"
    />
    <path
      d="M250.01,0c1.88,.59,3.78,1.12,5.64,1.78,11.53,4.12,19.69,15.05,19.73,27.39,.14,40.66,.18,81.31,0,121.97-.07,16.04-13.15,29.11-29.15,29.18-40.65,.18-81.31,.18-121.97,0-16.14-.07-29.1-13.34-29.14-29.58-.12-40.28-.09-80.56-.01-120.84,.03-14.22,9.53-25.87,23.36-29.16,.7-.17,1.37-.48,2.06-.73,43.17,0,86.33,0,129.5,0Zm-64.63,34.14c-5.73,0-10.28,3.12-12.51,8.95-11.18,29.18-22.32,58.38-33.4,87.6-2.37,6.26,.42,12.57,6.24,14.84,5.98,2.33,12.17-.59,14.78-7.06,1.58-3.92,2.9-7.96,4.64-11.82,.47-1.04,2.01-2.25,3.08-2.26,11.35-.16,22.7-.16,34.05,0,1.08,.02,2.65,1.17,3.11,2.2,1.68,3.74,2.94,7.67,4.44,11.49,2.67,6.79,8.74,9.81,14.9,7.48,6.08-2.3,8.61-8.62,6.05-15.49-4.03-10.85-8.14-21.68-12.23-32.51-6.72-17.82-13.44-35.65-20.18-53.46-2.51-6.63-6.88-9.95-12.97-9.95Z"
      fill="currentColor"
    />
    <path
      d="M90.85,383.25c-19.46,0-38.92,.02-58.38,0-19-.02-31.7-12.69-31.71-31.64-.02-39.04-.02-78.09,0-117.13,0-18.96,12.71-31.61,31.72-31.62,39.04-.02,78.09-.02,117.13,0,18.77,.01,31.51,12.7,31.53,31.44,.03,39.17,.03,78.34,0,117.51-.01,18.7-12.79,31.42-31.54,31.44-19.58,.02-39.17,0-58.75,0Zm-38.5-90.17c0,14.71-.04,29.42,.02,44.13,.03,7.24,4.36,11.68,11.55,11.9,4.23,.13,8.48,.1,12.71,.01,7.97-.16,16.08,.47,23.87-.83,13.95-2.31,23.35-10.92,27.46-24.47,4.12-13.58,.63-25.58-9.34-35.63-1.36-1.37-2.04-2.24-1-4.33,11.17-22.46-3.64-46.59-28.72-46.89-7.98-.09-15.95-.04-23.93,0-8.32,.04-12.6,4.37-12.61,12.73-.02,14.46,0,28.92,0,43.38Z"
      fill="currentColor"
    />
    <path
      d="M174.59,101.55c3.58-9.39,7.01-18.4,10.76-28.23,3.71,9.81,7.13,18.84,10.68,28.23h-21.44Z"
      fill="currentColor"
    />
    <path
      d="M75,326.35v-25.27c7.42,0,14.86-.54,22.19,.17,6.05,.58,10.12,6.79,9.88,13.06-.23,5.86-4.66,11.52-10.38,11.92-7.13,.5-14.33,.12-21.69,.12Z"
      fill="currentColor"
    />
    <path
      d="M74.94,278.32v-18.5c5.9,0,11.72-.44,17.43,.15,4.21,.44,6.84,5.03,6.55,9.56-.29,4.65-3.69,8.06-8.7,8.68-.86,.11-1.74,.1-2.61,.1-4.08,0-8.17,0-12.68,0Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgGroupsFillIcon);
export default Memo;
