import { FC, useEffect } from 'react';
import { Modal, Steps } from 'antd';

import { api } from '@chess-class/sandbox';

import BaseSkeleton from '~/components/atoms/BaseSkeleton';
import { NotFoundResult } from '~/components/atoms/NotFoundResult';

import { StepOne } from '~mMaterials/videos/videos/components/organisms/VideoEditModal/StepOne';
import { StepThree } from '~mMaterials/videos/videos/components/organisms/VideoEditModal/StepThree';
import { StepTwo } from '~mMaterials/videos/videos/components/organisms/VideoEditModal/StepTwo';
import { checkAllowedSteps } from '~mMaterials/videos/videos/utils/checkAllowedSteps';

export const VideoEditModal: FC<{
  id?: string;
  isOpen: boolean;
  onCancel: () => void;
  setStep: (step: number) => void;
  step?: number;
}> = ({ id = '', isOpen, onCancel, setStep, step = 0 }) => {
  const videoQuery = api.content.videos.useVideo({ id });
  const video = videoQuery.data?.data;

  const steps = checkAllowedSteps(video);

  useEffect(() => {
    if (videoQuery && steps[step].disabled) {
      setStep(0);
    }
  }, [steps, step]);

  return (
    <Modal
      className="px-4 md:px-0 w-full md:w-5/6 top-8"
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={
        video?.type == 'LESSON' && (
          <Steps
            className="site-navigation-steps"
            current={step}
            items={[
              { disabled: steps[0].disabled, status: steps[0].status, title: 'Шаг 1' },
              { disabled: steps[1].disabled, status: steps[1].status, title: 'Шаг 2' },
              { disabled: steps[2].disabled, status: steps[2].status, title: 'Шаг 3' },
            ]}
            onChange={setStep}
            type="navigation"
          />
        )
      }
    >
      {videoQuery.isFetching ? (
        <BaseSkeleton />
      ) : !video ? (
        <NotFoundResult title="Видео не найдено" />
      ) : step == 0 ? (
        <StepOne changeStep={setStep} onCancel={onCancel} steps={steps} video={video} />
      ) : step == 1 ? (
        <StepTwo changeStep={setStep} onCancel={onCancel} steps={steps} video={video} />
      ) : (
        <StepThree changeStep={setStep} onCancel={onCancel} video={video} />
      )}
    </Modal>
  );
};
