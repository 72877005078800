import { IFilters } from '@chess-class/sandbox';
import { modelKeys, paginationBaseFilters } from '@chess-class/sandbox/consts';

import { LocationSelectMultipleFilter } from '~/components/molecules/filters/dynamic/LocationSelectMultipleFilter';
import { SchoolCoachesSelectMultiple } from '~/components/molecules/filters/dynamic/SchoolCoachesSelectMultipleFilter';
import { SchoolsSelectMultipleFilter } from '~/components/molecules/filters/dynamic/SchoolsSelectMultipleFilter';
import { CheckboxFilter } from '~/components/molecules/filters/static/CheckboxFilter';
import { InputFilter } from '~/components/molecules/filters/static/InputFilter';

export const groupFilters: IFilters = {
  ...paginationBaseFilters,
  [modelKeys.school.group.name]: {
    input: <InputFilter placeholder="Введите имя" />,
    queries: [{ key: 'name' }],
    sort: true,
  },
  [modelKeys.school.group.school]: {
    input: <SchoolsSelectMultipleFilter />,
    queries: [{ key: 'schoolIds' }],
    sort: true,
  },
  [modelKeys.school.group.coachId]: {
    input: <SchoolCoachesSelectMultiple placeholder="Выберите тренера" />,
    queries: [{ key: 'coachIds' }],
  },
  cityIds: {
    input: <LocationSelectMultipleFilter placeholder="Выберите город" type="isLocality" />,
    queries: [{ key: 'locationIds' }],
  },
  grade: {
    input: (
      <CheckboxFilter
        options={[1, 2, 3, 4].map((grade) => ({
          label: grade,
          value: grade,
        }))}
      />
    ),
    queries: [{ key: 'grades' }],
    sort: true,
  },
  sort: { queries: [{ default: '-createdDate' }] },
  status: { sort: true },
};
