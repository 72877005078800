import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { App as AntdApp, ConfigProvider } from 'antd';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { mantineTheme } from '@chess-class/sandbox/consts';
import { consoleErrorConfig, sandboxAxiosConfig } from '@chess-class/sandbox/utils';

import { PageMetaContextProvider } from '~/context/PageMetaContext';

import App from './App';
import { EducationFormatProvider } from './context/EducationFormContext';

import './assets/index.scss';
import 'antd/dist/reset.css';

sandboxAxiosConfig({ type: 'admin', url: process.env.REACT_APP_API_URL });

if (process.env.NODE_ENV === 'development') {
  consoleErrorConfig();
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

const root = document.getElementById('root');
if (root) {
  createRoot(root).render(
    <ConfigProvider
      theme={{
        token: { colorPrimary: '#4444EF', fontFamily: 'Inter, sans-serif', zIndexPopupBase: 400 },
      }}
    >
      <MantineProvider theme={mantineTheme} withGlobalStyles withNormalizeCSS>
        <Notifications position="top-right" zIndex={1001} />

        <ModalsProvider>
          <QueryClientProvider client={queryClient}>
            <HelmetProvider>
              <PageMetaContextProvider>
                <EducationFormatProvider>
                  <BrowserRouter>
                    <AntdApp className="h-full w-full">
                      <App />
                    </AntdApp>
                  </BrowserRouter>
                </EducationFormatProvider>
              </PageMetaContextProvider>
            </HelmetProvider>
            <ReactQueryDevtools initialIsOpen={false} panelProps={{ className: 'min-h-10' }} />
          </QueryClientProvider>
        </ModalsProvider>
      </MantineProvider>
    </ConfigProvider>,
  );
}
