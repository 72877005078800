import { FC } from 'react';
import { Modal, ModalProps, Skeleton } from 'antd';
import tw from 'twin.macro';
import styled from '@emotion/styled';

import { api, filteredLocalizations, IEnumLocalizationGenitive } from '@chess-class/sandbox';

import { CountryImageText } from '~/components/atoms/CountryImageText';
import { NotFoundResult } from '~/components/atoms/NotFoundResult';
import { useEducationFormat } from '~/context/EducationFormContext';

type AuthorViewModalType = {
  id?: string;
  isOpen: boolean;
  onCancel: () => void;
};

const AuthorModal = styled(Modal)<ModalProps>`
  .ant-modal-header {
    ${tw`flex justify-end pr-[32px]`}
  }
`;

export const AuthorViewModal: FC<AuthorViewModalType> = ({ id = '', isOpen, onCancel }) => {
  const authorQuery = api.content.authors.useAuthor({ id });
  const author = authorQuery.data?.data;
  const { defaultEducationFormat } = useEducationFormat();

  return (
    <AuthorModal footer={null} onCancel={onCancel} open={isOpen}>
      {authorQuery.isLoading ? (
        <Skeleton active round />
      ) : !author ? (
        <NotFoundResult title="Автор не найден" />
      ) : (
        <div className="space-y-7">
          <div>
            <p className="mb-0 text-ant-gray-7">Звание автора</p>
            <p className="font-semibold text-lg text-ant-red-7">{author.rank}</p>
          </div>
          {filteredLocalizations({
            defaultEducationFormat: defaultEducationFormat ?? 'GENERAL',
            isDev:
              process.env.REACT_APP_API_URL.includes('api.dev') ||
              process.env.NODE_ENV == 'development',
          }).map((locale) => (
            <div className="text-ant-gray-7" key={`author-name-${locale}`}>
              <CountryImageText
                src={locale}
                text={`Имя автора на ${IEnumLocalizationGenitive[locale]}`}
              />
              <p className="font-semibold text-lg text-ant-gray-11">
                {author.translations[locale]?.name}
              </p>
            </div>
          ))}
        </div>
      )}
    </AuthorModal>
  );
};
