import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from '~/components/molecules/routes/ProtectedRoute';
import NotFoundPage from '~/components/pages/errors/NotFoundPage';

import { ChapterDetailsPage } from '~mMaterials/chapters/components/pages/ChapterDetailsPage';
import { ChaptersPage } from '~mMaterials/chapters/components/pages/ChaptersPage';
import ExercisesPage from '~mMaterials/exercises/exercises/components/pages/ExercisesPage';
import { ExerciseGroupsPage } from '~mMaterials/exercises/exercisesGroups/components/pages/ExerciseGroupsPage';
import { GuidelinesPage } from '~mMaterials/guidelines/components/pages/GuidelinesPage';
import { StudioCreatePage } from '~mMaterials/studios/components/pages/StudioCreatePage';
import { VideosPage } from '~mMaterials/videos/videos/components/pages/VideosPage';
import AuthorsPage from '~mMaterials/videos/videos-authors/components/pages/AuthorsPage';
import { VideoFilesPage } from '~mMaterials/videos/videos-files/components/pages/VideoFilesPage';

export const MaterialRouter = () => {
  return (
    <ProtectedRoute access="materials">
      <Routes>
        <Route element={<ExercisesPage />} path="/exercises" />
        <Route element={<ExerciseGroupsPage />} path="/exercise-groups" />

        <Route element={<VideosPage />} path="/videos" />
        <Route element={<AuthorsPage />} path="/video-authors" />
        <Route element={<VideoFilesPage />} path="/video-files" />

        <Route element={<ChaptersPage />} path="/chapters" />
        <Route element={<ChapterDetailsPage />} path="/chapters/:id" />

        <Route element={<GuidelinesPage />} path="/guidelines" />

        <Route element={<StudioCreatePage />} path="/studios" />

        <Route element={<NotFoundPage />} path="*" />
      </Routes>
    </ProtectedRoute>
  );
};
