import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';

import { IEnumLocalization, IReview } from '@chess-class/sandbox';
import { modelKeys } from '@chess-class/sandbox/consts';

export const reviewsColumns = (): ColumnsType<IReview> => {
  const columns: ColumnsType<IReview> = [
    {
      dataIndex: modelKeys.reviews.adminReviews.userFullName,
      title: 'ФИО',
    },
    {
      dataIndex: modelKeys.reviews.adminReviews.schoolName,
      title: 'Школа',
    },
    {
      dataIndex: modelKeys.reviews.adminReviews.educationFormat,
      render: (educationFormat: IReview['educationFormat']) =>
        educationFormat == 'GENERAL' ? 'Гос' : 'Частная',
      title: 'Тип школы',
    },
    {
      dataIndex: modelKeys.reviews.adminReviews.userRole,
      title: 'Роль',
    },
    {
      dataIndex: modelKeys.reviews.adminReviews.createdAt,
      render: (createdAt: IReview['createdAt']) => moment(createdAt).format('YYYY-MM-DD hh:mm'),
      title: 'Дата',
    },
    {
      dataIndex: modelKeys.reviews.adminReviews.value,
      title: 'Оценка',
    },
    {
      dataIndex: modelKeys.reviews.adminReviews.text,
      title: 'Отзыв',
    },
    {
      dataIndex: modelKeys.reviews.adminReviews.contentType,
      title: 'Тип контента',
    },
    {
      dataIndex: modelKeys.reviews.adminReviews.contentId,
      title: 'ID контента',
    },
    {
      dataIndex: modelKeys.reviews.adminReviews.contentLanguage,
      render: (lan: IReview['contentLanguage']) => IEnumLocalization[lan],
      title: 'Язык контента',
    },
  ];
  return [...columns];
};
