import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgChessKnightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="currentColor" height="24px" viewBox="0 0 287.5 383.23" width="24px" {...props}>
    <path
      d="M33,383.23c-2.51-2.1-5.54-3.83-7.41-6.41-2.62-3.61-1.89-7.8.07-11.72q11.55-23,23.06-46.11c2.6-5.25,6.71-7.66,12.57-7.65q54.81.09,109.61,0c26.18,0,52.37.09,78.56-.06,6.68,0,11,2.7,13.87,8.65,7,14.33,14.2,28.53,21.31,42.79,5,10.07,3.54,15.06-6.12,20.48Z"
      fill="currentColor"
    />
    <path
      d="M134.37,145.68c-3.75,2.63-7.38,5.45-11.29,7.83-9.68,5.88-20.17,7.87-31.25,5.18-10.7-2.6-20.3-.76-29.59,5.21a67,67,0,0,1-17.48,8.18c-9.34,2.65-17.92-.27-24.53-7.32C14.35,158.49,8.87,151.81,3.59,145-2.27,137.48-.83,127.69,7,121.45c15.19-12.15,30.52-24.14,45.78-36.2C69.27,72.17,85.76,59,102.34,46c13.06-10.26,19.55-23.25,17.47-40.11C119.14.48,121.06-1,126.3.61c11.33,3.44,20.26,9.85,25.79,20.64,6.5,12.72,17.15,18.93,31.31,18.31,17.1-.75,30.33,6.18,41.12,19C238.36,74.91,246,94.18,251.6,114.58c9,32.76,11.48,66.25,11.7,100,.17,24.07,0,48.15,0,72.52H53.27c-.06-.07-.36-.26-.36-.44,0-13.21-.18-26.43.25-39.63.25-7.66,4-14.06,9.6-19.27,7.78-7.2,16.12-13.89,23.26-21.67,9.85-10.72,18.93-22.15,28.1-33.47,7.06-8.71,13.75-17.71,20.61-26.58Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgChessKnightIcon);
export default Memo;
