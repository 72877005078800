import { FC, useState } from 'react';
import { Modal, Tag } from 'antd';
import { Formik } from 'formik';
import { QuestionOutlined } from '@ant-design/icons';

import { api, IExerciseTestCreateRequest } from '@chess-class/sandbox';
import { notify } from '@chess-class/sandbox/utils';

import { useEducationFormat } from '~/context/EducationFormContext';

import TestForm from '~mMaterials/exercises/exercises/components/molecules/TestForm';

export const ExerciseTestCreateModal: FC<{
  isOpen: boolean;
  onCancel: () => void;
}> = ({ isOpen, onCancel }) => {
  const testCreate = api.content.exercises.useExerciseTestCreate();
  const [imageKey, setImageKey] = useState('image');
  const { defaultEducationFormat } = useEducationFormat();

  return (
    <Modal
      className="px-4 md:px-0 md:w-4/5 lg:w-3/4 top-4"
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={
        <h1 className="text-xl space-x-1 mb-8">
          <QuestionOutlined className="text-ant-orange-6" />
          <span>Создать тест</span>
          <Tag color="processing">
            {defaultEducationFormat == 'GENERAL' ? 'Гос. школа' : 'Частная школа'}
          </Tag>
        </h1>
      }
    >
      <Formik<IExerciseTestCreateRequest>
        enableReinitialize
        initialValues={api.content.exercises.helpers.exerciseTestCreateInitial}
        onSubmit={(values, { resetForm }) => {
          testCreate
            .mutateAsync({ ...values, educationFormat: defaultEducationFormat })
            .then((data) => {
              if (data.status == 201 || data.status == 200) {
                notify('success', 'Тест создан');
                onCancel();
                resetForm();
                setImageKey(imageKey + 1);
              }
            });
        }}
        // validationSchema={api.content.exercises.helpers.exerciseTestCreateValidation}
      >
        <TestForm isLoading={testCreate.isLoading} onCancel={onCancel} />
      </Formik>
    </Modal>
  );
};
