import React, { FC } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { api, IExercisesRequest } from '@chess-class/sandbox';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';

import { AdminTable } from '~/components/organisms/adminTable/AdminTable';
import { useEducationFormat } from '~/context/EducationFormContext';
import { PageMeta } from '~/context/PageMetaContext';
import { checkEditPermission } from '~/utils/checkEditPermission';

import { ExerciseCustomProblemCreateModal } from '../organisms/ExerciseCustomProblemCreateModal';

import { ExerciseEditModal } from '~mMaterials/exercises/exercises/components/organisms/ExerciseEditModal';
import { ExerciseProblemCreateModal } from '~mMaterials/exercises/exercises/components/organisms/ExerciseProblemCreateModal';
import { ExerciseTestCreateModal } from '~mMaterials/exercises/exercises/components/organisms/ExerciseTestCreateModal';
import { ExerciseViewModal } from '~mMaterials/exercises/exercises/components/organisms/ExerciseViewModal';
import { exerciseColumns } from '~mMaterials/exercises/exercises/consts/columns/exerciseColumns';
import { exerciseFilters } from '~mMaterials/exercises/exercises/consts/filters/exerciseFilters';
import {
  ExercisesPageActions,
  exercisesPageActions,
} from '~mMaterials/exercises/exercises/consts/pageActions/ExercisesPageActions';
import { ExerciseTabs } from '~mMaterials/exercises/ExerciseTabs';

const ExercisesPage: FC = () => {
  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    IExercisesRequest,
    ExercisesPageActions
  >(exerciseFilters, exercisesPageActions);
  const { defaultEducationFormat } = useEducationFormat();
  const exercises = api.content.exercises.useExercises({
    ...filtered,
    educationFormat: defaultEducationFormat,
  });
  const exerciseTestPause = api.content.exercises.useExerciseTestStatusUpdate();
  const exerciseProblemPause = api.content.exercises.useExerciseProblemStatusUpdate();
  const exerciseDelete = api.content.exercises.useExerciseDelete();

  const canEditExercises = checkEditPermission('materials');

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => setActioned({ action: 'createTest' })}>
        Создать тест
      </Menu.Item>
      <Menu.Item key="2" onClick={() => setActioned({ action: 'createCustomGame' })}>
        Создать Кастомное упражнение
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="flex flex-col gap-4">
      <ExerciseTabs active="exercises" />
      <PageMeta
        openMenuKeys={['materials']}
        selectedMenuKeys={['materials-exercises']}
        subTitle={
          actioned.action == 'createTest'
            ? 'Создать тест'
            : actioned.action == 'createProblem'
              ? 'Загрузить PGN'
              : actioned.action == 'view'
                ? `Просмотр задачи #${actioned.actionId}`
                : actioned.action == 'edit'
                  ? `Изменить задачу #${actioned.actionId}`
                  : ''
        }
        title="Задачи"
      />
      {canEditExercises && (
        <div className="flex justify-end gap-3">
          <Dropdown overlay={menu} trigger={['click']}>
            <Button className="sm:px-12" size="large">
              Создать <DownOutlined />
            </Button>
          </Dropdown>
          <Button
            className="sm:px-12"
            onClick={() => setActioned({ action: 'createProblem' })}
            size="large"
            type="primary"
          >
            Загрузить PGN
          </Button>
        </div>
      )}

      <AdminTable
        columns={exerciseColumns({
          canEdit: canEditExercises,
          onClickDelete: exerciseDelete.mutateAsync,
          onClickEdit: ({ id }) => setActioned({ action: 'edit', actionId: id }),
          onClickProblemPause: exerciseProblemPause.mutateAsync,
          onClickTestPause: exerciseTestPause.mutateAsync,
          onClickView: ({ id }) => setActioned({ action: 'view', actionId: id }),
        })}
        emptyDescription={
          <div>
            <p>Данные отсутствуют</p>
            <p>Вы можете создать задачу, нажав на кнопку “Загрузить PGN” или “Создать тест”</p>
          </div>
        }
        query={exercises}
        tableFilter={{ filtered, filters: exerciseFilters, onFilter }}
      />

      <ExerciseTestCreateModal isOpen={actioned.action == 'createTest'} onCancel={clearParams} />

      <ExerciseProblemCreateModal
        isOpen={actioned.action == 'createProblem'}
        onCancel={clearParams}
      />

      <ExerciseViewModal
        id={actioned.actionId}
        isOpen={actioned.action == 'view'}
        onCancel={clearParams}
        openEdit={() => setActioned({ action: 'edit', actionId: actioned.actionId })}
      />

      <ExerciseEditModal
        id={actioned.actionId}
        isOpen={actioned.action == 'edit'}
        onCancel={clearParams}
        openView={() => setActioned({ action: 'view', actionId: actioned.actionId })}
      />
      <ExerciseCustomProblemCreateModal
        isOpen={actioned.action == 'createCustomGame'}
        onCancel={clearParams}
      />
    </div>
  );
};

export default ExercisesPage;
