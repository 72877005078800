import { useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';

enum IEnumSchoolsTabsType {
  schools = 'Школы',
  // eslint-disable-next-line typescript-sort-keys/string-enum
  coaches = 'Тренеры',
}

type IEnumSchoolsTabsTypeKeys = keyof typeof IEnumSchoolsTabsType;

export default function SchoolsTabs({ active }: { active: IEnumSchoolsTabsTypeKeys }) {
  const navigate = useNavigate();

  return (
    <Tabs
      activeKey={active}
      centered
      items={Object.entries(IEnumSchoolsTabsType).map(([key, label]) => ({
        key,
        label,
      }))}
      onChange={(tab: IEnumSchoolsTabsTypeKeys | string) =>
        navigate(tab == 'schools' ? '/schools/' : `/schools/coaches`)
      }
    />
  );
}
