import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';

import { ICoachToSchool, IGroup } from '@chess-class/sandbox';
import { modelKeys } from '@chess-class/sandbox/consts';

import SchoolCoachName from '~/components/atoms/dynamicTitles/SchoolCoachName';

import { extractLocation } from '~mSchools/utils/extractLocation';

export const groupColumns = ({
  canEdit,
}: {
  canEdit: boolean;
  schoolCoaches?: ICoachToSchool[];
}): ColumnsType<IGroup> => {
  const columns: ColumnsType<IGroup> = [
    {
      dataIndex: modelKeys.school.group.school,
      render: (school: IGroup['school']) => school?.name || '-',
      title: 'Школа',
    },
    {
      dataIndex: modelKeys.school.group.grade,
      render: (_, { grade, id, symbol }) => {
        return <Link to={`/classes/${id}`}>{`${grade}${symbol}`}</Link>;
      },
      title: 'Класс',
    },
    {
      dataIndex: modelKeys.school.group.studentsCount,
      title: 'Количество учеников',
    },
    {
      dataIndex: modelKeys.school.group.coachId,
      render: (coachId: IGroup['coachId']) => <SchoolCoachName id={coachId} />,
      title: 'Тренер',
    },
    {
      dataIndex: 'cityIds',
      render: (_, { school }) =>
        school?.locationText || extractLocation(school?.location, 'isLocality')?.nameRu || '-',
      title: 'Город',
    },
  ];

  if (!canEdit) {
    return columns;
  }

  return [...columns];
};
