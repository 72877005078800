import { IEnumVideoStatus, IFilters } from '@chess-class/sandbox';
import { modelKeys, paginationBaseFilters } from '@chess-class/sandbox/consts';

import { TagsSelectMultiple } from '~/components/molecules/filters/dynamic/TagsSelectMultipleFilter';
import { CheckboxFilter } from '~/components/molecules/filters/static/CheckboxFilter';
import { InputFilter } from '~/components/molecules/filters/static/InputFilter';
import { RadioFilter } from '~/components/molecules/filters/static/RadioFilter';

import { userPlayLevels } from '~mMaterials/exercises/exercises/types/UserPlayLevel';

export const videosFilters: IFilters = {
  ...paginationBaseFilters,
  author: {
    input: <TagsSelectMultiple placeholder="Выберите автора" />,
    queries: [{ key: 'authorIds' }],
    sort: true,
  },
  [modelKeys.content.video.level]: {
    input: (
      <CheckboxFilter
        options={Object.entries(userPlayLevels).map(([value, label]) => ({ label, value }))}
      />
    ),
    queries: [{ key: 'level' }],
    sort: true,
  },
  [modelKeys.content.video.translations]: {
    input: <InputFilter placeholder="Введите название" />,
    queries: [{ key: 'title' }],
    sort: true,
  },
  [modelKeys.content.video.type]: {
    input: (
      <RadioFilter
        options={[
          { label: 'Мультфильм', value: 'CARTOON' },
          { label: 'Видеоурок', value: 'LESSON' },
        ]}
      />
    ),
  },

  [modelKeys.content.video.status]: {
    input: (
      <CheckboxFilter
        options={Object.entries(IEnumVideoStatus).map(([value, label]) => ({
          label,
          value,
        }))}
      />
    ),
    sort: true,
  },
};
