import { FC, MouseEvent as ReactMouseEvent, ReactElement, ReactNode, useState } from 'react';
import { Button, CheckboxOptionType, Empty, Select } from 'antd';
import { BaseSelectRef } from 'rc-select';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';

import { FilterRender } from '@chess-class/sandbox';

interface SelectMultipleInterface {
  afterCallback?: () => void;
  defaultSearchValue?: string;
  filterOption?: ((s: string, o: CheckboxOptionType | undefined) => boolean) | boolean;
  filterRender?: FilterRender;
  loading?: boolean;
  onSearch?: (searchValue: string) => void;
  options: CheckboxOptionType[];
  placeholder?: string;
  tagRender?: (props: {
    closable: boolean;
    disabled: boolean;
    label: ReactNode;
    onClose: (event?: ReactMouseEvent<HTMLElement, MouseEvent>) => void;
    value: string;
  }) => ReactElement;
}

export const SelectMultipleFilter: FC<SelectMultipleInterface> = ({
  afterCallback,
  defaultSearchValue,
  filterOption,
  filterRender,
  loading,
  onSearch,
  options,
  placeholder,
  tagRender,
}) => {
  if (!filterRender) {
    return null;
  }

  const { dataIndex, filtered, onFilter, queries } = filterRender;

  const key = queries?.[0]?.key || dataIndex;
  const [value, setValue] = useState(filtered[key]);
  const [searchValue, setSearchValue] = useState(defaultSearchValue);
  const onSelectSubmit = (clear = false) => {
    onFilter({ [key]: clear ? undefined : value });
    afterCallback?.();
  };
  let refSelect: BaseSelectRef | null = null;

  return (
    <div className="p-2 space-y-3 bg-white shadow-filter max-w-tiny">
      <Select
        autoClearSearchValue
        autoFocus
        className="w-full max-w-xs filter-select"
        filterOption={filterOption}
        loading={loading}
        mode="multiple"
        notFoundContent={
          loading ? (
            <div className="text-center text-2xl text-ant-blue-5">
              <LoadingOutlined />
            </div>
          ) : (
            <Empty description={'Не найдено'} image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )
        }
        onChange={(val) => {
          setValue(val);
          refSelect?.blur();
        }}
        onSearch={(newSearchValue) => {
          setSearchValue(newSearchValue);
          if (onSearch) {
            onSearch(newSearchValue);
          }
        }}
        optionFilterProp="label"
        options={options}
        placeholder={placeholder}
        ref={(select) => (refSelect = select)}
        searchValue={searchValue}
        tagRender={tagRender}
        value={value}
      />
      <div className="grid gap-1 grid-cols-2">
        <Button
          block
          disabled={!value || value == filtered[key]}
          icon={<SearchOutlined />}
          onClick={() => onSelectSubmit()}
          size="small"
          type="primary"
        >
          Поиск
        </Button>
        <Button block className="w-full" onClick={() => onSelectSubmit(true)} size="small">
          Очистить
        </Button>
      </div>
    </div>
  );
};
