import { FC } from 'react';
import { Button } from 'antd';

import { api, ISchoolsRequest } from '@chess-class/sandbox';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';

import { AdminTable } from '~/components/organisms/adminTable/AdminTable';
import { useEducationFormat } from '~/context/EducationFormContext';
import { PageMeta } from '~/context/PageMetaContext';

import { schoolColumns } from '../../consts/columns/schoolColumns';
import { schoolFilters } from '../../consts/filters/schoolFilters';
import { SchoolPageActions, schoolPageActions } from '../../consts/pageActions/SchoolPageActions';
import { SchoolCreateModal } from '../organisms/SchoolCreateModal';
import { SchoolEditModal } from '../organisms/SchoolEditModal';
import { SchoolViewModal } from '../organisms/SchoolViewModal';

import SchoolsTabs from '~mSchools/SchoolsTabs';

export const SchoolsPage: FC = () => {
  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    ISchoolsRequest & { cityIds?: string; districtIds?: string; regionIds?: string },
    SchoolPageActions
  >(schoolFilters, schoolPageActions);
  const { defaultEducationFormat } = useEducationFormat();
  const { cityIds, districtIds, regionIds, ...outputFiltered } = filtered;

  const schools = api.schools.schools.useSchools({
    locationIds: [
      ...(typeof cityIds === 'string' ? [cityIds] : (cityIds ?? [])),
      ...(typeof districtIds === 'string' ? [districtIds] : (districtIds ?? [])),
      ...(typeof regionIds === 'string' ? [regionIds] : (regionIds ?? [])),
    ],
    ...outputFiltered,
    educationFormat: defaultEducationFormat,
  });

  const schoolStatusUpdate = api.schools.schools.useSchoolStatusUpdate();
  const schoolDelete = api.schools.schools.useSchoolDelete();

  return (
    <div className="flex flex-col gap-4">
      <SchoolsTabs active="schools" />
      <PageMeta selectedMenuKeys={['schools']} title="Список школ" />

      <div className="flex justify-end gap-4">
        <Button
          className="sm:px-12"
          onClick={() => setActioned({ action: 'create' })}
          size="large"
          type="primary"
        >
          Добавить школу
        </Button>
      </div>

      <AdminTable
        columns={schoolColumns({
          canEdit: true,
          onClickDelete: schoolDelete.mutateAsync,
          onClickEdit: ({ id }) => setActioned({ action: 'edit', actionId: id }),
          onClickPause: schoolStatusUpdate.mutateAsync,
          onClickView: ({ id }) => setActioned({ action: 'view', actionId: id }),
        })}
        emptyDescription={
          <div>
            <p>Данные отсутствуют</p>
          </div>
        }
        query={schools}
        tableFilter={{ filtered, filters: schoolFilters, onFilter }}
      />

      <SchoolViewModal
        id={actioned.actionId}
        isOpen={actioned.action === 'view'}
        onCancel={clearParams}
      />

      <SchoolCreateModal isOpen={actioned.action === 'create'} onCancel={clearParams} />

      <SchoolEditModal
        id={actioned.actionId}
        isOpen={actioned.action === 'edit'}
        onCancel={clearParams}
      />
    </div>
  );
};
