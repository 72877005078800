import { Tooltip } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

export default function TitleInfoTooltip({ title, tooltip }: { title: string; tooltip: string }) {
  return (
    <span className="flex gap-2 items-center">
      {title}
      <Tooltip title={tooltip}>
        <InfoCircleFilled className="text-gray-400" />
      </Tooltip>
    </span>
  );
}
