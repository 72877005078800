import { FC } from 'react';
import { Button, Modal, Result } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input } from 'formik-antd';
import { SearchOutlined } from '@ant-design/icons';

import { api, IAdminAccessesEditRequest } from '@chess-class/sandbox';

import BaseSkeleton from '~/components/atoms/BaseSkeleton';

import { StaffAuthoritySelects } from '~mStaff/components/molecules/StaffAuthoritySelects';

type IStaffEditModal = {
  id?: string;
  isOpen: boolean;
  onCancel: () => void;
};

export const StaffEditModal: FC<IStaffEditModal> = ({ id = '', isOpen, onCancel }) => {
  const staffEdit = api.user.admin.useAdminAccessesEdit();

  const staffQuery = api.user.admin.useAdmin({ id });
  const staff = staffQuery.data?.data;

  return (
    <Modal
      className="px-4 md:px-0 w-full md:w-3/4 lg:w-2/3 top-8"
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={<h1 className="text-xl m-0">Профиль персонала</h1>}
    >
      {staffQuery.isLoading ? (
        <BaseSkeleton />
      ) : !staff?.id ? (
        <Result icon={<SearchOutlined />} title="Персонал не найден" />
      ) : (
        <Formik<IAdminAccessesEditRequest>
          enableReinitialize
          initialValues={{
            id,
            ...staff.accesses,
          }}
          onSubmit={(values) => staffEdit.mutateAsync(values).then(onCancel)}
          validationSchema={api.user.admin.helpers.staffEditValidation}
        >
          {() => {
            return (
              <Form layout="vertical">
                <div className="grid grid-cols-3 gap-2">
                  <FormItem label="Фамилия" name="lastName">
                    <Input disabled name="lastName" placeholder="Фамилия" value={staff.lastName} />
                  </FormItem>
                  <FormItem label="Имя" name="firstName">
                    <Input disabled name="firstName" placeholder="Имя" value={staff.firstName} />
                  </FormItem>
                  <FormItem label="Отчество" name="middleName">
                    <Input
                      disabled
                      name="middleName"
                      placeholder="Отчество"
                      value={staff.middleName}
                    />
                  </FormItem>
                </div>
                <FormItem label="Почта" name="email">
                  <Input
                    disabled
                    name="email"
                    placeholder="example@example.com"
                    type="email"
                    value={staff.email}
                  />
                </FormItem>

                <StaffAuthoritySelects />

                <div className="grid md:grid-cols-2 gap-5">
                  <Button block onClick={onCancel}>
                    Отменить
                  </Button>

                  <Button htmlType="submit" loading={staffEdit.isLoading} type="primary">
                    Сохранить изменения
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </Modal>
  );
};
