import { type FC, useMemo } from 'react';
import { Card, Divider, Modal } from 'antd';

import { api, IEnumSchoolGradeType } from '@chess-class/sandbox';

import BaseSkeleton from '~/components/atoms/BaseSkeleton';
import SchoolStudentsCount from '~/components/atoms/dynamicTitles/SchoolStudentsCount';
import { NotFoundResult } from '~/components/atoms/NotFoundResult';
import { StatusTag } from '~/components/atoms/StatusTag';

import { extractLocation } from '../../utils/extractLocation';

type SchoolViewModalProps = {
  id?: string;
  isOpen: boolean;
  onCancel: () => void;
};

export const SchoolViewModal: FC<SchoolViewModalProps> = ({ id = '', isOpen, onCancel }) => {
  const schoolQuery = api.schools.schools.useSchool({ id });
  const school = schoolQuery.data?.data;

  const coachListQuery = api.user.coachToSchool.useCoachesToSchool({
    schoolIds: id ? [id] : [],
  });

  const coachList = coachListQuery.data?.data.content;

  const superCoach = useMemo(() => {
    return coachList?.find((coach) => coach.isSuperCoach);
  }, [coachList]);

  const subCoaches = useMemo(() => {
    return coachList?.filter((coach) => !coach.isSuperCoach);
  }, [coachList]);

  return (
    <Modal className="top-8" footer={null} onCancel={onCancel} open={isOpen} width={1000}>
      {schoolQuery.isFetching ? (
        <BaseSkeleton />
      ) : !school ? (
        <NotFoundResult title="Школа не найдена" />
      ) : (
        <>
          <div>
            <span className="text-xl font-semibold block mb-4">Школа «{school.name}»</span>

            <div className="flex flex-col space-y-3 text-base">
              <div className="flex gap-2">
                <span>Область:</span>
                <strong>{extractLocation(school.location, 'isRegion')?.nameRu ?? '-'}</strong>
              </div>
              <div className="flex gap-2">
                <span>Город:</span>
                <strong>{extractLocation(school.location, 'isLocality')?.nameRu ?? '-'}</strong>
              </div>
              <div className="flex gap-2">
                <span>Адрес:</span>
                <strong>{school.address}</strong>
              </div>
              <div className="flex gap-2">
                <span>Email:</span>
                <strong>{school.email}</strong>
              </div>
              <div className="flex gap-2">
                <span>Статус: </span>
                <StatusTag status={school.status} />
              </div>
            </div>
          </div>

          <Divider />

          <div>
            <div className="flex flex-col space-y-3 text-base">
              <span>
                Количество классов: <strong>{school.numberOfTargetClasses}</strong>
              </span>
              <span>
                Планируемое количество учеников: <strong>{school.amountOfStudents ?? '-'}</strong>
              </span>
              <span>
                Фактическое количество учеников:{' '}
                <strong>
                  <SchoolStudentsCount schoolId={school.id} />
                </strong>
              </span>
              <span>
                Количество активных учеников: <strong>{school.activeStudents}</strong>
              </span>
              <span>
                Шкала оценки:{' '}
                <strong>{school.gradeType ? IEnumSchoolGradeType[school.gradeType] : '-'}</strong>
              </span>
            </div>
          </div>

          <Divider />

          <div>
            <span className="text-xl font-semibold mb-4 block">Супер-тренер: </span>
            <div className="grid grid-cols-1">
              <Card className="bg-ant-blue-1 flex">
                <div className="flex flex-col">
                  <span>
                    ФИО:{' '}
                    <strong>
                      {superCoach?.firstName} {superCoach?.lastName} {superCoach?.middleName}
                    </strong>
                  </span>
                  <span>
                    Email: <strong>{superCoach?.email}</strong>
                  </span>
                  <span>
                    Номер телефона:<strong> {superCoach?.phone}</strong>
                  </span>
                </div>
              </Card>
            </div>
          </div>

          <Divider />

          {subCoaches && subCoaches?.length > 0 && (
            <div>
              <span className="text-xl font-semibold mb-4 block">Тренеры: </span>
              <div className="grid grid-cols-2 gap-4">
                {subCoaches?.map((subCoach) => (
                  <Card className="bg-ant-blue-1 flex" key={subCoach.coachUserId}>
                    <div className="flex flex-col font-semibold">
                      <span>
                        {subCoach?.firstName} {subCoach?.lastName} {subCoach?.middleName}
                      </span>
                      <span>{subCoach?.email}</span>
                      <span>{subCoach?.phone}</span>
                    </div>
                  </Card>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </Modal>
  );
};
