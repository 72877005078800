import { Result, Skeleton } from 'antd';

import { api, IEnumAdminPermissionKeys } from '@chess-class/sandbox';

import { checkPermission } from '~/utils/checkPermission';

const ProtectedRoute = ({
  access,
  children,
}: {
  access: IEnumAdminPermissionKeys;
  children: JSX.Element;
}) => {
  const { data, isFetching, isSuccess } = api.user.adminAuth.useAdminMe();

  if (isSuccess) {
    if (!checkPermission(access, data?.accesses)) {
      return (
        <Result status="403" subTitle="У вас нет прав для просмотра этой страницы" title="403" />
      );
    }

    return children;
  }

  if (isFetching) {
    return (
      <div className="bg-white p-10 md:p-15 mt-5 rounded-2xl">
        <Skeleton active round />
        <Skeleton active round />
        <Skeleton active round />
      </div>
    );
  }

  return <div />;
};

export default ProtectedRoute;
