import { Link } from 'react-router-dom';
import { Button, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  CaretRightOutlined,
  DeleteOutlined,
  PauseCircleOutlined,
  PauseOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';

import { IChapter, IEnumExerciseStatusKeys } from '@chess-class/sandbox';
import { modelKeys } from '@chess-class/sandbox/consts';

import { PencilIcon } from '~/components/atoms/icons';
import { StatusTag } from '~/components/atoms/StatusTag';

import { userPlayLevels } from '~mMaterials/exercises/exercises/types/UserPlayLevel';

export const chaptersColumns = ({
  canEdit,
  onClickDelete,
  onClickEdit,
  onClickPause,
}: {
  canEdit: boolean;
  onClickDelete?: ({ id }: { id: string }) => void;
  onClickEdit?: ({ id }: { id: string }) => void;
  onClickPause?: ({ id, status }: { id: string; status: IEnumExerciseStatusKeys }) => void;
}): ColumnsType<IChapter> => {
  const columns: ColumnsType<IChapter> = [
    {
      dataIndex: modelKeys.content.chapter.id,
      render: (id: IChapter['id']) => <Link to={`/materials/chapters/${id}`}>{id}</Link>,
      title: 'ID',
    },
    {
      dataIndex: modelKeys.content.chapter.translations,
      render: (translations: IChapter['translations'], { id }) => (
        <Link to={`/materials/chapters/${id}`}>{translations.ru?.title}</Link>
      ),
      title: 'Название',
    },
    {
      dataIndex: modelKeys.content.chapter.numId,
      render: (numId: IChapter['numId']) => numId,
      title: 'Номер темы',
    },

    {
      dataIndex: modelKeys.content.chapter.level,
      render: (level: IChapter['level']) => (
        <p className="mb-0.5" key={level}>
          {level && userPlayLevels[level]}
        </p>
      ),
      title: 'Уровень',
    },

    {
      dataIndex: modelKeys.content.chapter.status,
      render: (status: IChapter['status']) => <StatusTag status={status} />,
      title: 'Статус',
    },
  ];

  if (!canEdit || (!onClickDelete && !onClickEdit && !onClickDelete)) {
    return columns;
  }

  return [
    ...columns,
    {
      key: 'action',
      render: (chapter: IChapter) => (
        <div className="flex gap-x-1.5">
          {onClickPause && chapter.status !== 'DRAFT' && (
            <Popconfirm
              cancelText="Отменить"
              icon={
                chapter.status == 'ACTIVE' ? (
                  <PauseCircleOutlined className="text-ant-blue-5 text-base" />
                ) : (
                  <PlayCircleOutlined className="text-ant-blue-5 text-base" />
                )
              }
              okText={chapter.status == 'ACTIVE' ? 'Приостановить' : 'Активировать'}
              okType="primary"
              onConfirm={() =>
                onClickPause({
                  id: chapter.id as string,
                  status: chapter.status == 'ACTIVE' ? 'PAUSED' : 'ACTIVE',
                })
              }
              placement="topLeft"
              title={
                <p className="text-sm mb-0">
                  Вы уверены, что хотите
                  <span className="font-semibold">
                    {chapter.status == 'ACTIVE' ? ' приостановить ' : ' активировать '} тему #
                    {chapter.id}?
                  </span>
                </p>
              }
            >
              <Button
                ghost
                icon={chapter.status == 'ACTIVE' ? <PauseOutlined /> : <CaretRightOutlined />}
                type="primary"
              />
            </Popconfirm>
          )}
          {onClickEdit && (
            <Button
              ghost
              icon={<PencilIcon />}
              onClick={() => onClickEdit({ id: chapter.id as string })}
              type="primary"
            />
          )}
          {onClickDelete && (
            <Popconfirm
              cancelText="Отменить"
              icon={<DeleteOutlined className="text-ant-red-5" />}
              okText="Удалить"
              okType="danger"
              onConfirm={() => onClickDelete({ id: chapter.id as string })}
              placement="topLeft"
              title={
                <span className="text-sm">
                  Вы уверены, что хотите
                  <span className="font-bold"> удалить тему #{chapter.id}</span>?
                </span>
              }
            >
              <Button danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </div>
      ),
      title: 'Действия',
    },
  ];
};
