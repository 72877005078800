import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import { IEnumEducationFormatKeys } from '@chess-class/sandbox';

type EducationFormContextType = {
  defaultEducationFormat?: IEnumEducationFormatKeys;
  setDefaultEducationFormat: Dispatch<SetStateAction<IEnumEducationFormatKeys>>;
};

const educationFormatContextDefaultValues: EducationFormContextType = {
  defaultEducationFormat: 'GENERAL',
  setDefaultEducationFormat: () => {
    //
  },
};

export const EducationFormatContext = createContext<EducationFormContextType>(
  educationFormatContextDefaultValues,
);

export function useEducationFormat() {
  return useContext(EducationFormatContext);
}

type EducationFormatProviderProps = {
  children: ReactNode;
};

export function EducationFormatProvider({ children }: EducationFormatProviderProps) {
  const [defaultEducationFormat, setDefaultEducationFormat] =
    useState<IEnumEducationFormatKeys>('GENERAL');

  useEffect(() => {
    const initValues = localStorage.getItem('educationFormat') as 'GENERAL' | 'PRIVATE';
    setDefaultEducationFormat(initValues ?? 'GENERAL');
  }, []);

  return (
    <EducationFormatContext.Provider value={{ defaultEducationFormat, setDefaultEducationFormat }}>
      {children}
    </EducationFormatContext.Provider>
  );
}
