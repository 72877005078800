import { FC } from 'react';
import { Modal, Result } from 'antd';
import { Formik } from 'formik';
import { SearchOutlined } from '@ant-design/icons';

import { api, ICoachProfileEditRequest } from '@chess-class/sandbox';
import { notify, phoneMaskSanitizer } from '@chess-class/sandbox/utils';

import BaseSkeleton from '~/components/atoms/BaseSkeleton';

import { CoachForm } from './CoachForm';

export const CoachEditModal: FC<{
  id?: string;
  isOpen: boolean;
  onCancel: () => void;
}> = ({ id = '', isOpen, onCancel }) => {
  const coachQuery = api.user.coachToSchool.useCoachToSchool({ id });
  const coachEdit = api.user.coachToSchool.useCoachProfileEdit();
  const coach = coachQuery.data?.data;

  return (
    <Modal
      className="px-4 md:px-0 md:w-3/4 lg:w-2/3"
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={
        <h1 className="text-xl m-0 space-x-1">
          <span>Редактировать тренера</span>
        </h1>
      }
    >
      {coachQuery.isLoading ? (
        <BaseSkeleton />
      ) : !coach?.id ? (
        <Result icon={<SearchOutlined />} title="Персонал не найден" />
      ) : (
        <Formik<ICoachProfileEditRequest>
          enableReinitialize
          initialValues={{
            ...coach,
            id: coach.coachUserId,
            middleName: coach.middleName,
          }}
          onSubmit={(values) => {
            coachEdit
              .mutateAsync({
                ...values,
                phone: phoneMaskSanitizer(values.phone ?? ''),
              })
              .then((data) => {
                if (data.status == 201 || data.status == 200) {
                  notify('success', 'Тренер успешно изменен');
                  onCancel();
                }
              });
          }}
          validationSchema={api.user.coachToSchool.helpers.profileEditValidation}
        >
          <CoachForm edit isLoading={coachEdit.isLoading} onCancel={onCancel} />
        </Formik>
      )}
    </Modal>
  );
};
