import { IPageActions } from '@chess-class/sandbox';

enum IEnumStudioTab {
  'editor' = 'Editor',
  'fen' = 'FEN',
  'pgn' = 'PGN',
}
export type IEnumStudioTabKeys = keyof typeof IEnumStudioTab;

export type StudioCreatePageActions = {
  tab: IEnumStudioTabKeys;
};

export const studioCreatePageActions: IPageActions<StudioCreatePageActions> = {
  tab: { allowed: (value) => Object.keys(IEnumStudioTab).includes(value), default: 'fen' },
};
