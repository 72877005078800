import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgStaffIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg height="24px" viewBox="0 0 491.52 491.52" width="24px" {...props}>
    <path
      d="M205.04,0c5.73,1.22,11.51,2.24,17.18,3.7,45.68,11.78,82.49,56.08,78.78,111.69-3.16,47.33-41.39,89.1-89.78,96.53-68.47,10.5-128.06-44.72-122.93-113.93C91.97,48.29,131.92,6.56,181.23,.88,182.48,.73,183.69,.3,184.92,0c6.71,0,13.41,0,20.12,0Z"
      fill="currentColor"
    />
    <path
      d="M321.88,263.1c-.87,3.23-1.63,5.19-5.7,4.93-20.86-1.34-37.43,6.85-48.83,24.3-7.14,10.93-13.68,22.32-19.64,33.94-10.22,19.93-7.67,39.14,5.12,57.73-6.77,9.09-10.81,19.5-11.27,31.12-.46,11.49,2.95,21.98,9.33,32.4-2.17,.15-3.64,.34-5.11,.34-74.87,.01-149.75,.02-224.62,0-12.5,0-18.4-5.99-18.41-18.55-.02-23.47-.05-46.94,0-70.4,.12-51.75,34.59-92.79,85.51-101.72,4.54-.8,9.21-1.2,13.81-1.2,61.62-.08,123.25-.12,184.87,0,12.02,.02,23.65,2.52,34.93,7.12Z"
      fill="currentColor"
    />
    <path
      d="M313.37,310.33c1.68,.63,4.65,1.69,7.58,2.85,5.77,2.28,11.63,6.64,17.24,6.32,5.38-.31,10.56-5.33,15.63-8.58,1.22-.78,2.17-2.67,2.4-4.18,.92-5.99,1.5-12.03,2.23-18.04,.96-7.87,4.72-11.38,12.59-11.45,10.38-.1,20.76-.1,31.13,0,7.69,.07,11.47,3.46,12.49,11.01,.79,5.85,1.62,11.7,2.06,17.58,.23,3.12,1.48,4.76,4.3,6.08,3.73,1.75,7.33,3.92,10.65,6.38,2.4,1.78,4.37,1.87,6.97,.68,5.8-2.64,11.68-5.12,17.6-7.49,6.14-2.46,10.96-.92,14.34,4.76,5.71,9.59,11.31,19.25,16.77,28.99,3.03,5.42,1.89,10.52-3.07,14.45-5.25,4.16-10.8,7.96-15.92,12.28-1.36,1.15-2.14,3.57-2.28,5.47-.25,3.31,.97,6.86,.18,9.96-1.36,5.34,.82,8.17,4.92,10.87,4.52,2.98,8.77,6.4,13,9.79,5.15,4.14,6.18,9.1,2.95,14.84-5.39,9.59-10.9,19.12-16.51,28.59-3.46,5.83-8.29,7.3-14.75,4.69-5.91-2.39-11.81-4.83-17.6-7.5-2.5-1.15-4.29-.78-6.49,.74-3.66,2.53-7.77,4.41-11.39,6.98-1.56,1.11-3.08,3.14-3.41,4.98-1.05,5.8-1.52,11.7-2.24,17.56-1.01,8.18-4.77,11.59-12.9,11.62-10.22,.03-20.44,.04-30.65,0-7.88-.03-11.73-3.46-12.71-11.27-.74-5.86-1.56-11.71-2.04-17.59-.24-2.94-1.44-4.57-4.13-5.68-2.49-1.02-5.11-2.2-7.04-4-4.75-4.43-9.36-4.58-14.9-1.41-4.12,2.36-8.73,3.87-13.18,5.63-6.46,2.55-11.24,1.04-14.72-4.83-5.6-9.47-11.12-19-16.51-28.59-3.2-5.7-2.09-10.72,3.1-14.81,5.14-4.05,10.33-8.03,15.66-11.83,2.25-1.6,2.83-3.23,2.55-6-.43-4.24,.15-8.58-.18-12.84-.15-1.9-.96-4.29-2.32-5.44-5-4.21-10.4-7.93-15.56-11.96-5.39-4.21-6.46-9.25-3.11-15.19,5.33-9.44,10.83-18.79,16.24-28.19,2.13-3.71,5.12-6.05,11.03-6.25Zm73.17,116.15c23.58,.02,42.67-19,42.68-42.54,.01-23.45-19.18-42.69-42.61-42.72-23.45-.02-42.65,19.18-42.65,42.65,0,23.6,18.98,42.59,42.58,42.6Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgStaffIcon);
export default Memo;
