import { FC } from 'react';
import { Button } from 'antd';

import { api, IVideosRequest } from '@chess-class/sandbox';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';

import { AdminTable } from '~/components/organisms/adminTable/AdminTable';
import { useEducationFormat } from '~/context/EducationFormContext';
import { PageMeta } from '~/context/PageMetaContext';
import { checkEditPermission } from '~/utils/checkEditPermission';

import { VideoCreateModal } from '~mMaterials/videos/videos/components/organisms/VideoCreateModal';
import { VideoEditModal } from '~mMaterials/videos/videos/components/organisms/VideoEditModal/VideoEditModal';
import { VideoViewModal } from '~mMaterials/videos/videos/components/organisms/VideoViewModal';
import { videosColumns } from '~mMaterials/videos/videos/consts/columns/videosColumns';
import { videosFilters } from '~mMaterials/videos/videos/consts/filters/videosFilters';
import {
  IVideosPageActions,
  videosPageActions,
} from '~mMaterials/videos/videos/consts/pageActions/IVideosPageActions';
import VideoTabs from '~mMaterials/videos/VideoTabs';

export const VideosPage: FC = () => {
  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    IVideosRequest,
    IVideosPageActions
  >(videosFilters, videosPageActions);

  const { defaultEducationFormat } = useEducationFormat();

  const videos = api.content.videos.useVideos({
    ...filtered,
    educationFormat: defaultEducationFormat,
  });

  const videoStatusUpdate = api.content.videos.useVideoStatusUpdate();
  const videoDelete = api.content.videos.useVideoDelete();

  const canEditVideo = checkEditPermission('materials');

  return (
    <div className="flex flex-col gap-4">
      <VideoTabs active="videos" />
      <PageMeta
        openMenuKeys={['materials']}
        selectedMenuKeys={['materials-videos']}
        subTitle={
          actioned.action == 'view' && actioned.actionId
            ? `Видео #${actioned.actionId}`
            : actioned.action == 'create' && !actioned.actionId
              ? 'Создать видео'
              : actioned.action == 'edit' && actioned.actionId
                ? `Изменить видео #${actioned.actionId}`
                : ''
        }
        title="Видеобиблиотека"
      />

      {canEditVideo && (
        <div className="flex justify-end gap-4">
          <Button
            className="sm:px-12"
            onClick={() => setActioned({ action: 'create' })}
            size="large"
            type="primary"
          >
            Новое видео
          </Button>
        </div>
      )}

      <AdminTable
        columns={videosColumns({
          canEdit: canEditVideo,
          onClickDelete: videoDelete.mutateAsync,
          onClickEdit: ({ id }) => setActioned({ action: 'edit', actionId: id }),
          onClickPause: videoStatusUpdate.mutateAsync,
          onClickView: ({ id }) => setActioned({ action: 'view', actionId: id }),
        })}
        emptyDescription={
          <div>
            <p>Видео не найдено.</p>
            <p>Вы можете создать видео, нажав на кнопку “Новое видео”</p>
          </div>
        }
        query={videos}
        tableFilter={{ filtered, filters: videosFilters, onFilter }}
      />

      <VideoViewModal
        id={actioned.actionId}
        isOpen={actioned.action == 'view'}
        onCancel={clearParams}
        openEdit={() => setActioned({ action: 'edit', actionId: actioned.actionId })}
      />

      <VideoCreateModal
        isOpen={actioned.action == 'create'}
        onCancel={clearParams}
        onSubmit={(id) => setActioned({ action: 'edit', actionId: id, actionStep: 1 })}
      />

      <VideoEditModal
        id={actioned.actionId}
        isOpen={actioned.action == 'edit'}
        onCancel={clearParams}
        setStep={(step) =>
          setActioned({ action: actioned.action, actionId: actioned.actionId, actionStep: step })
        }
        step={
          typeof actioned.actionStep == 'string'
            ? parseInt(actioned.actionStep)
            : actioned.actionStep
        }
      />
    </div>
  );
};
