import { FC } from 'react';
import { Select, SelectProps } from 'antd';

export const GlobalEducationFormatSwitcher: FC<SelectProps> = ({ ...rest }) => {
  return (
    <Select
      className="w-[200px]"
      size="large"
      {...rest}
      options={[
        { label: 'Государственные школы', value: 'GENERAL' },
        { label: 'Частные школы', value: 'PRIVATE' },
      ]}
    />
  );
};
