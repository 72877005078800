import type { FC } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Tabs } from 'antd';

import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';
import { BoardWhiteIcon } from '@chess-class/sandbox/icons';

import { PageMeta } from '~/context/PageMetaContext';

import { StudioEditorForm } from '~mMaterials/studios/components/molecules/StudioEditorForm';
import { StudioFenForm } from '~mMaterials/studios/components/molecules/StudioFenForm';
import { StudioPgnForm } from '~mMaterials/studios/components/molecules/StudioPgnForm';
import {
  IEnumStudioTabKeys,
  StudioCreatePageActions,
  studioCreatePageActions,
} from '~mMaterials/studios/consts/pageActions/StudioCreatePageActions';

export const StudioCreatePage: FC = () => {
  const { actioned, setActioned } = useUrlFilteredActioned<undefined, StudioCreatePageActions>(
    undefined,
    studioCreatePageActions,
  );

  return (
    <div>
      <PageMeta
        openMenuKeys={['materials']}
        selectedMenuKeys={['materials-studios']}
        title="Создание студии"
      />

      <Breadcrumb
        items={[
          {
            title: (
              <Link className="hover:bg-transparent" to="/materials/studios">
                Студии
              </Link>
            ),
          },
          { title: 'Создание' },
        ]}
      />

      <h2 className="mt-8 flex items-center gap-2">
        <BoardWhiteIcon /> Создание студии
      </h2>

      <Tabs
        className="w-full items-center tabs-full"
        defaultActiveKey={actioned.tab}
        items={[
          {
            children: <StudioFenForm />,
            key: 'fen',
            label: 'FEN',
          },
          {
            children: <StudioPgnForm />,
            key: 'pgn',
            label: 'PGN',
          },
          {
            children: <StudioEditorForm />,
            key: 'editor',
            label: 'Редактор',
          },
        ]}
        onChange={(key) => setActioned({ tab: key as IEnumStudioTabKeys })}
      />
    </div>
  );
};
