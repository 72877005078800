import { FC, useState } from 'react';

import { api, FilterRender } from '@chess-class/sandbox';

import { SelectMultipleFilter } from '~/components/molecules/filters/static/SelectMultipleFilter';

interface TagsSelectMultipleInterface {
  filterRender?: FilterRender;
  placeholder?: string;
}

export const TagsSelectMultiple: FC<TagsSelectMultipleInterface> = ({
  filterRender,
  placeholder = 'Выберите тему(ы)',
}) => {
  const [tagName, setTagName] = useState('');
  const tags = api.content.authors.useAuthors({ name: tagName });
  const tagsData = tags.data?.data;

  const options =
    tagsData?.content.map((tag) => ({
      label: tag.rank ? (
        <>
          <span className="font-bold text-ant-red-7 mr-1">{tag.rank}</span>
          {tag.translations.ru?.name}
        </>
      ) : (
        tag.translations.ru?.name
      ),
      value: tag.id.toString(),
    })) || [];

  return (
    <SelectMultipleFilter
      filterOption={false}
      filterRender={filterRender}
      loading={tags.isLoading}
      onSearch={(searchValue) => setTagName(searchValue)}
      options={options}
      placeholder={placeholder}
    />
  );
};
