import { FC } from 'react';
import { FormItem, Input } from 'formik-antd';

import { ILocalization } from '@chess-class/sandbox';

import { CountryImageText } from '~/components/atoms/CountryImageText';

export const ChapterFormTranslationCard: FC<{
  nameStart: string;
  text: string;
  translation: ILocalization;
}> = ({ nameStart, text, translation }) => {
  return (
    <div className="md:grid md:grid-cols-2 gap-x-6 gap-y-2 rounded-xl py-4 px-6 shadow border border-ant-gray-2">
      <FormItem
        className="col-span-2 mb-2"
        label={<CountryImageText src={translation} text={text} width={15} />}
        name={`${nameStart}.title`}
      >
        <Input maxLength={100} name={`${nameStart}.title`} placeholder="Название" suffix />
      </FormItem>

      <FormItem label="Описание" name={`${nameStart}.description`}>
        <Input.TextArea maxLength={1000} name={`${nameStart}.description`} placeholder="Описание" />
      </FormItem>

      <FormItem label="Заметки для тренера" name={`${nameStart}.notes`}>
        <Input.TextArea maxLength={1000} name={`${nameStart}.notes`} placeholder="Заметки" />
      </FormItem>
    </div>
  );
};
