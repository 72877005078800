import {
  IEnumExerciseStatus,
  IEnumExerciseTarget,
  IEnumExerciseType,
  IFilters,
} from '@chess-class/sandbox';
import { modelKeys, paginationBaseFilters } from '@chess-class/sandbox/consts';

import { ChaptersSelectMultipleFilter } from '~/components/molecules/filters/dynamic/ChaptersSelectMultipleFilter';
import { CheckboxFilter } from '~/components/molecules/filters/static/CheckboxFilter';
import { InputFilter } from '~/components/molecules/filters/static/InputFilter';
import { RadioFilter } from '~/components/molecules/filters/static/RadioFilter';

import { userPlayLevels } from '~mMaterials/exercises/exercises/types/UserPlayLevel';

export const exerciseFilters: IFilters = {
  ...paginationBaseFilters,
  action: {
    input: <InputFilter placeholder="Введите часть FEN" />,
    queries: [{ key: 'boardState' }],
  },
  id: {
    input: <InputFilter placeholder="Введите ID" />,
    sort: true,
  },
  numId: {
    input: <InputFilter placeholder="Введите NumID" />,
    sort: true,
  },
  [modelKeys.content.exercise.target]: {
    input: (
      <RadioFilter
        options={Object.entries(IEnumExerciseTarget).map(([value, label]) => ({
          label,
          value,
        }))}
      />
    ),
    sort: false,
  },
  [modelKeys.content.exercise.type]: {
    input: (
      <RadioFilter
        options={Object.entries(IEnumExerciseType).map(([value, label]) => ({ label, value }))}
      />
    ),
    sort: true,
  },
  [modelKeys.content.exercise.chapter]: {
    input: <ChaptersSelectMultipleFilter />,
    queries: [{ key: 'chapterId' }],
  },
  [modelKeys.content.exercise.status]: {
    input: (
      <CheckboxFilter
        options={Object.entries(IEnumExerciseStatus).map(([value, label]) => ({
          label,
          value,
        }))}
      />
    ),
    sort: true,
  },
  [modelKeys.content.exercise.level]: {
    input: (
      <CheckboxFilter
        options={Object.entries(userPlayLevels).map(([value, label]) => ({
          label,
          value,
        }))}
      />
    ),
    queries: [{ key: 'level' }],
    sort: true,
    sortOrderReversed: true,
  },
};
