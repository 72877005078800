import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from '~/components/molecules/routes/ProtectedRoute';
import NotFoundPage from '~/components/pages/errors/NotFoundPage';

import { CoachesPage } from '~mSchools/coaches/components/pages/CoachesPage';
import { SchoolsPage } from '~mSchools/components/pages/SchoolsPage';

export const SchoolsRouter = () => {
  return (
    <ProtectedRoute access="materials">
      <Routes>
        <Route element={<SchoolsPage />} index />
        <Route element={<CoachesPage />} path="coaches" />
        <Route element={<NotFoundPage />} path="*" />
      </Routes>
    </ProtectedRoute>
  );
};
