import { FC } from 'react';
import { Modal } from 'antd';
import { AxiosError } from 'axios';
import { Formik } from 'formik';

import { api, IExerciseProblemCreateRequest } from '@chess-class/sandbox';
import { notify } from '@chess-class/sandbox/utils';

import { FaChessKnightIcon } from '~/components/atoms/icons';

import ProblemForm from '~mMaterials/exercises/exercises/components/molecules/ProblemForm';

export const ExerciseProblemCreateModal: FC<{
  isOpen: boolean;
  onCancel: () => void;
}> = ({ isOpen, onCancel }) => {
  const problemCreate = api.content.exercises.useExerciseProblemCreate();

  return (
    <Modal
      className="px-4 md:px-0 md:w-4/5 lg:w-3/4 top-4"
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={
        <h1 className="text-xl m-0 flex items-center gap-1">
          <FaChessKnightIcon /> Загрузить PGN
        </h1>
      }
    >
      <Formik<IExerciseProblemCreateRequest>
        initialValues={api.content.exercises.helpers.exerciseProblemCreateInitial}
        onSubmit={(values, { resetForm, setFieldError }) => {
          problemCreate
            .mutateAsync(values)
            .then((data) => {
              if (data.status == 201 || data.status == 200) {
                notify('success', 'PGN загружен');
                onCancel();
                resetForm();
              }
            })
            .catch((e: AxiosError) => {
              if (e.response?.status == 422) {
                setFieldError('file', 'Неверный формат pgn файла');
              }
            });
        }}
        validationSchema={api.content.exercises.helpers.exerciseProblemCreateValidation}
      >
        <ProblemForm isLoading={problemCreate.isLoading} onCancel={onCancel} />
      </Formik>
    </Modal>
  );
};
