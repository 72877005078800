import { Navigate } from 'react-router-dom';
import { Skeleton } from 'antd';

import { api } from '@chess-class/sandbox';

import { sidebars } from '~/consts/sidebars';
import { useEducationFormat } from '~/context/EducationFormContext';
import { checkPermission } from '~/utils/checkPermission';

const IndexRoute = () => {
  const me = api.user.adminAuth.useAdminMe();
  const { defaultEducationFormat } = useEducationFormat();

  const navbar = sidebars({ educationFormat: defaultEducationFormat }).find(
    (navbar) => !navbar.access || checkPermission(navbar.access, me.data?.accesses),
  );
  if (navbar?.key)
    return (
      <Navigate
        to={`${navbar?.link ? `/${navbar?.link}` : ''}/${
          navbar?.children ? navbar?.children?.[0].link : ''
        }`}
      />
    );

  if (me.isFetching) {
    return (
      <div className="bg-white p-10 md:p-15 mt-5 rounded-2xl">
        <Skeleton active round />
        <Skeleton active round />
        <Skeleton active round />
      </div>
    );
  }

  return <div />;
};

export default IndexRoute;
