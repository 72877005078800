import { memo } from 'react';
import { Button } from 'antd';
import { FormikTouched, useFormikContext } from 'formik';
import { FormItem, Input } from 'formik-antd';
import tw from 'twin.macro';
import { DeleteOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';

import {
  IExerciseTestCreateRequest,
  IExerciseTestEditRequest,
  ILocalization,
  IVideoStepThreeRequest,
} from '@chess-class/sandbox';

import { CountryImageText } from '~/components/atoms/CountryImageText';

const StyledFormItem = styled(FormItem)`
  .ant-form-item-control-input-content {
    ${tw`flex items-center gap-3`}
  }
`;

function QuestionAnswersForm({
  isVideo,
  localization,
  maxQuestionsNum = 4,
  nameStart,
  onRemoveClick,
  questionsNum = 4,
  setQuestionsNum,
  text,
}: {
  isVideo?: boolean;
  localization: ILocalization;
  maxQuestionsNum?: number;
  nameStart: string;
  onRemoveClick?: (index: number) => void;
  questionsNum?: number;
  setQuestionsNum?: (questionsNum: number) => void;
  text: string;
}) {
  const { touched, values } = useFormikContext<
    IExerciseTestCreateRequest | IExerciseTestEditRequest | IVideoStepThreeRequest
  >();

  const test = isVideo
    ? undefined
    : // (values as IVideoStepThreeRequest)?.test?.translations?.[localization]
      (values as IExerciseTestEditRequest).translations?.[localization];

  const testTouched = isVideo
    ? false
    : //  !!(touched as FormikTouched<IVideoStepThreeRequest>)?.test?.['translations']?.[localization]
      !!(touched as FormikTouched<IExerciseTestEditRequest>).translations?.[localization];

  const questionStatus =
    testTouched && !test?.question?.length && localization !== ('en' as ILocalization)
      ? 'error'
      : testTouched &&
          (test?.question?.length || 0) > 250 &&
          localization !== ('en' as ILocalization)
        ? 'warning'
        : '';

  return (
    <div className="md:grid md:grid-cols-2 gap-x-6 gap-y-2 rounded-xl p-6 shadow border">
      <FormItem
        className="col-span-2 mb-2"
        help={
          questionStatus == 'warning' ? (
            <span>Рекомендуемое количество символов 250</span>
          ) : questionStatus == 'error' ? (
            <span className="text-red-400">Вопрос обязателен для заполнения</span>
          ) : (
            ''
          )
        }
        label={<CountryImageText src={localization} text={text} width={15} />}
        name={`${nameStart}.question`}
        validateStatus={questionStatus}
      >
        <Input.TextArea
          maxLength={250}
          name={`${nameStart}.question`}
          placeholder="Вопрос"
          showCount
        />
      </FormItem>

      {Array.from(Array(questionsNum)).map((_, index) => {
        const answerStatus =
          testTouched &&
          !test?.answerOptions?.[index]?.length &&
          localization !== ('en' as ILocalization)
            ? 'error'
            : testTouched &&
                (test?.answerOptions?.[index]?.length || 0) > 150 &&
                localization !== ('en' as ILocalization)
              ? 'warning'
              : '';

        return (
          <StyledFormItem
            className="mb-2 w-full pr-4"
            help={
              answerStatus == 'warning'
                ? 'Рекомендуемое количество символов 150'
                : answerStatus == 'error'
                  ? 'Обязательно'
                  : undefined
            }
            key={`answer${index + 1}`}
            label={`Вариант ${String.fromCharCode(65 + index)}`}
            name={`${nameStart}.answerOptions.${index}`}
            validateStatus={answerStatus}
          >
            <Input
              maxLength={150}
              name={`${nameStart}.answerOptions.${index}`}
              placeholder="Ответ"
              prefix
              showCount
            />
            {index > 1 && !!setQuestionsNum && (
              <Button
                danger
                ghost
                icon={<DeleteOutlined />}
                onClick={() => {
                  onRemoveClick && onRemoveClick(index);
                  setQuestionsNum(questionsNum - 1);
                }}
                type="primary"
              />
            )}
          </StyledFormItem>
        );
      })}

      <div className="mt-2 flex items-center">
        {maxQuestionsNum > questionsNum && !!setQuestionsNum && (
          <Button
            className="truncate"
            ghost
            // icon={<PlusOutlined />}
            onClick={() => setQuestionsNum(questionsNum + 1)}
            type="primary"
          >
            Добавить вариант
          </Button>
        )}
      </div>
    </div>
  );
}

export default memo(QuestionAnswersForm);
