import type { FC } from 'react';
import { InputNumber as FormikAntdInputNumber, InputNumberProps } from 'formik-antd';

export const InputNumber: FC<InputNumberProps> = (props) => {
  return (
    <FormikAntdInputNumber
      controls={false}
      onKeyDown={(e) => {
        const restrictedElements = ['-', '+', '.', 'e'];

        if (restrictedElements.includes(e.key)) {
          e.preventDefault();
        }
      }}
      prefix
      type="number"
      {...props}
    />
  );
};
