import { IPageActions } from '@chess-class/sandbox';

enum StudentPageActionsEnum {
  'view',
  'create',
}

export type StudentPageActions = {
  action: keyof typeof StudentPageActionsEnum;
  actionId: string;
};

export const studentPageActions: IPageActions<StudentPageActions> = {
  action: {
    allowed: (value) => Object.keys(StudentPageActionsEnum).includes(value),
    default: '',
  },
  actionId: { default: '' },
};
