import { FC, useState } from 'react';

import { api, FilterRender } from '@chess-class/sandbox';

import { SelectMultipleFilter } from '~/components/molecules/filters/static/SelectMultipleFilter';

interface SchoolCoachesSelectMultipleInterface {
  filterRender?: FilterRender;
  placeholder?: string;
}

export const SchoolCoachesSelectMultiple: FC<SchoolCoachesSelectMultipleInterface> = ({
  filterRender,
  placeholder = 'Выберите тему(ы)',
}) => {
  const [coachName, setCoachName] = useState('');
  const coaches = api.user.coachToSchool.useCoachesToSchool({ fullName: coachName });
  const coachesData = coaches.data?.data;

  const options =
    coachesData?.content
      .filter((coach) => coach.isSuperCoach)
      .map((coach) => ({
        label: `${coach.firstName} ${coach.lastName} ${coach.middleName}`,
        value: coach.coachUserId ?? '',
      })) || [];

  return (
    <SelectMultipleFilter
      filterOption={false}
      filterRender={filterRender}
      loading={coaches.isLoading}
      onSearch={(searchValue) => setCoachName(searchValue)}
      options={options}
      placeholder={placeholder}
    />
  );
};
