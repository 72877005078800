import { ColumnsType } from 'antd/lib/table';

import { IGuidelineChapter, IGuidelineChapterTiny } from '@chess-class/sandbox';
import { modelKeys } from '@chess-class/sandbox/consts';

export const guidelineChapterColumns: ColumnsType<IGuidelineChapterTiny> = [
  {
    dataIndex: modelKeys.guideline.guidelineChapters.id,
    title: 'ID',
  },
  {
    dataIndex: modelKeys.guideline.guidelineChapters.translations,
    render: (translations: IGuidelineChapter['translations']) => translations?.ru?.title,
    title: 'Название',
  },
  {
    dataIndex: modelKeys.guideline.guidelineChapters.numId,
    render: (numId: IGuidelineChapter['numId']) => numId,
    title: 'Номер темы',
  },
];
