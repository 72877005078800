import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'antd';
import { MailOutlined } from '@ant-design/icons';

import { api } from '@chess-class/sandbox';

import { LogoutIcon, UserIcon } from '~/components/atoms/icons';
import { storageKeys } from '~/consts/storageKeys';

export const LogoutDropdown: FC = () => {
  const navigate = useNavigate();
  const meQuery = api.user.adminAuth.useAdminMe();

  const logout = () => {
    localStorage.removeItem(storageKeys.tokenAccess);
    meQuery.remove();
    navigate('/auth/signin');
  };

  return (
    <Dropdown
      menu={{
        items: [
          {
            className: 'pointer-events-none rounded-b-none pb-1',
            icon: <MailOutlined />,
            key: 'email',
            label: meQuery.data?.email,
          },
          {
            icon: <LogoutIcon className="w-3 h-3 " />,
            key: 'profile',
            label: 'Выход',
            onClick: logout,
          },
        ],
      }}
      placement="bottom"
    >
      <UserIcon />
    </Dropdown>
  );
};
