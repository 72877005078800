import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { AxiosError } from 'axios';
import { Formik } from 'formik';
import { Form, FormItem, Input } from 'formik-antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { api, IAdminResetInvokeRequest, IBackendError } from '@chess-class/sandbox';
import { notify } from '@chess-class/sandbox/utils';

import { PageMeta } from '~/context/PageMetaContext';

const ResetEmailPage: FC = () => {
  const resetEmail = api.user.adminAuth.useAdminResetInvoke();

  return (
    <>
      <PageMeta title="Восстановление пароля" />

      <Formik<IAdminResetInvokeRequest>
        initialValues={api.user.adminAuth.helpers.adminResetInvokeInitial}
        onSubmit={(values) => {
          resetEmail.mutateAsync(values).catch((error: AxiosError<IBackendError>) => {
            notify('error', error.response?.data.message ?? 'Повторите попытку позже');
          });
        }}
        validationSchema={api.user.adminAuth.helpers.adminResetInvokeValidation}
      >
        <Form className="space-y-8" layout="vertical">
          <h2 className="text-center text-3xl sm:text-4xl text-gray-900 truncate">Сброс пароля</h2>

          <p>Введите адрес электронной почты, для сброса пароля</p>

          <FormItem label="Адрес электронной почты" name="email">
            <Input name="email" placeholder="example@example.com" suffix type="email" />
          </FormItem>

          <div>
            <Button
              block
              htmlType="submit"
              loading={resetEmail.isLoading}
              size="large"
              type="primary"
            >
              Отправить письмо
            </Button>

            <Link className="text-center" to="/auth/signin">
              <Button block className="mt-2 text-base" icon={<ArrowLeftOutlined />} type="link">
                Назад
              </Button>
            </Link>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default ResetEmailPage;
