import { Chessboard } from 'react-chessboard';
import { Button, Switch as AntSwitch, Upload } from 'antd';
import { FormikErrors, FormikTouched, useFormikContext } from 'formik';
import { Form, FormItem, Input, Select, Switch } from 'formik-antd';
import { LinkOutlined, UploadOutlined } from '@ant-design/icons';

import {
  api,
  IEnumExerciseGamePhase,
  IEnumExerciseTarget,
  IExercise,
  IExerciseProblemCreateRequest,
  IExerciseProblemEditRequest,
} from '@chess-class/sandbox';
import { modelKeys } from '@chess-class/sandbox/consts';

import { userPlayLevels } from '~mMaterials/exercises/exercises/types/UserPlayLevel';

export default function ProblemForm({
  exercise,
  isEdit,
  isLoading,
  onCancel,
}: {
  exercise?: IExercise;
  isEdit?: boolean;
  isLoading?: boolean;
  onCancel: () => void;
}) {
  const chapters = api.content.chapters.useChapters({ size: 9999 });

  const { errors, setFieldValue, touched, values } = useFormikContext<
    IExerciseProblemCreateRequest | IExerciseProblemEditRequest
  >();

  return (
    <Form className="mt-8 space-y-8" layout="vertical">
      {!isEdit ? (
        <FormItem label="PGN" name="file">
          <Upload.Dragger
            accept=".pgn"
            beforeUpload={(file) => {
              setFieldValue('file', file);
              return false;
            }}
            className={
              (errors as FormikErrors<IExerciseProblemCreateRequest>).file ? 'border-ant-red-5' : ''
            }
            multiple={false}
            name="file"
            showUploadList={false}
          >
            <p className="ant-upload-drag-icon">
              <UploadOutlined
                className={
                  (errors as FormikErrors<IExerciseProblemCreateRequest>).file
                    ? 'text-ant-red-5'
                    : (values as IExerciseProblemCreateRequest).file
                      ? ''
                      : 'text-ant-gray-5'
                }
              />
            </p>

            {(values as IExerciseProblemCreateRequest).file ? (
              <p
                className={`font-bold truncate ${
                  (errors as FormikErrors<IExerciseProblemCreateRequest>).file
                    ? 'text-ant-red-5'
                    : 'text-ant-blue-5'
                }`}
              >
                <LinkOutlined className="mr-1" />
                {(values as IExerciseProblemCreateRequest).file?.name}
              </p>
            ) : (
              <p
                className={
                  (touched as FormikTouched<IExerciseProblemCreateRequest>).file &&
                  (errors as FormikErrors<IExerciseProblemCreateRequest>).file
                    ? 'text-ant-red-3'
                    : 'ant-upload-hint'
                }
              >
                Загрузите PGN
              </p>
            )}
          </Upload.Dragger>
        </FormItem>
      ) : (
        !!exercise?.problem && (
          <div className="grid place-items-center overflow-x-auto">
            <div className="shadow-md rounded-sm">
              <Chessboard
                arePiecesDraggable={false}
                boardWidth={360}
                position={exercise.problem.boardState || ''}
              />
            </div>
            <p className="mt-2 mb-0 text-center" style={{ maxWidth: 360 }}>
              {exercise.problem.moveSequence}
            </p>
          </div>
        )
      )}

      {!isEdit && (
        <FormItem label="Название коллекции упражнений" name={`${!isEdit ? 'json.' : ''}groupName`}>
          <Input
            maxLength={150}
            name={`${!isEdit ? 'json.' : ''}groupName`}
            placeholder="Название"
            suffix
          />
        </FormItem>
      )}

      <div className="grid grid-cols-2 gap-4 gap-x-8">
        <FormItem
          label="Уровень"
          name={`${!isEdit ? 'json.' : ''}${modelKeys.content.exercise.level}`}
        >
          <Select
            name={`${!isEdit ? 'json.' : ''}${modelKeys.content.exercise.level}`}
            optionFilterProp="label"
            options={Object.keys(userPlayLevels).map((level) => ({
              label: userPlayLevels[level],
              value: level,
            }))}
            placeholder="Уровень"
            showSearch={false}
          />
        </FormItem>

        <FormItem label="Тема" name={`${!isEdit ? 'json.' : ''}chapterId`}>
          <Select
            allowClear
            className="gap-y-2"
            name={`${!isEdit ? 'json.' : ''}chapterId`}
            optionFilterProp="label"
            options={
              chapters.data?.data.content.map((chapter) => ({
                label: chapter.translations.ru?.title ?? '-',
                value: chapter.id,
              })) || []
            }
          />
        </FormItem>

        <FormItem label="Место для применения" name={`${!isEdit ? 'json.' : ''}target`}>
          <Select
            className="gap-y-2"
            name={`${!isEdit ? 'json.' : ''}target`}
            optionFilterProp="label"
            options={
              Object.keys(IEnumExerciseTarget).map((key) => ({
                label: IEnumExerciseTarget[key],
                value: key,
              })) || []
            }
          />
        </FormItem>

        <FormItem
          label="Стадия игры"
          name={`${!isEdit ? 'json.' : ''}${modelKeys.content.exercise.gamePhase}`}
        >
          <Select
            name={`${!isEdit ? 'json.' : ''}${modelKeys.content.exercise.gamePhase}`}
            options={Object.entries(IEnumExerciseGamePhase).map(([value, label]) => ({
              label,
              value,
            }))}
          />
        </FormItem>
      </div>

      <div className="grid md:grid-cols-3 place-items-center text-center">
        <FormItem
          label={<span className="text-center">Капитализация преимущества</span>}
          name={`${!isEdit ? 'json.' : ''}${modelKeys.content.exercise.isCapitalization}`}
        >
          <Switch
            name={`${!isEdit ? 'json.' : ''}${modelKeys.content.exercise.isCapitalization}`}
          />
        </FormItem>

        <FormItem
          label={<span className="text-center">Тактика</span>}
          name={`${!isEdit ? 'json.' : ''}${modelKeys.content.exercise.isTactic}`}
        >
          <Switch name={`${!isEdit ? 'json.' : ''}${modelKeys.content.exercise.isTactic}`} />
        </FormItem>

        <FormItem
          label={<span className="text-center">Запустить немедленно</span>}
          name={`${!isEdit ? 'json.' : ''}${modelKeys.content.exercise.status}`}
        >
          <Input
            hidden
            name={`${!isEdit ? 'json.' : ''}${modelKeys.content.exercise.status}`}
            suffix
          />

          <AntSwitch
            checked={
              (!isEdit
                ? (values as IExerciseProblemCreateRequest).json[modelKeys.content.exercise.status]
                : (values as IExerciseProblemEditRequest).status) == 'ACTIVE'
            }
            onChange={(checked) =>
              setFieldValue(
                `${!isEdit ? 'json.' : ''}${modelKeys.content.exercise.status}`,
                checked ? 'ACTIVE' : 'DRAFT',
              )
            }
          />
        </FormItem>
      </div>

      <div className="grid md:grid-cols-2 gap-5">
        <Button block onClick={onCancel}>
          Отменить
        </Button>

        <Button block htmlType="submit" loading={isLoading} type="primary">
          <span className="max-w-full overflow-ellipsis overflow-hidden">
            {(!isEdit
              ? (values as IExerciseProblemCreateRequest).json[modelKeys.content.exercise.status]
              : (values as IExerciseProblemEditRequest).status) == 'ACTIVE'
              ? `${isEdit ? 'Изменить' : 'Создать'} и запустить`
              : 'Сохранить как черновик'}
          </span>
        </Button>
      </div>
    </Form>
  );
}
