import { FC } from 'react';
import { Image } from 'antd';

import { api, IChapter, IExercisesRequest, ILocalization } from '@chess-class/sandbox';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';

import { CountryImageText } from '~/components/atoms/CountryImageText';
import { Description } from '~/components/atoms/Description';
import { TbVideoIcon, VscPlayIcon } from '~/components/atoms/icons';
import { StatusTag } from '~/components/atoms/StatusTag';
import { AdminTable } from '~/components/organisms/adminTable/AdminTable';

import { chapterExerciseColumns } from '~mMaterials/chapters/consts/columns/chapterExerciseColumns';
import { ExerciseViewModal } from '~mMaterials/exercises/exercises/components/organisms/ExerciseViewModal';
import { exerciseFilters } from '~mMaterials/exercises/exercises/consts/filters/exerciseFilters';
import {
  ExercisesPageActions,
  exercisesPageActions,
} from '~mMaterials/exercises/exercises/consts/pageActions/ExercisesPageActions';
import { VideoViewModal } from '~mMaterials/videos/videos/components/organisms/VideoViewModal';

type IChapterDetails = {
  chapter: IChapter;
  localization: ILocalization;
};

export const ChapterDetailsLayout: FC<IChapterDetails> = ({ chapter, localization }) => {
  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    IExercisesRequest,
    ExercisesPageActions
  >(exerciseFilters, exercisesPageActions);
  const exercises = api.content.exercises.useExercises({
    ...filtered,
    chapterId: [String(chapter.id)],
  });

  return (
    <div className="lg:col-span-3">
      <div className="border bg-white rounded-lg p-4 lg:p-6">
        <div className="flex flex-row justify-between border-b border-ant-blue-6 pb-4">
          <h2 className="text-2xl flex gap-2 items-baseline">
            <StatusTag status={chapter.status} />
            {chapter.translations?.[localization]?.title || '-'}
          </h2>
        </div>

        <div className="divide-y divide-slate-200 text-base">
          <Description
            description={
              <CountryImageText
                src={localization}
                text={chapter.translations?.[localization]?.description || '-'}
              />
            }
            title="Описание"
          />
          <Description
            description={
              <CountryImageText
                src={localization}
                text={chapter.translations?.[localization]?.notes || '-'}
              />
            }
            title="Заметки для тренера"
          />
          <Description
            description={
              !!chapter.additionalMaterialImages?.length && (
                <div className="flex flex-col gap-4">
                  {chapter.additionalMaterialImages?.map((url, index) => (
                    <div
                      className="grid grid-cols-2 gap-2 items-center"
                      key={`chapter-additional-${localization}-${index}`}
                    >
                      <div>
                        {url ? (
                          <Image className="w-20 h-20 object-cover rounded-lg" src={url} />
                        ) : (
                          '-'
                        )}
                      </div>
                      <div className="flex flex-col gap-1">
                        <span>
                          Название:{' '}
                          {chapter.translations?.[localization]?.additionalMaterialTitles?.[
                            index
                          ] || '-'}
                        </span>
                        <span className="text-gray-600 font-normal">
                          Описание:{' '}
                          {chapter.translations?.[localization]?.additionalMaterialDescriptions?.[
                            index
                          ] || '-'}
                        </span>
                      </div>
                    </div>
                  )) || '-'}
                </div>
              )
            }
            title="Дополнительный материал"
          />

          <Description description={chapter.numId} title="Номер темы" />
          <Description description={chapter.level} title="Уровень" />

          <Description
            description={chapter.lessons?.map((lesson) =>
              lesson && lesson.translations.ru?.videoFile?.hlsUrl ? (
                <div
                  className="cursor-pointer flex items-center gap-1 text-ant-purple-6"
                  key={lesson.id}
                  onClick={() => {
                    setActioned({ action: 'viewVideo', actionId: lesson.id });
                  }}
                >
                  <TbVideoIcon />
                  {lesson.translations?.[localization]?.title}
                </div>
              ) : (
                '-'
              ),
            )}
            title="Видеоуроки"
          />

          <Description
            description={chapter.cartoons?.map((cartoon) =>
              cartoon && cartoon.translations.ru?.videoFile?.hlsUrl ? (
                <div
                  className="flex items-center gap-1 text-ant-magenta-6 cursor-pointer"
                  key={cartoon.id}
                  onClick={() => {
                    setActioned({ action: 'viewVideo', actionId: cartoon.id });
                  }}
                >
                  <VscPlayIcon />
                  {cartoon.translations?.[localization]?.title}
                </div>
              ) : (
                '-'
              ),
            )}
            title="Мультфильмы"
          />

          <AdminTable
            columns={chapterExerciseColumns({
              onClickView: ({ id }) => setActioned({ action: 'view', actionId: id }),
            })}
            emptyDescription={
              <div>
                <p>Задачи по данной теме не найдены.</p>
              </div>
            }
            query={exercises}
            tableFilter={{ filtered, filters: exerciseFilters, onFilter }}
          />
        </div>
      </div>

      <ExerciseViewModal
        id={actioned.actionId}
        isOpen={actioned.action == 'view'}
        onCancel={clearParams}
      />

      <VideoViewModal
        id={actioned.actionId?.toString()}
        isOpen={actioned.action == 'viewVideo'}
        onCancel={clearParams}
      />
    </div>
  );
};
