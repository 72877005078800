import { type FC } from 'react';
import { Button, Divider, Switch as AntSwitch } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import clsx from 'clsx';
import { getIn, useFormikContext } from 'formik';
import { Form, FormItem, Input, Select } from 'formik-antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import {
  api,
  filteredLocalizations,
  IEnumLocalization,
  IEnumSchoolGradeType,
} from '@chess-class/sandbox';
import { convertPhoneToMaskPhone } from '@chess-class/sandbox/utils';

import { InputNumber } from '~/components/atoms/InputNumber';
import { StatusTag } from '~/components/atoms/StatusTag';
import { useEducationFormat } from '~/context/EducationFormContext';

import {
  ISchoolWithCoachesCreateRequest,
  shortCoachInitial,
} from '~mSchools/consts/schoolAndCoachesValidation';

type SchoolFormProps = {
  isEdit?: boolean;
  isLoading?: boolean;
  onCancel?: () => void;
};

export const SchoolForm: FC<SchoolFormProps> = ({ isEdit, isLoading, onCancel }) => {
  const { errors, setFieldValue, touched, values } =
    useFormikContext<ISchoolWithCoachesCreateRequest>();
  const { defaultEducationFormat } = useEducationFormat();

  const guidelines = api.guideline.guideline.useGuidelines({
    educationFormat: values.educationFormat || defaultEducationFormat,
    page: 0,
    size: 999,
    status: 'PUBLISHED',
  });

  const regionsQuery = api.schools.locations.useLocations({
    enabled: true,
    isRegion: true,
    unpaged: true,
  });

  const districtsQuery = api.schools.locations.useLocations({
    enabled: !!values.additional.regionId,
    isDistrict: true,
    parentId: values.additional.regionId ? [values.additional.regionId] : undefined,
    unpaged: true,
  });

  const locationsQuery = api.schools.locations.useLocations({
    enabled: !!values.additional.regionId && !!values.additional.districtId,
    isLocality: true,
    parentId: values.additional.districtId ? [values.additional.districtId] : undefined,
    unpaged: true,
  });

  const locations = locationsQuery.data?.data.content ?? [];
  const districts = districtsQuery.data?.data.content ?? [];
  const regions = regionsQuery.data?.data.content ?? [];

  return (
    <Form layout="vertical">
      <h2 className="flex items-center">{isEdit ? 'Редактирование школы' : 'Новая школа'}</h2>
      <div className="grid grid-cols-6 gap-2">
        <FormItem className="col-span-2" label="Тип школы" name="educationFormat">
          <Input
            disabled
            name="educationFormat"
            suffix
            value={defaultEducationFormat == 'GENERAL' ? 'Государственная' : 'Частная школа'}
          />
        </FormItem>
        <FormItem className="col-span-2" label="Название школы" name="name">
          <Input name="name" suffix />
        </FormItem>
        <FormItem className="col-span-2" label="Email" name="email">
          <Input name="email" placeholder="example@example.com" suffix />
        </FormItem>
        <FormItem className="col-span-2" label="Область" name="additional.regionId">
          <Select
            name="additional.regionId"
            onChange={() => {
              setFieldValue('additional.districtId', null);
              setFieldValue('additional.cityId', null);
            }}
            options={regions.map((region) => ({
              label: region.nameRu,
              value: region.id,
            }))}
            placeholder="Выбрать область"
          />
        </FormItem>
        <FormItem className="col-span-2" label="Район" name="additional.districtId">
          <Select
            disabled={!values.additional.regionId}
            name="additional.districtId"
            onChange={() => {
              setFieldValue('additional.cityId', null);
            }}
            options={districts.map((district) => ({
              label: district.nameRu,
              value: district.id,
            }))}
            placeholder="Выбрать район"
          />
        </FormItem>
        <FormItem className="col-span-2" label="Город" name="additional.cityId">
          <Select
            disabled={!values.additional.districtId}
            name="additional.cityId"
            options={[...locations, { id: 0, nameRu: 'Другое' }].map((location) => ({
              label: location.nameRu,
              value: location.id,
            }))}
            placeholder="Выбрать город"
          />
        </FormItem>
        {isEdit && (
          <FormItem className="col-span-6" label="Методички" name="guidelineIds">
            <Select
              disabled={!values}
              mode="multiple"
              name="guidelineIds"
              optionFilterProp="label"
              options={guidelines.data?.data?.content.map(({ grade, id, name, version }) => ({
                label: `${name} - ${grade} (v.${version})`,
                value: id,
              }))}
              placeholder="Методички"
            />
          </FormItem>
        )}
        {values.additional.cityId === 0 && (
          <FormItem className="col-span-6" label="Населенный пункт" name="locationText">
            <Input name="locationText" placeholder="Выбрать населенный пункт" suffix />
          </FormItem>
        )}
        <FormItem className="col-span-6" label="Адрес" name="address">
          <Input.TextArea name="address" prefix="" />
        </FormItem>
      </div>
      <Divider />
      <div className="grid grid-cols-3 gap-4">
        <FormItem className="col-span-1" label="Язык обучения" name="language">
          <Select
            name="language"
            options={filteredLocalizations({
              defaultEducationFormat: defaultEducationFormat ?? 'GENERAL',
              isDev:
                process.env.REACT_APP_API_URL.includes('api.dev') ||
                process.env.NODE_ENV == 'development',
            }).map((lan) => ({
              label: IEnumLocalization[lan],
              value: lan,
            }))}
            placeholder="Выбрать язык"
          />
        </FormItem>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <FormItem label="Количество целевых классов" name="numberOfTargetClasses">
          <InputNumber
            className="w-full"
            max={999999}
            min={1}
            name="numberOfTargetClasses"
            prefix
          />
        </FormItem>
        <FormItem label="Общее количество учеников" name="amountOfStudents">
          <InputNumber className="w-full" max={999999} min={1} name="amountOfStudents" prefix />
        </FormItem>

        {defaultEducationFormat == 'GENERAL' && (
          <FormItem label="Шкала оценки" name="gradeType">
            <Select
              name="gradeType"
              options={Object.entries(IEnumSchoolGradeType).map(([value, label]) => ({
                label,
                value,
              }))}
              placeholder="Выбрать тип шкалы оценки"
            />
          </FormItem>
        )}
      </div>
      {!isEdit && <Divider />}
      {!isEdit && (
        <div>
          <h2>Супер-тренер</h2>
          <div className="grid grid-cols-3 gap-4">
            <FormItem label="Имя" name={`additional.superCoach.firstName`}>
              <Input name={`additional.superCoach.firstName`} suffix />
            </FormItem>
            <FormItem label="Фамилия" name={`additional.superCoach.lastName`}>
              <Input name={`additional.superCoach.lastName`} suffix />
            </FormItem>
            <FormItem label="Отчество" name={`additional.superCoach.middleName`}>
              <Input name={`additional.superCoach.middleName`} suffix />
            </FormItem>
            <FormItem label="Email" name={`additional.superCoach.email`}>
              <Input name={`additional.superCoach.email`} suffix />
            </FormItem>
            <FormItem label="Номер телефона" name={`additional.superCoach.phone`}>
              <MaskedInput
                mask="+7 000 000 00 00"
                name="additional.superCoach.phone"
                onChange={(event) =>
                  setFieldValue('additional.superCoach.phone', event.target.value)
                }
                status={
                  getIn(errors, 'additional.superCoach.phone') &&
                  getIn(touched, 'additional.superCoach.phone')
                    ? 'error'
                    : ''
                }
              />
            </FormItem>
          </div>
        </div>
      )}
      <Divider />
      <div>
        <h2>
          Тренеры
          {!isEdit && (
            <span className="text-base font-semibold text-ant-gray-7"> (необязательно)</span>
          )}
        </h2>

        <div className="flex flex-col gap-4">
          {values.additional.coaches?.map((coach, index) => (
            <div className="flex flex-col gap-4" key={`coach-${index}-${coach.coachUserId}}`}>
              <div className="grid grid-cols-3 gap-4">
                <FormItem label="Имя" name={`additional.coaches.${index}.firstName`}>
                  <Input name={`additional.coaches.${index}.firstName`} suffix />
                </FormItem>
                <FormItem label="Фамилия" name={`additional.coaches.${index}.lastName`}>
                  <Input name={`additional.coaches.${index}.lastName`} suffix />
                </FormItem>
                <FormItem label="Отчество" name={`additional.coaches.${index}.middleName`}>
                  <Input name={`additional.coaches.${index}.middleName`} suffix />
                </FormItem>
                <FormItem label="Email" name={`additional.coaches.${index}.email`}>
                  <Input name={`additional.coaches.${index}.email`} suffix />
                </FormItem>
                <FormItem label="Номер телефона" name={`additional.coaches.${index}.phone`}>
                  <MaskedInput
                    mask="+7 000 000 00 00"
                    name={`additional.coaches.${index}.phone`}
                    onChange={(event) =>
                      setFieldValue(`additional.coaches.${index}.phone`, event.target.value)
                    }
                    status={
                      getIn(errors, `additional.coaches.${index}.phone`) &&
                      getIn(touched, `additional.coaches.${index}.phone`)
                        ? 'error'
                        : ''
                    }
                    {...(isEdit &&
                      values.additional.coaches?.[index].phone && {
                        value: convertPhoneToMaskPhone(
                          values.additional.coaches?.[index].phone ?? '',
                        ),
                      })}
                  />
                </FormItem>
                {coach.coachUserId ? (
                  <FormItem label="Статус" name={`coaches.${index}.status`}>
                    <StatusTag status={values.additional.coaches?.[index].status ?? 'DRAFT'} />
                  </FormItem>
                ) : (
                  (!isEdit || (values.additional.coaches ?? []).length > 1) && (
                    <FormItem label=" " name={`deleteButton`}>
                      <Button
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          const isSuperCoach = !!coach.isSuperCoach;

                          setFieldValue(
                            'additional.coaches',
                            values.additional.coaches?.filter(
                              (_, coachIndex) => coachIndex !== index,
                            ),
                          ).then(() => {
                            if (isSuperCoach) {
                              setFieldValue('additional.coaches.0.isSuperCoach', true);
                            }
                          });
                        }}
                      />
                    </FormItem>
                  )
                )}
              </div>
              {isEdit && (
                <div className="flex items-center gap-4">
                  <span className="text-center">Супер-тренер:</span>
                  <AntSwitch
                    checked={!!coach.isSuperCoach}
                    disabled={!!coach.isSuperCoach}
                    onChange={() => {
                      values.additional.coaches?.forEach((_, eachIndex) => {
                        setFieldValue(
                          `additional.coaches.${eachIndex}.isSuperCoach`,
                          eachIndex == index,
                        );
                      });
                    }}
                  />
                </div>
              )}

              {index !== (values.additional.coaches ?? []).length - 1 && <Divider />}
            </div>
          ))}
        </div>

        <Button
          className="w-full mt-4"
          ghost
          icon={<PlusOutlined />}
          onClick={() => {
            setFieldValue('additional.coaches', [
              ...(values.additional.coaches ?? []),
              shortCoachInitial,
            ]);
          }}
          type="primary"
        >
          Добавить нового тренера
        </Button>
      </div>
      <div className={clsx('grid gap-4 mt-8', !isEdit && 'grid-cols-5 ')}>
        {!isEdit && (
          <FormItem className="col-span-2" name="status">
            <div className="space-x-2">
              <span className="text-center">Запустить немедленно</span>
              <AntSwitch
                checked={values.status == 'ACTIVE'}
                onChange={(checked) => setFieldValue('status', checked ? 'ACTIVE' : 'DRAFT')}
              />
            </div>
          </FormItem>
        )}
        <div className="col-span-3 grid grid-cols-2 gap-4">
          <Button block className="grid-flow-row" onClick={onCancel}>
            Отменить
          </Button>
          <Button className="grid-flow-row" htmlType="submit" loading={isLoading} type="primary">
            Сохранить
          </Button>
        </div>
      </div>
    </Form>
  );
};
