import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgMenuIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg height="24px" viewBox="0 0 384 384" width="24px" {...props}>
    <path
      d="M192.19,111.64c-42.03,0-84.06,.01-126.09-.01-10.64,0-17.86-6.94-17.48-16.61,.32-8.03,6.75-14.62,14.78-15.13,.87-.06,1.75-.06,2.62-.06,84.19,0,168.37,0,252.56,0,9.15,0,15.24,4.52,17.12,12.53,2.15,9.17-4.63,18.45-14.05,19.18-1.61,.13-3.24,.1-4.86,.1-41.53,0-83.06,0-124.6,0Z"
      fill="currentColor"
    />
    <path
      d="M192.3,207.49c-42.16,0-84.31,.02-126.47-.01-10.51,0-17.75-7.2-17.21-16.87,.44-7.87,6.81-14.34,14.66-14.86,1.49-.1,2.99-.08,4.49-.08,83.06,0,166.13-.01,249.19,.03,2.71,0,5.55,.08,8.11,.84,7.37,2.21,11.92,9.63,10.89,17.12-1.05,7.6-7.28,13.35-14.96,13.76-1.49,.08-2.99,.06-4.49,.06-41.41,0-82.82,0-124.22,0Z"
      fill="currentColor"
    />
    <path
      d="M192.34,271.52c42.16,0,84.31-.02,126.47,.01,10.38,0,17.56,6.93,17.25,16.46-.26,8.04-6.64,14.71-14.65,15.26-1.49,.1-2.99,.08-4.49,.08-83.06,0-166.13,.01-249.19-.03-2.83,0-5.81-.1-8.47-.94-7.15-2.25-11.55-9.64-10.56-17,1.02-7.59,7.26-13.59,14.93-13.74,11.47-.22,22.95-.1,34.42-.1,31.43,0,62.86,0,94.29,0Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgMenuIcon);
export default Memo;
