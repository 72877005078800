import { IFilters } from '@chess-class/sandbox';
import { InputFilter } from '@chess-class/sandbox/components';
import { modelKeys, paginationBaseFilters } from '@chess-class/sandbox/consts';

export const reviewFilters: IFilters = {
  ...paginationBaseFilters,
  [modelKeys.reviews.adminReviews.userFullName]: {
    input: <InputFilter placeholder="Введите имя" />,
    queries: [{ key: 'userFullName' }],
    sort: true,
  },
  [modelKeys.reviews.adminReviews.schoolName]: {
    input: <InputFilter placeholder="Введите название школы" />,
    queries: [{ key: 'schoolName' }],
    sort: true,
  },

  sort: { queries: [{ default: 'createdAt' }] },
  status: { sort: true },
};
