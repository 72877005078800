import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgUserIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg height="24px" viewBox="0 0 512 512" width="24px" {...props}>
    <g fill="currentColor" stroke="none" transform="translate(0,512) scale(0.100000,-0.100000)">
      <path d="M2370 5114 c-19 -2 -78 -9 -130 -15 -791 -90 -1522 -586 -1924 -1305 -146 -262 -252 -587 -297 -914 -20 -143 -18 -527 4 -670 90 -586 331 -1065 738 -1467 63 -62 155 -145 204 -185 641 -512 1494 -684 2290 -461 929 260 1646 1043 1819 1987 156 845 -103 1682 -705 2285 -399 398 -879 639 -1449 726 -100 15 -472 28 -550 19z m395 -304 c785 -72 1480 -553 1825 -1264 106 -217 166 -409 206 -661 25 -154 25 -496 0 -655 -60 -384 -207 -729 -442 -1040 -72 -94 -239 -280 -253 -280 -4 0 -18 33 -30 73 -120 374 -421 723 -782 906 l-116 59 80 64 c187 147 324 360 388 603 21 79 24 112 24 270 0 165 -3 188 -28 279 -101 364 -359 642 -714 769 -216 78 -510 78 -726 0 -355 -127 -613 -405 -714 -769 -25 -91 -28 -114 -28 -279 0 -158 3 -191 24 -270 64 -243 201 -456 388 -603 l80 -64 -116 -59 c-360 -183 -665 -536 -782 -907 -13 -39 -26 -72 -30 -72 -11 0 -155 156 -222 240 -271 339 -445 765 -486 1194 -71 728 230 1468 789 1939 464 390 1069 582 1665 527z m-10 -1141 c273 -66 487 -267 581 -544 38 -114 45 -302 16 -426 -39 -163 -113 -290 -236 -406 -91 -87 -182 -142 -306 -185 -91 -31 -101 -33 -250 -33 -149 0 -159 2 -250 33 -124 43 -215 98 -306 185 -123 116 -197 243 -236 406 -27 115 -22 310 12 416 131 417 553 657 975 554z m66 -1929 c381 -78 708 -324 887 -669 55 -105 107 -252 118 -329 l6 -49 -73 -47 c-102 -66 -334 -178 -455 -219 -127 -44 -278 -81 -419 -104 -155 -24 -499 -24 -650 1 -317 51 -603 155 -854 311 l-93 58 5 46 c7 62 61 220 104 306 125 250 339 463 591 590 125 62 303 112 457 129 79 8 278 -4 376 -24z" />
    </g>
  </svg>
);
const Memo = memo(SvgUserIcon);
export default Memo;
