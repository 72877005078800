import { FC } from 'react';
import { Button, Modal, Skeleton } from 'antd';
import { AxiosError } from 'axios';
import { Formik } from 'formik';
import { Form, FormItem, Input, Select } from 'formik-antd';

import {
  api,
  filteredLocalizations,
  IAuthorCreateRequest,
  IBackendError,
  IEnumAuthorRank,
  IEnumLocalizationGenitive,
} from '@chess-class/sandbox';
import { localizationsToObject } from '@chess-class/sandbox/utils';

import { CountryImageText } from '~/components/atoms/CountryImageText';
import { NotFoundResult } from '~/components/atoms/NotFoundResult';
import { useEducationFormat } from '~/context/EducationFormContext';

type AuthorEditModalType = {
  id?: string;
  isOpen: boolean;
  onCancel: () => void;
};

export const AuthorEditModal: FC<AuthorEditModalType> = ({ id = '', isOpen, onCancel }) => {
  const authorQuery = api.content.authors.useAuthor({ id });
  const author = authorQuery.data?.data;
  const authorUpdate = api.content.authors.useAuthorUpdate();
  const { defaultEducationFormat } = useEducationFormat();

  return (
    <Modal footer={null} onCancel={onCancel} open={isOpen}>
      {authorQuery.isLoading ? (
        <Skeleton active round />
      ) : !author ? (
        <NotFoundResult title="Автор не найден" />
      ) : (
        <Formik<IAuthorCreateRequest>
          enableReinitialize
          initialValues={
            author
              ? {
                  rank: author.rank ?? 'GM',
                  translations: author.translations,
                }
              : api.content.authors.helpers.authorCreateInitial
          }
          onSubmit={(values, { resetForm }) => {
            authorUpdate
              .mutateAsync({ id, ...values })
              .then(() => {
                onCancel();
                resetForm();
              })
              .catch((error: AxiosError<IBackendError>) => {
                if (error.response?.status == 422) {
                  resetForm({
                    errors: {
                      translations: localizationsToObject(() => ({
                        name: 'Такое название уже существует',
                      })),
                    },
                    values,
                  });
                }
              });
          }}
          validationSchema={api.content.authors.helpers.authorCreateValidation}
        >
          {() => (
            <Form className="space-y-8" layout="vertical">
              <FormItem label="Звание автора" name="rank">
                <Select
                  name="rank"
                  options={Object.keys(IEnumAuthorRank).map((rank) => ({
                    label: rank,
                    value: rank,
                  }))}
                />
              </FormItem>

              {filteredLocalizations({
                defaultEducationFormat: defaultEducationFormat ?? 'GENERAL',
                isDev:
                  process.env.REACT_APP_API_URL.includes('api.dev') ||
                  process.env.NODE_ENV == 'development',
              }).map((locale) => (
                <FormItem
                  key={`form-item-${locale}`}
                  label={
                    <CountryImageText
                      src={locale}
                      text={`Имя автора на ${IEnumLocalizationGenitive[locale]}`}
                      width={14}
                    />
                  }
                  name={`translations.${locale}.name`}
                >
                  <Input
                    maxLength={60}
                    name={`translations.${locale}.name`}
                    placeholder="Имя автора"
                    showCount
                    suffix
                    type="text"
                  />
                </FormItem>
              ))}

              <div className="grid md:grid-cols-2 gap-5">
                <Button block onClick={onCancel}>
                  Отменить
                </Button>

                <Button htmlType="submit" loading={authorUpdate.isLoading} type="primary">
                  Сохранить
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};
