import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input } from 'formik-antd';

import { api, IAdminInviteCompleteConfirmPasswordType } from '@chess-class/sandbox';

import { PageMeta } from '~/context/PageMetaContext';

const RegisterPage: FC = () => {
  const { link } = useParams();

  const register = api.user.adminAuth.useAdminInviteComplete();

  return (
    <>
      <PageMeta title="Регистрация" />

      <Formik<IAdminInviteCompleteConfirmPasswordType>
        initialValues={{
          ...api.user.adminAuth.helpers.adminInviteCompleteInitial,
          linkCode: link || '',
        }}
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSubmit={({ confirmPassword, ...values }) => {
          register.mutate(values);
        }}
        validationSchema={api.user.adminAuth.helpers.adminInviteCompleteValidation}
      >
        <Form className="mt-2 sm:mt-6 space-y-8" layout="vertical">
          <h2 className="text-center text-4xl text-gray-900">Добро пожаловать!</h2>

          <FormItem label="Фамилия" name="lastName">
            <Input maxLength={40} name="lastName" placeholder="example" showCount suffix />
          </FormItem>
          <FormItem label="Имя" name="firstName">
            <Input maxLength={40} name="firstName" placeholder="example" showCount suffix />
          </FormItem>
          <FormItem label="Отчество" name="middleName">
            <Input maxLength={40} name="middleName" placeholder="example" showCount suffix />
          </FormItem>

          <FormItem label="Новый пароль" name="password">
            <Input.Password maxLength={30} name="password" placeholder="********" showCount />
          </FormItem>

          <FormItem label="Повтор пароля" name="confirmPassword">
            <Input.Password
              maxLength={30}
              name="confirmPassword"
              placeholder="********"
              showCount
            />
          </FormItem>

          <Button block htmlType="submit" loading={register.isLoading} size="large" type="primary">
            Сохранить
          </Button>
        </Form>
      </Formik>
    </>
  );
};

export default RegisterPage;
