import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgBiImageIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="currentColor"
    height="24px"
    stroke="currentColor"
    strokeWidth={0}
    viewBox="0 0 24 24"
    width="24px"
    {...props}
  >
    <circle cx={7.499} cy={9.5} fill="currentColor" r={1.5} stroke="none" />
    <path d="m10.499 14-1.5-2-3 4h12l-4.5-6z" fill="currentColor" stroke="none" />
    <path
      d="M19.999 4h-16c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zm-16 14V6h16l.002 12H3.999z"
      fill="currentColor"
      stroke="none"
    />
  </svg>
);
const Memo = memo(SvgBiImageIcon);
export default Memo;
