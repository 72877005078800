import { FC, useState } from 'react';
import { Button } from 'antd';
import clsx from 'clsx';
import { Formik } from 'formik';
import { Form, FormItem, Input } from 'formik-antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import {
  api,
  IAppSettingsRequest,
  IAppSettingUpdateRequest,
  IEnumSettingName,
  IEnumSettingNameKeys,
  ISetting,
} from '@chess-class/sandbox';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';
import { notify } from '@chess-class/sandbox/utils';

import { PencilIcon } from '~/components/atoms/icons';
import { PageMeta } from '~/context/PageMetaContext';
import { checkEditPermission } from '~/utils/checkEditPermission';

import { appSettingsFilters } from '~mSettings/consts/filters/appSettingsFilters';
import {
  AppSettingsPageActions,
  appSettingsPageActions,
} from '~mSettings/consts/pageActions/AppSettingsPageActions';

const settingOrderList = ['TERMS_URL', 'ABOUT_URL', 'SUPPORT_EMAIL'];

const AppSettingsPage: FC = () => {
  const { actioned } = useUrlFilteredActioned<IAppSettingsRequest, AppSettingsPageActions>(
    appSettingsFilters,
    appSettingsPageActions,
  );

  const appSettings = api.settings.useSettings({
    settingNames: ['ABOUT_URL', 'SUPPORT_EMAIL', 'TERMS_URL'],
  });

  const [editKey, setEditKey] = useState<IEnumSettingNameKeys>();

  const canEditSettings = checkEditPermission('settings');

  const settingUpdate = api.settings.useSettingUpdate();

  const sorteredSettingsList = (settingList: ISetting[]) => {
    return settingList
      ? settingList
          .map((item) => ({ ...item }))
          .sort((a, b) => {
            const indexA = settingOrderList.indexOf(a.name);
            const indexB = settingOrderList.indexOf(b.name);

            return indexA - indexB;
          })
      : [];
  };

  return (
    <>
      <PageMeta
        selectedMenuKeys={['settings']}
        subTitle={actioned.action == 'edit' ? 'Изменение настройки' : ''}
        title="Настройки"
      />

      <div className="grid grid-cols-2">
        <div>
          <h2>Настройки</h2>

          <div className="flex flex-col space-y-4 mt-8">
            {sorteredSettingsList(appSettings.data?.data.content ?? []).map((appSetting) => (
              <div className="flex items-center space-x-4" key={appSetting.name}>
                <div className={clsx('min-w-[200px]')}>{IEnumSettingName[appSetting.name]}:</div>
                <div className="min-w-[300px]">
                  {editKey === appSetting.name ? (
                    <Formik<IAppSettingUpdateRequest>
                      enableReinitialize
                      initialValues={{ ...appSetting }}
                      onSubmit={(values, { resetForm }) => {
                        settingUpdate.mutateAsync(values).then((data) => {
                          if (data.status == 201 || data.status == 200) {
                            notify(
                              'success',
                              `Настройка ${IEnumSettingName[appSetting.name]} изменен`,
                            );
                            setEditKey(undefined);
                            resetForm();
                          }
                        });
                      }}
                      validationSchema={api.settings.helpers.appSettingUpdateValidation}
                    >
                      {({ isValid }) => (
                        <Form layout="vertical">
                          <div className="flex gap-2 items-center">
                            <FormItem className="w-full mb-0" name="string">
                              <Input
                                name="string"
                                placeholder={IEnumSettingName[appSetting.name]}
                                suffix
                                type="text"
                              />
                            </FormItem>

                            <div className="flex space-x-2">
                              <Button
                                danger
                                icon={<CloseOutlined />}
                                onClick={() => setEditKey(undefined)}
                              />
                              <Button
                                className={clsx(
                                  editKey === appSetting.name &&
                                    'text-ant-green-6 border-ant-green-6',
                                )}
                                disabled={!isValid}
                                ghost
                                htmlType="submit"
                                icon={<CheckOutlined />}
                                onClick={() => {
                                  setEditKey(appSetting.name);
                                }}
                                type="primary"
                              />
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    <div className="flex w-full items-center space-x-2">
                      {['ABOUT_URL', 'TERMS_URL'].includes(appSetting.name) && (
                        <a href={appSetting.string ?? ''} rel="noreferrer" target="_blank">
                          {appSetting.string}
                        </a>
                      )}

                      {['SUPPORT_EMAIL'].includes(appSetting.name) && (
                        <a href={`mailto:${appSetting.string ?? ''}`} rel="noreferrer">
                          {appSetting.string}
                        </a>
                      )}
                      {canEditSettings && (
                        <Button
                          ghost
                          icon={<PencilIcon />}
                          onClick={() => {
                            setEditKey(appSetting.name);
                          }}
                          type="primary"
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AppSettingsPage;
