import { IPageActions } from '@chess-class/sandbox';

enum StaffsPageActionsEnum {
  'edit',
  'invite',
}

export type IStaffsPageActions = {
  action: keyof typeof StaffsPageActionsEnum;
  actionId: string;
};

export const staffsPageActions: IPageActions<IStaffsPageActions> = {
  action: {
    allowed: (value) => Object.keys(StaffsPageActionsEnum).includes(value),
    default: '',
  },
  actionId: { default: '' },
};
