import { IFilters } from '@chess-class/sandbox';
import { modelKeys, paginationBaseFilters } from '@chess-class/sandbox/consts';

export const guidelineChapterFilter: IFilters = {
  ...paginationBaseFilters,
  [modelKeys.content.chapter.numId]: {
    queries: [{ key: 'numId' }],
    sort: true,
  },
};
