import { FC } from 'react';
import { Formik } from 'formik';

import {
  api,
  IExercise,
  IExerciseProblem,
  IExerciseProblemEditRequest,
} from '@chess-class/sandbox';
import { notify } from '@chess-class/sandbox/utils';

import ProblemForm from '~mMaterials/exercises/exercises/components/molecules/ProblemForm';

export const ProblemEditFormik: FC<{
  exercise: IExercise & { problem: IExerciseProblem };
  onCancel: () => void;
}> = ({ exercise, onCancel }) => {
  const problemEdit = api.content.exercises.useExerciseProblemEdit();

  return (
    <Formik<IExerciseProblemEditRequest>
      enableReinitialize
      initialValues={{
        ...exercise,
        chapterId: exercise.chapter?.id as string,
        gamePhase: exercise.gamePhase,
        isCapitalization: exercise.isCapitalization,
        isTactic: exercise.isTactic,
        level: exercise.level,
        status: exercise.status,
        target: exercise.target,
      }}
      onSubmit={(values, { resetForm }) => {
        problemEdit.mutateAsync(values).then((data) => {
          if (data.status == 201 || data.status == 200) {
            notify('success', 'Задача изменена');
            onCancel();
            resetForm();
          }
        });
      }}
      validationSchema={api.content.exercises.helpers.exerciseProblemEditValidation}
    >
      <ProblemForm
        exercise={exercise}
        isEdit
        isLoading={problemEdit.isLoading}
        onCancel={onCancel}
      />
    </Formik>
  );
};
