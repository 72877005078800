import { Button, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { DeleteOutlined } from '@ant-design/icons';

import { authorKeys, IAuthor, IEnumLocalizationGenitive } from '@chess-class/sandbox';
import { localizationsToObjectArray } from '@chess-class/sandbox/utils';

import { PencilIcon } from '~/components/atoms/icons';

export const authorsColumns = ({
  canEdit,
  onClickDelete,
  onClickEdit,
  onClickView,
}: {
  canEdit: boolean;
  onClickDelete: ({ id }: { id: string }) => void;
  onClickEdit: ({ id }: { id: string }) => void;
  onClickView: ({ id }: { id: string }) => void;
}): ColumnsType<IAuthor> => {
  const columns: ColumnsType<IAuthor> = [
    {
      dataIndex: authorKeys.rank,
      title: 'Звание',
    },
    { key: '' },
    ...localizationsToObjectArray((locale) => ({
      key: `author-${locale}`,
      render: (_, { id, translations }) => (
        <Button onClick={() => onClickView({ id })} type="link">
          {translations[locale]?.name}
        </Button>
      ),
      title: `Имя на ${IEnumLocalizationGenitive[locale]}`,
    })),
  ];

  if (!canEdit) {
    return columns;
  }

  return [
    ...columns,
    {
      key: 'actions',
      render: (author: IAuthor) => {
        return (
          <div className="flex gap-x-1.5">
            <Button
              ghost
              icon={<PencilIcon />}
              onClick={() => onClickEdit({ id: author.id })}
              type="primary"
            />

            <Popconfirm
              cancelText="Отменить"
              icon={<DeleteOutlined className="text-ant-red-5" />}
              okText="Удалить"
              okType="danger"
              onConfirm={() => onClickDelete({ id: author.id })}
              placement="topLeft"
              title={
                <span className="text-sm">
                  Вы уверены, что хотите удалить автора
                  <span className="font-bold"> &quot;{author.translations.ru?.name}&quot;</span>?
                </span>
              }
            >
              <Button danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </div>
        );
      },
      title: 'Действия',
    },
  ];
};
