import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from '~/components/molecules/routes/ProtectedRoute';
import NotFoundPage from '~/components/pages/errors/NotFoundPage';

import StaffsPage from '~mStaff/components/pages/StaffsPage';

export const StaffRouter = () => {
  return (
    <ProtectedRoute access="materials">
      <Routes>
        <Route element={<StaffsPage />} index />
        <Route element={<NotFoundPage />} path="*" />
      </Routes>
    </ProtectedRoute>
  );
};
