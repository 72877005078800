import { Button, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { AxiosResponse } from 'axios';
import {
  CaretRightOutlined,
  DeleteOutlined,
  MailOutlined,
  PauseCircleOutlined,
  PauseOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';

import { ICoachInviteResendRequest, ICoachToSchool } from '@chess-class/sandbox';
import { modelKeys } from '@chess-class/sandbox/consts';
import { notify } from '@chess-class/sandbox/utils';

import { PencilIcon } from '~/components/atoms/icons';
import { StatusTag } from '~/components/atoms/StatusTag';

export const coachColumns = ({
  canEdit,
  onClickActivate,
  onClickDeactivate,
  onClickDelete,
  onClickEdit,
  onClickInvitationResend,
  onClickSuperCoach,
}: {
  canEdit: boolean;
  onClickActivate: ({ id }: { id: string }) => void;
  onClickDeactivate: ({ id }: { id: string }) => void;
  onClickDelete?: ({ id }: { id: string }) => void;
  onClickEdit?: ({ id }: { id: string }) => void;
  onClickInvitationResend: (payload: ICoachInviteResendRequest) => Promise<AxiosResponse<void>>;
  onClickSuperCoach: ({ id, schoolId }: { id: string; schoolId: string }) => void;
}): ColumnsType<ICoachToSchool> => {
  const columns: ColumnsType<ICoachToSchool> = [
    {
      dataIndex: modelKeys.user.coachToSchool.firstName,
      render: (_, { firstName, lastName, middleName }) => `${firstName} ${lastName} ${middleName}`,
      title: 'ФИО',
    },
    {
      dataIndex: modelKeys.user.coachToSchool.schoolId,
      render: (_, { schoolName }) => (
        <p className={`flex items-center gap-1 mb-0 `}>{schoolName ?? '-'}</p>
      ),
      title: 'Школа',
    },
    {
      dataIndex: modelKeys.user.coachToSchool.email,
      title: 'Email тренера',
    },
    {
      dataIndex: modelKeys.user.coachToSchool.isSuperCoach,
      render: (isSuperCoach: ICoachToSchool['isSuperCoach']) => (
        <p className={`flex items-center gap-1 mb-0 `}>{isSuperCoach ? 'Да' : 'Нет'}</p>
      ),
      title: 'Супер-тренер',
    },
    {
      dataIndex: modelKeys.user.coachToSchool.phone,
      title: 'Номер телефона',
    },
    {
      dataIndex: modelKeys.user.coachToSchool.status,
      render: (status: ICoachToSchool['status']) => <StatusTag status={status} />,
      title: 'Статус',
    },
  ];

  if (!canEdit || (!onClickDelete && !onClickEdit && !onClickDelete)) {
    return columns;
  }

  return [
    ...columns,
    {
      key: 'action',
      render: (coach: ICoachToSchool) => (
        <div className="flex gap-x-1.5">
          {(coach.status == 'INVITED' || coach.status == 'NOT_INVITED') && (
            <Popconfirm
              cancelText="Отменить"
              icon={<MailOutlined className="text-ant-blue-5 text-base" />}
              okText="Отправить"
              okType="primary"
              onConfirm={() =>
                onClickInvitationResend({
                  email: coach.email,
                  schoolId: coach.schoolId ?? '',
                }).then(() => {
                  notify('success', 'Приглашение тренеру отправлено');
                })
              }
              placement="topLeft"
              title={
                <p className="text-sm mb-0">
                  Вы уверены, что хотите{' '}
                  <span className="font-semibold">
                    отправить приглашение тренеру &quot;
                    {`${coach.firstName} ${coach.lastName} ${coach.middleName}`}&quot;?
                  </span>
                </p>
              }
            >
              <Button ghost icon={<MailOutlined />} type="primary" />
            </Popconfirm>
          )}
          {coach.status != 'INVITED' && coach.status != 'NOT_INVITED' && (
            <Popconfirm
              cancelText="Отменить"
              icon={
                coach.status == 'ACTIVE' ? (
                  <PauseCircleOutlined className="text-ant-blue-5 text-base" />
                ) : (
                  <PlayCircleOutlined className="text-ant-blue-5 text-base" />
                )
              }
              okText={coach.status == 'ACTIVE' ? 'Приостановить' : 'Запустить'}
              okType="primary"
              onConfirm={() => {
                if (coach.isSuperCoach && coach.status == 'ACTIVE') {
                  onClickSuperCoach({ id: coach.id, schoolId: coach.schoolId ?? '' });
                } else if (!coach.isSuperCoach && coach.status == 'INACTIVE') {
                  onClickActivate({
                    id: coach.id,
                  });
                } else if (!coach.isSuperCoach && coach.status == 'ACTIVE') {
                  onClickDeactivate({
                    id: coach.id,
                  });
                }
              }}
              placement="topLeft"
              title={
                <p className="text-sm mb-0">
                  Вы уверены, что хотите
                  <span className="font-semibold">
                    {coach.status == 'ACTIVE' ? ' приостановить ' : ' запустить '}
                    тренера #{`${coach.firstName} ${coach.lastName} ${coach.middleName}`}?
                  </span>
                </p>
              }
            >
              <Button
                ghost
                icon={coach.status == 'ACTIVE' ? <PauseOutlined /> : <CaretRightOutlined />}
                type="primary"
              />
            </Popconfirm>
          )}
          {onClickEdit && (
            <Button
              ghost
              icon={<PencilIcon />}
              onClick={() => onClickEdit({ id: coach.id })}
              type="primary"
            />
          )}
          {onClickDelete && !coach.isSuperCoach && (
            <Popconfirm
              cancelText="Отменить"
              icon={<DeleteOutlined className="text-ant-red-5" />}
              okText="Удалить"
              okType="danger"
              onConfirm={() => onClickDelete({ id: coach.coachUserId as string })}
              placement="topLeft"
              title={
                <span className="text-sm">
                  Вы уверены, что хотите
                  <span className="font-bold">
                    {' '}
                    удалить тренера {coach.firstName + ' ' + coach.lastName}
                  </span>
                  ?
                </span>
              }
            >
              <Button danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </div>
      ),
      title: 'Действия',
    },
  ];
};
