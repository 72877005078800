import { FC } from 'react';
import { Modal } from 'antd';
import { AxiosError } from 'axios';
import { Formik } from 'formik';

import { api, ICoachInviteRequest } from '@chess-class/sandbox';
import { notify, phoneMaskSanitizer } from '@chess-class/sandbox/utils';

import { useEducationFormat } from '~/context/EducationFormContext';

import { CoachForm } from './CoachForm';

export const CoachCreateModal: FC<{
  isOpen: boolean;
  onCancel: () => void;
}> = ({ isOpen, onCancel }) => {
  const coachCreate = api.user.coachAuth.useCoachInvite();
  const demoCoachCreate = api.user.coachAuth.useDemoCoachInvite();

  const { defaultEducationFormat } = useEducationFormat();

  return (
    <Modal
      className="px-4 md:px-0 md:w-4/5 lg:w-3/4 top-10 max-w-[770px]"
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={
        <h1 className="text-xl m-0 space-x-1 flex items-center">
          <span>Добавить нового тренера</span>
        </h1>
      }
    >
      <Formik<ICoachInviteRequest>
        enableReinitialize
        initialValues={api.user.coachAuth.helpers.coachInviteInitial}
        onSubmit={(values, { resetForm }) => {
          values.isDemo
            ? demoCoachCreate
                .mutateAsync({
                  ...values,
                  educationFormat: defaultEducationFormat,
                  phone: phoneMaskSanitizer(values.phone ?? ''),
                })
                .then((data) => {
                  if (data.status == 201 || data.status == 200) {
                    notify('success', 'Тренеру отправлено приглашение!');
                    onCancel();
                    resetForm();
                  }
                })
                .catch((error: AxiosError) => {
                  if (error.response?.status == 422) {
                    notify('error', 'Тренер уже приглашен в данную школу');
                  }
                })
            : coachCreate
                .mutateAsync({
                  ...values,
                  educationFormat: defaultEducationFormat,
                  phone: phoneMaskSanitizer(values.phone ?? ''),
                })
                .then((data) => {
                  if (data.status == 201 || data.status == 200) {
                    notify('success', 'Тренеру отправлено приглашение!');
                    onCancel();
                    resetForm();
                  }
                })
                .catch((error: AxiosError) => {
                  if (error.response?.status == 422) {
                    notify('error', 'Тренер уже приглашен в данную школу');
                  }
                });
        }}
        validationSchema={api.user.coachAuth.helpers.coachInviteValidation}
      >
        <CoachForm isLoading={coachCreate.isLoading} onCancel={onCancel} />
      </Formik>
    </Modal>
  );
};
