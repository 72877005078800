import { type FC, useState } from 'react';
import { Button, Checkbox } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { useFormikContext } from 'formik';
import { Form, FormItem, Input, Select } from 'formik-antd';

import { api, ICoachInviteRequest } from '@chess-class/sandbox';

import { useEducationFormat } from '~/context/EducationFormContext';

type CoachFormProps = {
  edit?: boolean;
  isLoading: boolean;
  onCancel?: () => void;
};

export const CoachForm: FC<CoachFormProps> = ({ edit, isLoading, onCancel }) => {
  const { errors, setFieldValue, touched, values } = useFormikContext<ICoachInviteRequest>();
  const schools = api.schools.schools.useSchools({ status: 'ACTIVE', unpaged: true });
  const { defaultEducationFormat } = useEducationFormat();
  const [isDemoAccess, setIsDemoAccess] = useState(false);

  return (
    <Form className="flex flex-col gap-2 mt-6" layout="vertical">
      <div className="grid grid-cols-6 gap-2">
        <FormItem className="col-span-3" label="Тип школы" name="educationFormat">
          <Input
            disabled
            name="educationFormat"
            placeholder="Тип школы"
            value={defaultEducationFormat === 'GENERAL' ? 'Школа' : 'Частная школа'}
          />
        </FormItem>
        <FormItem className="col-span-6" name="isDemo">
          <Checkbox
            checked={isDemoAccess}
            onChange={(e) => {
              setIsDemoAccess(e.target.checked);
              setFieldValue('isDemo', e.target.checked);
            }}
          >
            Демо доступ
          </Checkbox>
        </FormItem>
        {!isDemoAccess && (
          <FormItem className="col-span-3" label="Школы" name="schoolId">
            <Select
              allowClear
              className="gap-y-2"
              disabled={edit}
              name="schoolId"
              optionFilterProp="label"
              options={
                schools.data?.data.content
                  ? schools.data?.data.content.map((school) => ({
                      label: school.name,
                      value: school.id,
                    }))
                  : []
              }
              placeholder="Выберите школу"
              showSearch
            />
          </FormItem>
        )}

        {isDemoAccess && (
          <FormItem className="col-span-3" label="Количество дней" name="demoDays">
            <Input name="demoDays" placeholder="Количество дней" type="number" />
          </FormItem>
        )}
        {isDemoAccess && (
          <FormItem className="col-span-3" label="Язык обучения" name="language">
            <Select
              allowClear
              className="gap-y-2"
              name="language"
              options={[
                { label: 'Русский', value: 'ru' },
                { label: 'Английский', value: 'en' },
                { label: 'Французский', value: 'fr' },
                { label: 'Немецкий', value: 'de' },
                { label: 'Казахский', value: 'kz' },
              ]}
              placeholder="Выберите язык"
              showSearch
            />
          </FormItem>
        )}
      </div>

      <div className="grid grid-cols-3 gap-4">
        <FormItem label="Фамилия" name="lastName">
          <Input maxLength={100} name="lastName" placeholder="Фамилия" />
        </FormItem>
        <FormItem label="Имя" name="firstName">
          <Input maxLength={100} name="firstName" placeholder="Имя" />
        </FormItem>
        <FormItem label="Отчество" name="middleName">
          <Input maxLength={100} name="middleName" placeholder="Отчество" />
        </FormItem>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <FormItem label="Почта" name="email">
          <Input
            disabled={edit}
            maxLength={40}
            name="email"
            placeholder="email@example.com"
            type="email"
          />
        </FormItem>

        <FormItem label="Номер телефона" name="phone">
          <MaskedInput
            mask="+7 000 000 00 00"
            name="phone"
            onChange={(event) => setFieldValue('phone', event.target.value)}
            status={errors.phone && touched.phone ? 'error' : ''}
            value={values.phone}
          />
        </FormItem>
      </div>

      <div className="grid md:grid-cols-2 gap-5">
        <Button block onClick={onCancel}>
          Отменить
        </Button>

        <Button block htmlType="submit" loading={isLoading} type="primary">
          <span className="max-w-full overflow-ellipsis overflow-hidden">Сохранить</span>
        </Button>
      </div>
    </Form>
  );
};
