import { Button, Popconfirm, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { DeleteOutlined } from '@ant-design/icons';

import { IVideoFile } from '@chess-class/sandbox';
import { modelKeys } from '@chess-class/sandbox/consts';

import { CountryImageText } from '~/components/atoms/CountryImageText';
import { PencilIcon } from '~/components/atoms/icons';

export const videoFilesColumns = ({
  canEdit,
  onClickDelete,
  onClickEdit,
  onClickView,
}: {
  canEdit: boolean;
  onClickDelete: ({ uuid }: { uuid: string }) => void;
  onClickEdit?: ({ uuid }: { uuid: string }) => void;
  onClickView: ({ uuid }: { uuid: string }) => void;
}): ColumnsType<IVideoFile> => {
  const columns: ColumnsType<IVideoFile> = [
    {
      dataIndex: modelKeys.content.videoFile.title,
      render: (title: IVideoFile['title'], { hlsUrl, isUploading, uuid, videoFileName }) => (
        <Tooltip
          title={
            isUploading || !videoFileName || !hlsUrl ? 'Видео еще не переведено в hls формат' : ''
          }
        >
          <Button
            danger={isUploading || !videoFileName || !hlsUrl}
            onClick={() => onClickView({ uuid })}
            type="link"
          >
            {title ?? '-'}
          </Button>
        </Tooltip>
      ),
      title: 'Название видео',
    },
    {
      dataIndex: modelKeys.content.videoFile.uuid,
      title: 'UUID',
    },
    {
      dataIndex: modelKeys.content.videoFile.createdDate,
      render: (createdDate: IVideoFile['createdDate']) =>
        createdDate ? `${moment(createdDate).format('LLL')}` : '-',
      title: 'Дата создания',
    },
    {
      dataIndex: modelKeys.content.videoFile.author,
      key: modelKeys.content.videoFile.author,
      render: (_, videoFile) =>
        videoFile.author ? (
          <div className="space-y-2">
            {videoFile.author?.translations.ru && (
              <div className="whitespace-nowrap mb-0 flex items-center gap-1" key="ru">
                <CountryImageText src="ru" width={15} />
                {videoFile.author?.translations.ru.name}
              </div>
            )}
          </div>
        ) : (
          '-'
        ),
      title: 'Автор',
    },
  ];

  if (!canEdit) {
    return columns;
  }

  return [
    ...columns,
    {
      key: 'action',
      render: ({ hlsUrl, isUploading, title, uuid }: IVideoFile) => (
        <div className="flex gap-x-1.5">
          {!!hlsUrl && !isUploading && onClickEdit && (
            <Button
              ghost
              icon={<PencilIcon />}
              onClick={() => onClickEdit({ uuid })}
              type="primary"
            />
          )}
          <Popconfirm
            cancelText="Отменить"
            icon={<DeleteOutlined className="text-ant-red-5" />}
            okText="Удалить"
            okType="danger"
            onConfirm={() => onClickDelete({ uuid })}
            placement="topLeft"
            title={
              <span className="text-sm">
                Вы уверены, что хотите удалить видеофайл
                <span className="font-bold"> &quot;{title}&quot;</span>?
              </span>
            }
          >
            <Button danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </div>
      ),
      title: 'Действия',
    },
  ];
};
