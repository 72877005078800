import { ChangeEvent, FC, useState } from 'react';
import { Button, Collapse, Input, Pagination, Select } from 'antd';
import { debounce } from 'lodash';
import { SearchOutlined } from '@ant-design/icons';

import { api, IEnumGuidelineStatus, IGuideline, IGuidelinesRequest } from '@chess-class/sandbox';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';

import BaseSkeleton from '~/components/atoms/BaseSkeleton';
import { AdminTableSelfManaged } from '~/components/organisms/adminTableSelfManaged/AdminTableSelfManaged';
import { useEducationFormat } from '~/context/EducationFormContext';
import { PageMeta } from '~/context/PageMetaContext';
import { guidelineChapterFilter } from '~/modules/materials/chapters/consts/filters/guidelineChapterFilter';
import { userPlayLevels } from '~/modules/materials/exercises/exercises/types/UserPlayLevel';
import { checkEditPermission } from '~/utils/checkEditPermission';

import { chapterFilters } from '~mMaterials/chapters/consts/filters/chapterFilters';
import { GuidelineCollapseLabel } from '~mMaterials/guidelines/components/atoms/GuidelineCollapseLabel';
import { GuidelineChapterList } from '~mMaterials/guidelines/components/molecules/GuidelineChapterList';
import { GuidelineCreateModal } from '~mMaterials/guidelines/components/organisms/GuidelineCreateModal';
import { GuidelineEditModal } from '~mMaterials/guidelines/components/organisms/GuidelineEditModal';
import { guidelineChapterColumns } from '~mMaterials/guidelines/consts/columns/guidelineChapterColumns';
import {
  guidelinesPageActions,
  IGuidelinesPageActions,
} from '~mMaterials/guidelines/consts/pageActions/ChaptersPageActions';

const PAGE_SIZE = 10;

export const GuidelinesPage: FC = () => {
  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    IGuidelinesRequest,
    IGuidelinesPageActions
  >(chapterFilters, guidelinesPageActions);
  const { defaultEducationFormat } = useEducationFormat();

  const [editGuide, setEditGuide] = useState<IGuideline>();

  const canEditMaterials = checkEditPermission('materials');

  const guidelinesQuery = api.guideline.guideline.useGuidelines({
    ...filtered,
    educationFormat: defaultEducationFormat,
  });
  const guidelines = guidelinesQuery.data?.data;

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => onFilter({ name: e.target.value });

  const debouncedOnChange = debounce(onSearch, 300);

  return (
    <div className="flex flex-col gap-4">
      <PageMeta
        openMenuKeys={['materials']}
        selectedMenuKeys={['materials-guidelines']}
        title="Методичка"
      />

      <div className="flex gap-8 items-center">
        <Input
          allowClear
          className="h-12 pr-0 py-0"
          onChange={debouncedOnChange}
          placeholder="Введите название"
          suffix={
            <Button
              className="w-12 h-full border-l border-0 rounded-none bg-transparent"
              icon={<SearchOutlined className="text-xl" />}
            />
          }
        />

        {canEditMaterials && (
          <div className="flex justify-end gap-3">
            <Button
              className="sm:px-12"
              onClick={() => setActioned({ action: 'create' })}
              size="large"
              type="primary"
            >
              Новая методичка
            </Button>
          </div>
        )}
      </div>

      <div className="flex items-center gap-4 justify-between">
        <div className="flex items-center gap-4">
          <Select
            allowClear
            className="min-w-[100px]"
            onChange={(classNumber) =>
              onFilter({ grades: classNumber ? [classNumber] : undefined })
            }
            options={
              defaultEducationFormat == 'GENERAL'
                ? [1, 2, 3, 4].map((classNumber) => ({
                    label: `${classNumber}-й класс`,
                    value: classNumber,
                  }))
                : Object.keys(userPlayLevels)
                    // .map(Number)
                    .map((level) => ({
                      label: userPlayLevels[level],
                      value: Number(level),
                    }))
            }
            placeholder={defaultEducationFormat == 'GENERAL' ? 'Класс' : 'Уровень'}
            value={filtered.grades?.[0]}
          />
          <Select
            allowClear
            className="min-w-[150px]"
            onChange={(status) => onFilter({ status })}
            options={Object.entries(IEnumGuidelineStatus).map(([value, label]) => ({
              label,
              value,
            }))}
            placeholder="Статус"
            value={filtered.status}
          />
        </div>
        <Pagination
          current={
            typeof filtered.page == 'string'
              ? parseInt(filtered.page) + 1
              : (filtered.page || 0) + 1
          }
          defaultPageSize={PAGE_SIZE}
          locale={{ items_per_page: '' }}
          onChange={(page) => onFilter({ page: page - 1 })}
          onShowSizeChange={(page, size) => onFilter({ page: page - 1, size })}
          showSizeChanger
          total={guidelines?.totalElements || 0}
        />
      </div>

      {guidelinesQuery.isFetching ? (
        <BaseSkeleton />
      ) : !guidelines ? (
        <div className="grid justify-center place-items-center gap-3 mt-6">
          <img alt="guidelines-not-found.png" src="/images/guidelines-not-found.png" />
          <span className="text-gray-500 font-semibold">
            Здесь появится список ваших методичек.
          </span>
          <span className="text-gray-500 font-semibold">
            <span
              className="text-blue-500 hover:text-blue-300 hover:cursor-pointer"
              onClick={() => setActioned({ action: 'create' })}
            >
              Создайте
            </span>{' '}
            первую уже сейчас
          </span>
        </div>
      ) : (
        <Collapse
          expandIconPosition="end"
          ghost
          items={guidelines.content.map((guide) => ({
            children:
              guide.status == 'PUBLISHED' ? (
                <AdminTableSelfManaged
                  columns={guidelineChapterColumns}
                  filters={guidelineChapterFilter}
                  query={api.guideline.guidelineChapters.useGuidelineChapters}
                  queryData={{ guidelineId: guide.id, page: 0, pageSize: 20 }}
                />
              ) : (
                <GuidelineChapterList guide={guide} />
              ),
            className: 'rounded-lg my-6 p-3',
            label: (
              <GuidelineCollapseLabel
                editable={canEditMaterials}
                guideline={guide}
                onEdit={() => {
                  setEditGuide(guide);
                  setActioned({ action: 'edit' });
                }}
              />
            ),
            style: { boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' },
          }))}
        />
      )}

      <GuidelineCreateModal isOpen={actioned.action == 'create'} onCancel={clearParams} />
      <GuidelineEditModal
        guide={editGuide}
        isOpen={actioned.action == 'edit' && !!editGuide}
        onCancel={clearParams}
      />
    </div>
  );
};
