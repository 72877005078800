import { FC } from 'react';

import { api, IExerciseGroupsRequest } from '@chess-class/sandbox';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';

import { AdminTable } from '~/components/organisms/adminTable/AdminTable';
import { useEducationFormat } from '~/context/EducationFormContext';
import { PageMeta } from '~/context/PageMetaContext';
import { checkEditPermission } from '~/utils/checkEditPermission';

import { ExerciseGroupEditModal } from '~mMaterials/exercises/exercisesGroups/components/organisms/ExerciseGroupEditModal';
import { ExerciseGroupViewModal } from '~mMaterials/exercises/exercisesGroups/components/organisms/ExerciseGroupViewModal';
import { exerciseGroupColumns } from '~mMaterials/exercises/exercisesGroups/consts/columns/exerciseGroupColumns';
import { exerciseGroupsFilters } from '~mMaterials/exercises/exercisesGroups/consts/filters/exerciseGroupsFilters';
import {
  exerciseGroupsPageActions,
  IExerciseGroupsPageActions,
} from '~mMaterials/exercises/exercisesGroups/consts/pageActions/IExerciseGroupsPageActions';
import { ExerciseTabs } from '~mMaterials/exercises/ExerciseTabs';

export const ExerciseGroupsPage: FC = () => {
  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    IExerciseGroupsRequest,
    IExerciseGroupsPageActions
  >(exerciseGroupsFilters, exerciseGroupsPageActions);
  const { defaultEducationFormat } = useEducationFormat();

  const exerciseGroups = api.content.exercisesGroups.useExerciseGroups({
    ...filtered,
    educationFormat: defaultEducationFormat,
  });

  const statusUpdate = api.content.exercisesGroups.useExerciseGroupStatusUpdate();
  const groupDelete = api.content.exercisesGroups.useExerciseGroupDelete();

  const canEditExercises = checkEditPermission('materials');

  return (
    <div className="flex flex-col gap-4">
      <ExerciseTabs active="groups" />

      <PageMeta
        openMenuKeys={['materials']}
        selectedMenuKeys={['materials-exercises']}
        subTitle={
          actioned.action == 'edit'
            ? 'Изменить коллекцию'
            : actioned.action == 'view'
              ? `Коллекцию задач #${actioned.actionId}`
              : ''
        }
        title="Коллекция задач"
      />

      <AdminTable
        columns={exerciseGroupColumns({
          canEdit: canEditExercises,
          onClickDelete: groupDelete.mutateAsync,
          onClickEdit: ({ id }) => setActioned({ action: 'edit', actionId: id }),
          onClickPause: statusUpdate.mutateAsync,
          onClickView: ({ id }) => setActioned({ action: 'view', actionId: id }),
        })}
        emptyDescription={<p>Коллекции задач не найдены</p>}
        query={exerciseGroups}
        tableFilter={{ filtered, filters: exerciseGroupsFilters, onFilter }}
      />

      <ExerciseGroupViewModal
        canEditExercises={canEditExercises}
        id={actioned.actionId}
        isOpen={actioned.action == 'view'}
        onCancel={clearParams}
        onOpenEdit={() => setActioned({ action: 'edit', actionId: actioned.actionId })}
      />

      <ExerciseGroupEditModal
        id={actioned.actionId}
        isOpen={actioned.action == 'edit'}
        onCancel={clearParams}
        onSubmit={() => setActioned({ action: 'view', actionId: actioned.actionId })}
      />
    </div>
  );
};
