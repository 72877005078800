import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Empty, Skeleton, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { AxiosError, AxiosResponse } from 'axios';
import Icon from '@ant-design/icons';
import { UseQueryResult } from '@tanstack/react-query';

import {
  IAdmin,
  IAuthor,
  IChapter,
  ICoachToSchool,
  IExercise,
  IExerciseGroup,
  IGroup,
  IGuideline,
  IGuidelineChapter,
  IGuidelineChapterTiny,
  IPageAbleResponse,
  IReview,
  ISchool,
  ISetting,
  IStudent,
  IVideo,
  IVideoFile,
  TableFilter,
} from '@chess-class/sandbox';

import { ChessKnightIcon } from '~/components/atoms/icons';

import { TableHeader } from './organismComponents/TableHeader';
import { TableSizeChanger } from './organismComponents/TableSizeChanger';

import { IStudio } from '~mMaterials/studios/types/IStudio';

type IAdminTableContent =
  | ISetting
  | IExercise
  | IExerciseGroup
  | IAdmin
  | IGuideline
  | IGuidelineChapter
  | IGuidelineChapterTiny
  | IVideo
  | IVideoFile
  | IStudio
  | IChapter
  | IGroup
  | IStudent
  | ISchool
  | IAuthor
  | ICoachToSchool
  | IReview;

type AdminTableType<T extends IAdminTableContent> = {
  columns: ColumnsType<T>;
  emptyDescription?: ReactNode;
  position?: ('topLeft' | 'bottomLeft')[];
  query: UseQueryResult<AxiosResponse<IPageAbleResponse<T>>, AxiosError>;
  rowClassName?: (record: T, row: number) => string;
  rowKey?: keyof T;
  tableFilter: TableFilter;
};

export const AdminTable = <T extends IAdminTableContent>({
  columns,
  emptyDescription,
  position,
  query: { data, isLoading },
  rowClassName,
  rowKey,
  tableFilter: { filtered, filters, onFilter },
}: AdminTableType<T>) => {
  const pageAble = data?.data;
  const [total, setTotal] = useState(pageAble?.totalElements || 1);

  const filteredPage = useMemo(
    () => (typeof filtered.page === 'string' ? parseInt(filtered.page) : (filtered.page ?? 0)),
    [filtered.page],
  );

  useEffect(() => {
    if (!isLoading) {
      setTotal(pageAble?.totalElements || 1);

      if (filteredPage + 1 > (pageAble?.totalPages || 1)) {
        onFilter({ page: pageAble?.totalPages || 0 });
      }
    }
  }, [data]);

  return (
    <Table
      className="table-custom-options"
      columns={columns}
      components={
        filters && {
          header: {
            row: (props) => (
              <TableHeader
                props={props}
                tableFilterProps={{ filtered, filters: filters, onFilter }}
              />
            ),
          },
        }
      }
      dataSource={pageAble?.content}
      loading={{
        indicator: (
          <Icon className="text-xl" component={(props) => <ChessKnightIcon {...props} />} spin />
        ),
        spinning: isLoading,
      }}
      locale={{
        emptyText: isLoading ? (
          <div className="p-2">
            <Skeleton active round />
            <Skeleton active round />
          </div>
        ) : (
          <Empty description={emptyDescription} image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ),
      }}
      onChange={(pagination) => {
        onFilter({
          page: pagination.current ? pagination.current - 1 : 0,
          size: pagination.pageSize || 10,
        });
      }}
      pagination={{
        current: filteredPage + 1,
        defaultCurrent: 1,
        hideOnSinglePage: false,
        pageSize: filtered.size,
        position: position || ['topLeft', 'bottomLeft'],
        responsive: true,
        selectComponentClass: TableSizeChanger,
        showSizeChanger: true,
        total,
      }}
      rowClassName={rowClassName}
      rowKey={rowKey?.toString() ?? 'id'}
      scroll={{ x: 'max-content' }}
      showSorterTooltip={false}
      tableLayout="fixed"
    />
  );
};
