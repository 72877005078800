import { Button, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  CaretRightOutlined,
  DeleteOutlined,
  PauseCircleOutlined,
  PauseOutlined,
  PlayCircleOutlined,
  QuestionOutlined,
} from '@ant-design/icons';

import {
  IEnumExerciseStatusKeys,
  IEnumExerciseTarget,
  IEnumExerciseType,
  IExercise,
} from '@chess-class/sandbox';
import { modelKeys } from '@chess-class/sandbox/consts';

import PencilIcon from '~/components/atoms/icons/PencilIcon';

import { FaChessKnightIcon } from '~/components/atoms/icons';
import { StatusTag } from '~/components/atoms/StatusTag';

import { userPlayLevels } from '~mMaterials/exercises/exercises/types/UserPlayLevel';

export const exerciseColumns = ({
  canEdit,
  onClickDelete,
  onClickEdit,
  onClickProblemPause,
  onClickTestPause,
  onClickView,
}: {
  canEdit: boolean;
  onClickDelete?: ({ id }: { id: string }) => void;
  onClickEdit?: ({ id }: { id: string }) => void;
  onClickProblemPause?: ({ id, status }: { id: string; status: IEnumExerciseStatusKeys }) => void;
  onClickTestPause?: ({ id, status }: { id: string; status: IEnumExerciseStatusKeys }) => void;
  onClickView?: ({ id }: { id: string }) => void;
}): ColumnsType<IExercise> => {
  const columns: ColumnsType<IExercise> = [
    {
      dataIndex: modelKeys.content.exercise.id,
      render: (id: IExercise['id']) =>
        onClickView ? (
          <Button onClick={() => onClickView({ id })} type="link">
            {id}
          </Button>
        ) : (
          id
        ),
      title: 'ID',
    },
    {
      dataIndex: modelKeys.content.exercise.numId,
      render: (numId: IExercise['numId']) => numId,
      title: 'NumID',
    },
    {
      dataIndex: modelKeys.content.exercise.chapter,
      render: (chapter: IExercise['chapter']) => (
        <div className="space-y-2.5">
          <p className="mb-0 first-letter:capitalize" key={`chapter${chapter?.id}`}>
            {chapter?.translations?.ru?.title}
          </p>
        </div>
      ),
      title: 'Тема',
    },
    {
      dataIndex: modelKeys.content.exercise.type,
      render: (type: IExercise['type']) => (
        <p className={`flex items-center gap-1 mb-0 ${type == 'TEST' ? 'text-ant-orange-6' : ''}`}>
          {type == 'TEST' ? <QuestionOutlined /> : <FaChessKnightIcon />}
          {IEnumExerciseType[type]}
        </p>
      ),
      title: 'Тип',
    },
    {
      dataIndex: modelKeys.content.exercise.target,
      render: (target: IExercise['target']) => (
        <p className={`flex items-center gap-1 mb-0 `}>{IEnumExerciseTarget[target]}</p>
      ),
      title: 'Место для применения',
    },
    {
      dataIndex: modelKeys.content.exercise.level,
      render: (level: IExercise['level']) => (level ? userPlayLevels[level] : ''),
      title: 'Уровень',
    },
    {
      dataIndex: modelKeys.content.exercise.status,
      render: (status: IExercise['status']) => <StatusTag status={status} />,
      title: 'Статус',
    },
  ];

  if (!canEdit || (!onClickDelete && !onClickEdit && !onClickDelete)) {
    return columns;
  }

  return [
    ...columns,
    {
      key: 'action',
      render: (exercise: IExercise) => (
        <div className="flex gap-x-1.5">
          {(onClickTestPause || onClickProblemPause) && exercise.status !== 'DRAFT' && (
            <Popconfirm
              cancelText="Отменить"
              icon={
                exercise.status == 'ACTIVE' ? (
                  <PauseCircleOutlined className="text-ant-blue-5 text-base" />
                ) : (
                  <PlayCircleOutlined className="text-ant-blue-5 text-base" />
                )
              }
              okText={exercise.status == 'ACTIVE' ? 'Приостановить' : 'Запустить'}
              okType="primary"
              onConfirm={() =>
                exercise.type === 'TEST'
                  ? onClickTestPause?.({
                      id: exercise.id,
                      status: exercise.status == 'ACTIVE' ? 'PAUSED' : 'ACTIVE',
                    })
                  : onClickProblemPause?.({
                      id: exercise.id,
                      status: exercise.status == 'ACTIVE' ? 'PAUSED' : 'ACTIVE',
                    })
              }
              placement="topLeft"
              title={
                <p className="text-sm mb-0">
                  Вы уверены, что хотите
                  <span className="font-semibold">
                    {exercise.status == 'ACTIVE' ? ' приостановить ' : ' запустить '}
                    {exercise.type == 'TEST' ? 'тест' : 'упражнение'} #{exercise.id}?
                  </span>
                </p>
              }
            >
              <Button
                ghost
                icon={exercise.status == 'ACTIVE' ? <PauseOutlined /> : <CaretRightOutlined />}
                type="primary"
              />
            </Popconfirm>
          )}
          {onClickEdit && (
            <Button
              ghost
              icon={<PencilIcon />}
              onClick={() => onClickEdit({ id: exercise.id })}
              type="primary"
            />
          )}
          {onClickDelete && (
            <Popconfirm
              cancelText="Отменить"
              icon={<DeleteOutlined className="text-ant-red-5" />}
              okText="Удалить"
              okType="danger"
              onConfirm={() => onClickDelete({ id: exercise.id })}
              placement="topLeft"
              title={
                <span className="text-sm">
                  Вы уверены, что хотите
                  <span className="font-bold">
                    {' '}
                    удалить {exercise.type == 'TEST' ? 'тест' : 'упражнение'} #{exercise.id}
                  </span>
                  ?
                </span>
              }
            >
              <Button danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </div>
      ),
      title: 'Действия',
    },
  ];
};
