import { IPageActions } from '@chess-class/sandbox';

enum AuthorsPageActionsEnum {
  'view',
  'create',
  'edit',
}

export type AuthorsPageActions = {
  action: keyof typeof AuthorsPageActionsEnum;
  actionId: string;
};

export const authorsPageActions: IPageActions<AuthorsPageActions> = {
  action: {
    allowed: (value) => Object.keys(AuthorsPageActionsEnum).includes(value),
    default: '',
  },
  actionId: { default: '' },
};
