import { FC, useState } from 'react';

import { api, FilterRender } from '@chess-class/sandbox';

import { SelectMultipleFilter } from '~/components/molecules/filters/static/SelectMultipleFilter';
import { useEducationFormat } from '~/context/EducationFormContext';

interface ChaptersSelectMultipleInterface {
  filterRender?: FilterRender;
}

export const ChaptersSelectMultipleFilter: FC<ChaptersSelectMultipleInterface> = ({
  filterRender,
}) => {
  const [chapterTitle, setChapterTitle] = useState('');
  const { defaultEducationFormat } = useEducationFormat();
  const chapters = api.content.chapters.useChapters(
    chapterTitle
      ? { educationFormat: defaultEducationFormat, title: chapterTitle }
      : { educationFormat: defaultEducationFormat },
  );
  const chaptersData = chapters.data?.data;

  const options =
    chaptersData?.content.map((chapter) => ({
      label: chapter.translations.ru?.title ?? chapter?.id,
      value: chapter.id as string,
    })) || [];

  return (
    <SelectMultipleFilter
      filterOption={false}
      filterRender={filterRender}
      loading={chapters.isLoading}
      onSearch={(searchValue) => setChapterTitle(searchValue)}
      options={options}
      placeholder="Выберите тему"
    />
  );
};
