import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { QuestionOutlined } from '@ant-design/icons';

import { IEnumExerciseTarget, IEnumExerciseType, IExercise } from '@chess-class/sandbox';
import { modelKeys } from '@chess-class/sandbox/consts';

import { FaChessKnightIcon } from '~/components/atoms/icons';
import { StatusTag } from '~/components/atoms/StatusTag';

export const chapterExerciseColumns = ({
  onClickView,
}: {
  onClickView?: ({ id }: { id: string }) => void;
}): ColumnsType<IExercise> => {
  const columns: ColumnsType<IExercise> = [
    {
      dataIndex: modelKeys.content.exercise.id,
      render: (id: IExercise['id']) =>
        onClickView ? (
          <Button onClick={() => onClickView({ id })} type="link">
            {id}
          </Button>
        ) : (
          id
        ),
      title: 'Номер/ID',
    },
    {
      dataIndex: modelKeys.content.exercise.type,
      render: (type: IExercise['type']) => (
        <p className={`flex items-center gap-1 mb-0 ${type == 'TEST' ? 'text-ant-orange-6' : ''}`}>
          {type == 'TEST' ? <QuestionOutlined /> : <FaChessKnightIcon />}
          {IEnumExerciseType[type]}
        </p>
      ),
      title: 'Тип',
    },
    {
      dataIndex: modelKeys.content.exercise.target,
      render: (target: IExercise['target']) => (
        <p className={`flex items-center gap-1 mb-0 `}>{IEnumExerciseTarget[target]}</p>
      ),
      title: 'Место для применения',
    },
    // {
    //   dataIndex: modelKeys.content.exercise.playLevels,
    //   render: (playLevels: IExercise['playLevels']) =>
    //     playLevels
    //       ? playLevels.map((playLevel) => (
    //           <p className="mb-0.5" key={playLevel}>
    //             {userPlayLevels[playLevel]}
    //           </p>
    //         ))
    //       : [],
    //   title: 'Уровень',
    // },
    {
      dataIndex: modelKeys.content.exercise.status,
      render: (status: IExercise['status']) => <StatusTag status={status} />,
      title: 'Статус',
    },
  ];

  return [...columns];
};
