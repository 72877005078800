import { type FC, useMemo } from 'react';

import { api, IGroupsRequest } from '@chess-class/sandbox';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';

import { GraduationIcon, GroupsIcon } from '~/components/atoms/icons';
import { AdminTable } from '~/components/organisms/adminTable/AdminTable';
import { useEducationFormat } from '~/context/EducationFormContext';
import { PageMeta } from '~/context/PageMetaContext';

import { groupColumns } from '~mGroups/groups/consts/columns/groupColumns';
import { groupFilters } from '~mGroups/groups/consts/filters/groupFilters';
import {
  groupPageActions,
  IGroupPageActions,
} from '~mGroups/groups/consts/pageActions/IGroupPageActions';
import GroupsTabs from '~mGroups/GroupsTabs';

export const GroupsPage: FC = () => {
  const { filtered, onFilter } = useUrlFilteredActioned<IGroupsRequest, IGroupPageActions>(
    groupFilters,
    groupPageActions,
  );
  const { defaultEducationFormat } = useEducationFormat();

  const groupsQuery = api.schools.groups.useGroups({
    ...filtered,
    educationFormat: defaultEducationFormat,
  });

  const studentsCountQuery = api.user.students.useStudentsCount();

  const schoolIds: string[] = useMemo(() => {
    return (
      (groupsQuery.data?.data.content
        .map((itemClass) => itemClass.school && itemClass.school.id)
        .filter((id) => id) as string[]) || []
    );
  }, [groupsQuery.data]);

  const schoolCoachesQuery = api.user.coachToSchool.useCoachesToSchool({
    // enabled: (schoolIds ?? []).length > 0,
    schoolIds,
  });

  const schoolCoaches = schoolCoachesQuery.data?.data.content;
  return (
    <div className="flex flex-col gap-4">
      <GroupsTabs active="groups" />
      <PageMeta
        selectedMenuKeys={['classes']}
        title={defaultEducationFormat == 'GENERAL' ? 'Классы' : 'Группы'}
      />

      <div className="flex flex-col gap-4 text-2xl">
        <span className="font-medium flex items-center gap-2">
          <GroupsIcon /> {defaultEducationFormat == 'GENERAL' ? 'Классов:' : 'Групп:'}{' '}
          {groupsQuery.data?.data.totalElements || 0}
        </span>
        <span className="flex items-center gap-2">
          <GraduationIcon /> Учеников: {studentsCountQuery.data?.data || 0}
        </span>
      </div>
      <AdminTable
        columns={groupColumns({
          canEdit: true,
          schoolCoaches,
        })}
        emptyDescription={
          <div>
            <p>
              {defaultEducationFormat == 'GENERAL' ? 'Классов не найдено.' : 'Групп не найдено'}
            </p>
          </div>
        }
        query={groupsQuery}
        tableFilter={{ filtered, filters: groupFilters, onFilter }}
      />
    </div>
  );
};
