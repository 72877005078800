import { IPageActions } from '@chess-class/sandbox';

enum IEnumGuidelinesPageActions {
  'create',
  'edit',
  'view',
}

export type IGuidelinesPageActions = {
  action: keyof typeof IEnumGuidelinesPageActions;
  actionId: string;
};

export const guidelinesPageActions: IPageActions<IGuidelinesPageActions> = {
  action: {
    allowed: (value) => Object.keys(IEnumGuidelinesPageActions).includes(value),
    default: '',
  },
  actionId: { default: '' },
};
