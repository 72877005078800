import { LoadingOutlined } from '@ant-design/icons';

import { api } from '@chess-class/sandbox';

export default function SchoolStudentsCount({
  enabled,
  groupIds,
  schoolId,
}: {
  enabled?: boolean;
  groupIds?: string[];
  schoolId: string;
}) {
  const countQuery = api.user.students.useStudentsCount({ enabled, groupIds, schoolId });
  const count = countQuery.data?.data;

  return countQuery.isLoading ? <LoadingOutlined /> : count || 0;
}
