import { Button, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  CaretRightOutlined,
  DeleteOutlined,
  PauseCircleOutlined,
  PauseOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';

import { ISchool, ISchoolStatusUpdateRequest } from '@chess-class/sandbox';
import { modelKeys } from '@chess-class/sandbox/consts';

import SchoolCoachName from '~/components/atoms/dynamicTitles/SchoolCoachName';
import { PencilIcon } from '~/components/atoms/icons';
import { StatusTag } from '~/components/atoms/StatusTag';

import { extractLocation } from '../../utils/extractLocation';

export const schoolColumns = ({
  canEdit,
  onClickDelete,
  onClickEdit,
  onClickPause,
  onClickView,
}: {
  canEdit: boolean;
  onClickDelete: ({ id }: { id: string }) => void;
  onClickEdit: ({ id }: { id: string }) => void;
  onClickPause: (payload: ISchoolStatusUpdateRequest) => void;
  onClickView: ({ id }: { id: string }) => void;
}): ColumnsType<ISchool> => {
  const columns: ColumnsType<ISchool> = [
    {
      dataIndex: modelKeys.school.school.name,
      render: (name: ISchool['name'], school) => {
        return (
          <Button onClick={() => onClickView({ id: school.id })} type="link">
            <span className="max-w-[250px] truncate">{name}</span>
          </Button>
        );
      },
      title: 'Школа',
    },
    {
      dataIndex: 'regionIds',
      render: (_, school) => extractLocation(school.location, 'isRegion')?.nameRu ?? '-',
      title: 'Область',
    },
    {
      dataIndex: 'districtIds',
      render: (_, school) => extractLocation(school.location, 'isDistrict')?.nameRu ?? '-',
      title: 'Район',
    },
    {
      dataIndex: 'cityIds',
      render: (_, school) =>
        school.locationText || extractLocation(school.location, 'isLocality')?.nameRu,
      title: 'Город',
    },
    {
      dataIndex: modelKeys.school.school.address,
      title: 'Адрес',
    },
    {
      dataIndex: modelKeys.school.school.status,
      render: (status: ISchool['status']) => <StatusTag status={status} />,
      title: 'Статус',
    },
    {
      dataIndex: modelKeys.school.school.superCoachId,
      render: (superCoachId: ISchool['superCoachId']) =>
        superCoachId ? <SchoolCoachName id={superCoachId} /> : '-',
      title: 'Супер-тренер',
    },
  ];

  if (!canEdit) {
    return columns;
  }

  return [
    ...columns,
    {
      key: 'actions',
      render: (school: ISchool) => {
        return (
          <div className="flex gap-x-1.5">
            {school.superCoachId && (
              <Popconfirm
                cancelText="Отменить"
                icon={
                  school.status == 'ACTIVE' ? (
                    <PauseCircleOutlined className="text-ant-blue-5 text-base" />
                  ) : (
                    <PlayCircleOutlined className="text-ant-blue-5 text-base" />
                  )
                }
                okText={school.status == 'ACTIVE' ? 'Приостановить' : 'Запустить'}
                okType="primary"
                onConfirm={() =>
                  onClickPause({
                    id: school.id,
                    status: school.status == 'ACTIVE' ? 'PAUSED' : 'ACTIVE',
                  })
                }
                placement="topLeft"
                title={
                  <p className="text-sm mb-0">
                    Вы уверены, что хотите
                    <span className="font-semibold">
                      {school.status == 'ACTIVE' ? ' приостановить ' : ' запустить '}
                      школу {school.name}?
                    </span>
                  </p>
                }
              >
                <Button
                  ghost
                  icon={school.status == 'ACTIVE' ? <PauseOutlined /> : <CaretRightOutlined />}
                  type="primary"
                />
              </Popconfirm>
            )}

            <Button
              ghost
              icon={<PencilIcon />}
              onClick={() => onClickEdit({ id: school.id })}
              type="primary"
            />

            <Popconfirm
              cancelText="Отменить"
              icon={<DeleteOutlined className="text-ant-red-5" />}
              okText="Удалить"
              okType="danger"
              onConfirm={() => onClickDelete({ id: school.id })}
              placement="topLeft"
              title={
                <span className="text-sm">
                  Вы уверены, что хотите удалить школу
                  <span className="font-bold"> &quot;{school.name}&quot;</span>?
                </span>
              }
            >
              <Button danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </div>
        );
      },
      title: 'Действия',
    },
  ];
};
