import { IEnumAuthorRank, IFilters } from '@chess-class/sandbox';

import { RadioFilter } from '~/components/molecules/filters/static/RadioFilter';

export const authorsFilters: IFilters = {
  name: {},
  page: {
    queries: [{ default: 0 }],
  },
  rank: {
    input: (
      <RadioFilter
        options={Object.keys(IEnumAuthorRank).map((value) => ({ label: value, value }))}
      />
    ),
    queries: [{ allowed: (value) => Object.keys(IEnumAuthorRank).includes(value) }],
  },
  size: {
    queries: [
      {
        allowed: (value) => {
          return ['30'].includes(value);
        },
        default: 30,
      },
    ],
  },
  sort: {},
};
