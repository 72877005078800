import { FC } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import { AxiosError } from 'axios';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';

import { api, IAdminInviteRequest, IBackendError } from '@chess-class/sandbox';
import { modelKeys } from '@chess-class/sandbox/consts';
import { notify } from '@chess-class/sandbox/utils';

import { StaffAuthoritySelects } from '~mStaff/components/molecules/StaffAuthoritySelects';

export const StaffInviteModal: FC<{ isOpen: boolean; onCancel: () => void }> = ({
  isOpen,
  onCancel,
}) => {
  const invite = api.user.admin.useAdminInvite();

  return (
    <Modal
      className="px-4 md:px-0 w-full md:w-3/4 lg:w-2/3 top-8"
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={<h1 className="text-xl m-0">Новый персонал</h1>}
    >
      <Formik<IAdminInviteRequest>
        enableReinitialize
        initialValues={api.user.admin.helpers.adminInviteInitial}
        onSubmit={(values, { resetForm }) => {
          invite
            .mutateAsync(values)
            .then(() => {
              onCancel();
              resetForm();
            })
            .catch((error: AxiosError<IBackendError>) => {
              notify('error', error.response?.data.message ?? 'Повторите попытку позже');
            });
        }}
        validationSchema={api.user.admin.helpers.adminInviteValidation}
      >
        {({ isValid, resetForm, setErrors, values }) => {
          const disableSubmit = [
            values.classes,
            values.materials,
            values.schools,
            values.settings,
            values.staff,
          ].every((permission) => permission === 'NO_ACCESS');

          return (
            <Form className="space-y-8" layout="vertical" noValidate>
              <Form.Item initialValue="block" label="Почта" name={modelKeys.user.admin.email}>
                <Input
                  name={modelKeys.user.admin.email}
                  placeholder="example@example.com"
                  suffix
                  type="email"
                />
              </Form.Item>

              <StaffAuthoritySelects />

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                <Button
                  block
                  onClick={() => {
                    onCancel();
                    setErrors({});
                    resetForm();
                  }}
                >
                  Отменить
                </Button>

                <Tooltip className="text-center" title={'Персонал должен иметь минимум 1 роль'}>
                  <Button
                    block
                    disabled={!isValid || disableSubmit}
                    htmlType="submit"
                    loading={invite.isLoading}
                    type="primary"
                  >
                    Отправить приглашение
                  </Button>
                </Tooltip>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
