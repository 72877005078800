import { IPageActions } from '@chess-class/sandbox';

enum ReviewPageActionEnum {}

export type ReviewPageActions = {
  action: keyof typeof ReviewPageActionEnum;
  actionId: string;
};

export const reviewPageActions: IPageActions<ReviewPageActions> = {
  action: {
    allowed: (value) => Object.keys(ReviewPageActionEnum).includes(value),
    default: '',
  },
  actionId: { default: '' },
};
