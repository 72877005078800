import { useEffect, useState } from 'react';
import { Button, Switch as AntSwitch } from 'antd';
import { useFormikContext } from 'formik';
import { Form, FormItem, Input, Radio, Select, Switch } from 'formik-antd';

import {
  api,
  filteredLocalizations,
  IEnumExerciseGamePhase,
  IEnumExerciseTarget,
  IEnumLocalizationGenitive,
  IExerciseTestCreateRequest,
  ILocalization,
} from '@chess-class/sandbox';

import { FileUpload } from '~/components/molecules/files/FileUpload';
import { useEducationFormat } from '~/context/EducationFormContext';

import QuestionAnswersForm from '~mMaterials/exercises/exercises/components/molecules/QuestionAnswersForm';
import { userPlayLevels } from '~mMaterials/exercises/exercises/types/UserPlayLevel';

export default function TestForm({
  isEdit,
  isLoading,
  onCancel,
}: {
  isEdit?: boolean;
  isLoading?: boolean;
  onCancel: () => void;
}) {
  const { defaultEducationFormat } = useEducationFormat();
  const chapters = api.content.chapters.useChapters({
    educationFormat: defaultEducationFormat,
    size: 9999,
  });

  const [questionsNum, setQuestionsNum] = useState(2);
  const [isSecondImageVisible, setIsSecondImageVisible] = useState(false);

  const {
    setFieldValue,
    values: { answerImageUrl, imageUrl, status, translations },
  } = useFormikContext<IExerciseTestCreateRequest>();

  const handleRemoveClick = (index) => {
    const updatedTranslations = {};
    for (const key in translations) {
      if (translations[key]) {
        updatedTranslations[key] = {
          ...translations[key],
          answerOptions: translations[key]?.answerOptions.filter((item, idx) => idx !== index),
        };
      }
      setFieldValue('translations', updatedTranslations);
    }
  };

  useEffect(() => {
    setQuestionsNum(translations?.['ru']?.answerOptions.length ?? 2);
  }, [translations]);

  return (
    <Form className="flex flex-col gap-6" layout="vertical">
      <div className="grid grid-cols-2 gap-4">
        {filteredLocalizations({
          defaultEducationFormat: defaultEducationFormat ?? 'GENERAL',
          isDev:
            process.env.REACT_APP_API_URL.includes('api.dev') ||
            process.env.NODE_ENV == 'development',
        })
          ?.reverse()
          .map((locale) => (
            <QuestionAnswersForm
              key={`questions-answers-${locale}`}
              localization={locale as ILocalization}
              maxQuestionsNum={4}
              nameStart={`translations.${locale}`}
              onRemoveClick={(index) => handleRemoveClick(index)}
              questionsNum={questionsNum}
              setQuestionsNum={setQuestionsNum}
              text={`Вопрос на ${IEnumLocalizationGenitive[locale]} языке`}
            />
          ))}
      </div>

      <FormItem label="Правильный вариант" name="correctOption">
        <Radio.Group
          name="correctOption"
          options={Array.from(Array(questionsNum)).map((_, index) => ({
            label: String.fromCharCode(65 + index),
            value: index + 1,
          }))}
        />
      </FormItem>

      <FormItem className="upload-form-item text-center custom-picture" name="imageUrl">
        <FileUpload
          accept=".jpeg,.png,.jpg"
          defaultFileList={
            imageUrl
              ? [
                  {
                    name: '',
                    status: 'done',
                    uid: imageUrl,
                    url: imageUrl,
                  },
                ]
              : undefined
          }
          folderName="testImages"
          label="Картинка"
          maxCount={1}
          onChange={(info, linkInfo) => {
            if (!info.file.status || info.file.status == 'removed') setFieldValue('imageUrl', '');
            if (info.file.status == 'done') setFieldValue('imageUrl', linkInfo.downloadUrl);
          }}
        />
      </FormItem>
      {isSecondImageVisible && (
        <FormItem className="upload-form-item text-center custom-picture" name="answerImageUrl">
          <FileUpload
            accept=".jpeg,.png,.jpg"
            defaultFileList={
              answerImageUrl
                ? [
                    {
                      name: '',
                      status: 'done',
                      uid: answerImageUrl,
                      url: answerImageUrl,
                    },
                  ]
                : undefined
            }
            folderName="testImages"
            label="Картинка #2"
            maxCount={1}
            onChange={(info, linkInfo) => {
              if (!info.file.status || info.file.status == 'removed')
                setFieldValue('answerImageUrl', '');
              if (info.file.status == 'done') setFieldValue('answerImageUrl', linkInfo.downloadUrl);
            }}
          />
        </FormItem>
      )}
      {isSecondImageVisible ? (
        <Button
          className="w-max m-auto"
          onClick={() => {
            setIsSecondImageVisible(false);
            setFieldValue('answerImageUrl', '');
          }}
          type="primary"
        >
          {'Удалить "Картинка #2"'}
        </Button>
      ) : (
        <Button
          className="w-max m-auto"
          disabled={!imageUrl}
          onClick={() => setIsSecondImageVisible(true)}
          type="primary"
        >
          Добавить картинку
        </Button>
      )}

      <div className="grid grid-cols-2 gap-4 gap-x-8">
        <FormItem label="Уровень" name="level">
          <Select
            name="level"
            optionFilterProp="label"
            options={Object.keys(userPlayLevels).map((playLevel) => ({
              label: userPlayLevels[playLevel],
              value: playLevel,
            }))}
            showSearch={false}
          />
        </FormItem>

        <FormItem label="Тема" name="chapterId">
          <Select
            className="gap-y-2"
            name="chapterId"
            optionFilterProp="label"
            options={
              chapters.data?.data.content.map((chapter) => ({
                label: chapter.translations.ru?.title ?? '-',
                value: chapter.id,
              })) || []
            }
          />
        </FormItem>

        <FormItem label="Место для применения" name="target">
          <Select
            className="gap-y-2"
            name="target"
            optionFilterProp="label"
            options={
              Object.keys(IEnumExerciseTarget).map((key) => ({
                label: IEnumExerciseTarget[key],
                value: key,
              })) || []
            }
          />
        </FormItem>

        <FormItem label="Стадия игры" name="gamePhase">
          <Select
            allowClear
            name="gamePhase"
            options={Object.entries(IEnumExerciseGamePhase).map(([value, label]) => ({
              label,
              value,
            }))}
          />
        </FormItem>
      </div>

      <div className="grid md:grid-cols-3 place-items-center text-center">
        <FormItem
          label={<span className="text-center">Капитализация преимущества</span>}
          name="isCapitalization"
        >
          <Switch name="isCapitalization" />
        </FormItem>

        <FormItem label={<span className="text-center">Тактика</span>} name="isTactic">
          <Switch name="isTactic" />
        </FormItem>

        <Form.Item label={<span className="text-center">Запустить немедленно</span>} name="status">
          <Input hidden name="status" suffix />

          <AntSwitch
            checked={status == 'ACTIVE'}
            onChange={(checked) => setFieldValue('status', checked ? 'ACTIVE' : 'DRAFT')}
          />
        </Form.Item>
      </div>

      <div className="grid md:grid-cols-2 gap-5">
        <Button block onClick={onCancel}>
          Отменить
        </Button>

        <Button block htmlType="submit" loading={isLoading} type="primary">
          <span className="max-w-full overflow-ellipsis overflow-hidden">
            {status == 'ACTIVE'
              ? `${isEdit ? 'Изменить' : 'Создать'} и запустить`
              : 'Сохранить как черновик'}
          </span>
        </Button>
      </div>
    </Form>
  );
}
